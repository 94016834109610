import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO } from 'config.js';

const initialState = {
  invoiceStatus: [],
  isInvoiceLoading: true,
};

const invoiceStatusSlice = createSlice({
  name: 'invoiceStatus',
  initialState,
  reducers: {
    setInvoiceStatus(state, actions) {
      state.isInvoiceLoading = false;
      state.invoiceStatus = actions.payload;
    },
  },
});

export const { setInvoiceStatus } = invoiceStatusSlice.actions;

const invoiceStatusReducer = invoiceStatusSlice.reducer;
export default invoiceStatusReducer;
