/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Row, Col, Dropdown, OverlayTrigger, Form, Tooltip, Badge } from 'react-bootstrap';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { format } from 'date-fns';

import HtmlHead from 'components/html-head/HtmlHead';
import { useIntl } from 'react-intl';
// import { getCurrentSellerId } from 'auth/utils';
// import { Pagination, Table } from 'rsuite';
import { Table } from 'rsuite';
import { displayErrorToast } from 'helpers/toaster';
import { SORTING_ORDER } from 'constants/index';
import { useUrlSearchParams } from 'use-url-search-params';
import { useSelector, useDispatch } from 'react-redux';
import { hideSideBar, showSideBar } from 'settings/settingsSlice';

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
// import { fetchAllStatements, generateSellerSOA } from './actions/statementActions';
import { fetchAllStatements } from './actions/statementActions';
import SOAModal from './SOAModal';

// import { getStatementStatusCounts } from './actions/statementActions';

const { Column, HeaderCell, Cell } = Table;
const CustomCell = ({ rowData, ...rest }) => {
  console.log(rowData, rest);
  switch (rest.dataKey) {
    case 'view':
      return (
        <Cell rowData={rowData} {...rest}>
          <NavLink to={`/statements/transactions/detail/${rowData.statement_period_id}`} className="">
            View
          </NavLink>
        </Cell>
      );
    case 'statusBadge':
      return (
        <Cell rowData={rowData} {...rest}>
          <Badge>{rowData.statusBadge}</Badge>
        </Cell>
      );
    case 'paymentProcessed':
      return (
        <Cell rowData={rowData} {...rest}>
          {rowData.payment_processed_on ? format(new Date(Number(rowData.payment_processed_on)), 'dd/MM/yyyy hh:m a') : ''}
        </Cell>
      );
    case 'payment_received_on':
      return (
        <Cell rowData={rowData} {...rest}>
          {rowData.payment_received_on ? format(new Date(Number(rowData.payment_received_on)), 'dd/MM/yyyy hh:m a') : ''}
        </Cell>
      );
    default:
      return (
        <Cell rowData={rowData} {...rest}>
          <a href="https://google.com" target="_blank" rel="noreferrer">
            {rowData[rest.dataKey]}
          </a>
        </Cell>
      );
  }
};

const StatementViewV2 = () => {
  const title = 'Statement';
  const description = 'Statement Detail View';
  const { formatMessage: f } = useIntl();
  const [sortingName, setSortingName] = useState(f({ id: 'sort.date_asc' }));

  const [sortingValue, setSortingValue] = useUrlSearchParams({ sort: '_date', order: SORTING_ORDER.ASC }, null, true);
  const [paginationValue] = useUrlSearchParams({ page: 0 }, null, true);

  // const { list, count } = useSelector((state) => state.statements);
  const { list } = useSelector((state) => state.statements);

  const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 10 }, null, true);
  const [showSOAModal, setSOAModal] = useState(false);

  const limitByValues = [5, 10, 20, 50];
  const handleSorting = (type, order = SORTING_ORDER.ASC, name) => {
    setSortingValue({ sort: type, order });
    setSortingName(name);
  };
  const sortByValues = [
    { key: 'date', order: SORTING_ORDER.ASC, label: f({ id: 'sort.date_asc' }) },
    { key: 'date', order: SORTING_ORDER.DESC, label: f({ id: 'sort.date_desc' }) },
    { key: 'status', order: SORTING_ORDER.ASC, label: f({ id: 'sort.status_asc' }) },
    { key: 'status', order: SORTING_ORDER.DESC, label: f({ id: 'sort.status_desc' }) },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(hideSideBar());
    return () => {
      dispatch(showSideBar());
    };
  }, []);

  const handleLimit = (e) => {
    setLimitValue({ limit: Number(e) });
  };
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    const fn = async () => {
      try {
        setLoading(true);
        await fetchAllStatements({ params: { page: paginationValue.page, limit: Number(limitValue.limit) }, dispatch });
        setLoading(false);
      } catch (e) {
        displayErrorToast('Something went wrong');
        console.error(e);
        setLoading(false);
      }
    };
    fn();
  }, [paginationValue.page, limitValue.limit, sortingValue.sort]);

  return (
    <>
      <HtmlHead title={title} description={description} />
      <Col>
        {/* Title Start */}
        <div className="page-title-container mb-3">
          <Row className="g-0">
            <Col className="mb-2">
              <h1 className="mb-2 pb-0 display-4">{title}</h1>
              <div className="text-muted font-heading text-small">List of All Statements.</div>
            </Col>
            {/* <Col xs="12" sm="auto" className="d-flex align-items-end justify-content-end mb-2 mb-sm-0 order-sm-3">
              <Button
                data-testid="importNewProductButton"
                onClick={() => {
                  setSOAModal(true);
                }}
                variant="primary"
                className="btn-icon btn-icon-start ms-0 ms-sm-1 w-100 w-md-auto"
              >
                <CsLineIcons icon="invoice" /> <span>Generate SOA</span>
              </Button>
            </Col> */}
          </Row>
        </div>
        <Row className="mb-3">
          <Col md="5" lg="3" xxl="2" className="mb-1">
            <div className="d-inline-block float-md-start me-1 mb-1 search-input-container w-100 shadow bg-foreground">
              <Form.Control type="text" placeholder="Search" id="listSearch" />
              <span className="search-magnifier-icon">
                <CsLineIcons icon="search" />
              </span>
              <span className="search-delete-icon d-none">
                <CsLineIcons icon="close" />
              </span>
            </div>
          </Col>
          <Col md="7" lg="9" xxl="10" className="mb-1 text-end">
            <Dropdown align={{ xs: 'end' }} className="d-inline-block ms-1">
              <OverlayTrigger delay={{ show: 1000, hide: 0 }} placement="top" overlay={<Tooltip id="tooltip-top">Export</Tooltip>}>
                <Dropdown.Toggle variant="foreground-alternate" className="dropdown-toggle-no-arrow btn btn-icon btn-icon-only shadow">
                  <CsLineIcons icon="download" />
                </Dropdown.Toggle>
              </OverlayTrigger>
              <Dropdown.Menu className="shadow dropdown-menu-end">
                <Dropdown.Item href="#">Copy</Dropdown.Item>
                <Dropdown.Item href="#">Excel</Dropdown.Item>
                <Dropdown.Item href="#">Cvs</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown align={{ xs: 'end' }} className="d-inline-block ms-1" id="listSort">
              <Dropdown.Toggle variant="foreground-alternate" className="shadow sw-19">
                {f({ id: 'menu.sortby' })} {sortingName}
              </Dropdown.Toggle>

              <Dropdown.Menu className="shadow dropdown-menu-end" data-testid="sortItemDropdown">
                {sortByValues.map((obj, i) => {
                  return (
                    <Dropdown.Item
                      href="#"
                      value={obj.key}
                      key={i}
                      onClick={() => {
                        handleSorting(obj.key, obj.order, obj.label);
                      }}
                    >
                      {obj.label}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            {/* Length End */}
            <Dropdown align={{ xs: 'end' }} className="d-inline-block ms-1" id="listLimit">
              <Dropdown.Toggle variant="foreground-alternate" className="shadow sw-13">
                {limitValue.limit} Items
              </Dropdown.Toggle>

              <Dropdown.Menu className="shadow dropdown-menu-end">
                {limitByValues.map((limit, i) => {
                  return (
                    <Dropdown.Item key={i} href="#" onClick={() => handleLimit(limit)}>
                      {limit} Items
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            {/* Length End */}
          </Col>
        </Row>
        <Col md="1" className="d-flex flex-column pe-1 justify-content-end">
          {/* <div className="text-muted text-small cursor-pointer sort">STATUS</div> */}
          <div
            className={classNames('text-muted text-medium cursor-pointer ', {
              asc: sortingValue.value === 'fields.name.lang.en' && sortingValue.order === SORTING_ORDER.ASC,
              desc: sortingValue.value === 'fields.name.lang.en' && sortingValue.order === SORTING_ORDER.DESC,
            })}
          />
        </Col>
      </Col>

      <Table bordered cellBordered autoHeight data={list} wordWrap={false} affixHorizontalScrollbar loading={isLoading}>
        <Column width={50} fixed align="center">
          <HeaderCell>SI #</HeaderCell>
          <Cell dataKey="si" />
        </Column>
        <Column width={110} fixed>
          <HeaderCell>Start Date</HeaderCell>
          <Cell dataKey="statement_period_from_date" />
        </Column>
        <Column width={110} fixed>
          <HeaderCell>End Date</HeaderCell>
          <Cell dataKey="statement_period_end_date" />
        </Column>

        <Column width={90} align="center">
          <HeaderCell># of Sales</HeaderCell>
          <Cell dataKey="order_count" />
        </Column>

        <Column width={100} align="center">
          <HeaderCell>Sales</HeaderCell>
          <Cell dataKey="sales" />
        </Column>

        <Column width={100} align="center">
          <HeaderCell>Refunds</HeaderCell>
          <Cell dataKey="refunds" />
        </Column>
        <Column width={110} align="center">
          <HeaderCell>Shipping Charges</HeaderCell>
          <Cell dataKey="shipping_charge" />
        </Column>
        <Column width={100} align="center">
          <HeaderCell>Danube Fees</HeaderCell>
          <Cell dataKey="commission" />
        </Column>

        <Column width={100} align="center">
          <HeaderCell>Status</HeaderCell>
          <CustomCell dataKey="statusBadge" />
        </Column>
        <Column width={160}>
          <HeaderCell>Payment Processed On</HeaderCell>
          <CustomCell dataKey="paymentProcessed" />
        </Column>
        <Column width={160}>
          <HeaderCell>Payment Received On</HeaderCell>
          <CustomCell dataKey="payment_received_on" />
        </Column>

        <Column width={100} fixed="right">
          <HeaderCell>Total Payout</HeaderCell>
          <Cell dataKey="total_payout" />
        </Column>
        <Column width={60} fixed="right">
          <HeaderCell>...</HeaderCell>
          <CustomCell dataKey="view">View</CustomCell>
        </Column>
        <Column width={100} fixed="right">
          <HeaderCell>...</HeaderCell>
          <Cell dataKey="download">Download</Cell>
        </Column>
      </Table>

      {showSOAModal && (
        <SOAModal
          show={showSOAModal}
          onHide={() => {
            setSOAModal(false);
          }}
        />
      )}
    </>
  );
};

export default StatementViewV2;
