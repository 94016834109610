import { getFirebaseToken } from 'auth/utils';
import axios from 'axios';

export const sendEmailVerificationSellerHub = async ({ token: fbToken, name = '', phone = '' }) => {
  try {
    const token = fbToken || (await getFirebaseToken());
    const result = await axios({
      method: 'GET',
      url: `${process.env.SELLER_HUB_SELLER_GRAPHQL_API}/account/sendEmailVerification?name=${encodeURIComponent(name)}&phone=${phone}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return result;
  } catch (e) {
    console.error(e);
    return e;
  }
};
