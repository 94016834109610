import axios from 'axios';
import { setMessageList } from 'messageSlice';
// import UserAuthKey from "../../../../components/Common/UserAuthKey";

const apiUrl = `${process.env.SELLER_HUB_OMS_END_POINT}sellersapi`;
const bearerToken = process.env.SELLER_HUB_OMS_AUTH_TOKEN;
// 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJEYW51YmVIb21lIE9NUyIsImF1ZCI6Ik9NUyBVc2VycyIsInN1YiI6Ik9NUyBBdXRoIiwiaWF0IjoxNjkyOTcwMDA0LCJleHAiOjE3MjQ1MDYwMDQsImVtYWlsIjoic2VsbGVyIn0.K7nSMQ9i_wAHoyqaFnCu51RcsjxFRMe2Luq_C3d1kp4';

export const fetchQaList = async ({ params, dispatch }) => {
  let result = null;
  const config = {
    method: 'POST',
    url: `${apiUrl}/v1/orders/list-qa`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    dispatch(setMessageList(response?.data));
    result = response?.data;
  } catch (error) {
    console.log('OMS Message API Error', error);
  }
  return result;
};

export const insertOrderShipQustionAnswer = async ({ params }) => {
  const config = {
    method: 'POST',
    url: `${apiUrl}/v1/orders/insert-order-qa`,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data: {
      ...params,
    },
  };
  try {
    const response = await axios(config);
    console.log(response, 'response');
    if (response?.data?.code === 100) {
      return true;
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      console.log(error);
    }
  }
  return true;
};

export const getQuestionAnswer = (data) => {
  let result = null;
  return axios({
    method: 'post',
    url: `${apiUrl}/v1/orders/get-qa`,
    data: data,
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
  })
    .then(async (response) => {
      if (response?.data?.code === 100) {
        result = response?.data?.data;
        return result;
      }
      return result;
    })
    .catch((error) => {
      console.log(error);
    });
};
