import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO } from 'config.js';

const initialState = {
  orderAWB:[],
  isOrderLoading: true,
};

const orderAWBSlice = createSlice({
  name: 'orderAWB',
  initialState,
  reducers: {
    setOrderAWB(state, actions) {
      state.isOrderLoading = false;
      state.orderAWB = actions.payload;
    },
  },
});

export const { setOrderAWB } = orderAWBSlice.actions;

const orderAWBReducer = orderAWBSlice.reducer;
export default orderAWBReducer;
