import { COUNTRIES } from 'constants/index';

export const objectToArray = (fields) => {
  if (fields) {
    const formattedFieldsPost = Object.keys(fields).map((key) => {
      let lang = null;
      if (typeof fields[key] === 'object' && fields[key]?.lang) {
        lang = {
          en: fields[key]?.lang?.en,
          ar: fields[key]?.lang?.ar,
        };
      }
      return {
        key,
        numberValue: !Number.isNaN(Number(fields[key])) ? Number(fields[key]) : null,
        boolValue: typeof fields[key] === 'boolean' ? fields[key] : null,
        stringValue: typeof fields[key] === 'string' ? fields[key] : null,
        dateValue: fields[key] instanceof Date ? fields[key] : null,
        lang,
      };
    });
    return formattedFieldsPost;
  }
  return [];
};

export const arrayToObject = (fields) => {
  let formattedFields = {};
  if (fields && Array.isArray(fields)) {
    fields?.forEach((field) => {
      const fieldLang = {};
      if (field.lang) {
        if (field.lang.ar) fieldLang.ar = field.lang.ar;
        if (field.lang.en) fieldLang.en = field.lang.en;
      }
      formattedFields = {
        ...formattedFields,
        [field.key]: field.lang ? { lang: fieldLang } : field.numberValue || field.boolValue || field.StringValue || field.dateValue,
      };
    });
  }
  return formattedFields;
};

export const formatFilterFields = (str) => {
  if (typeof str === 'string') {
    return str;
    // return str
    //   .replace(/_/g, ' ')
    //   .replace(/((?<!^)[A-Z](?![A-Z]))(?=\S)/g, '$1')
    //   .replace(/^./, (s) => s.toUpperCase());
  }
  return '';
};

export const getCurrency = (country) => {
  if (country) {
    return COUNTRIES.find((c) => c.iso2_code === country)?.currency;
  }
  return '';
};

export const mapPercentToColor = (value, totalValue) => {
  const percent = ((value || 0) / (totalValue || 1)) * 100;
  if (percent < 34) {
    return 'danger';
  }
  if (percent < 67) {
    return 'warning';
  }
  return 'secondary';
};

export const mapPercentToColorReverse = (value, totalValue) => {
  const percent = ((value || 0) / (totalValue || 1)) * 100;
  if (percent > 67) {
    return 'danger';
  }
  if (percent > 34) {
    return 'warning';
  }
  return 'secondary';
};

export const mapPercentToColorShade = (value, totalValue) => {
  const percent = ((value || 0) / (totalValue || 1)) * 100;
  if (percent < 34) {
    return '#ea4228';
  }
  if (percent < 67) {
    return '#f5cd19';
  }
  return '#5be12c';
};

export const calculatePercent = (value, totalValue) => {
  const percent = ((value || 0) / (totalValue || 1)) * 100;
  const percentValue = percent ? Number(percent.toFixed(1)) : 0;
  return percentValue;
};

export const camelCaseToText = (str) => {
  return str
    .split(/(?=[A-Z])/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
