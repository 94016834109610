import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SellerOnboardingFullPage from 'layout/SellerOnboardingFullPage';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import HtmlHead from 'components/html-head/HtmlHead';
import { auth } from 'helpers/Firebase';
import { Button, Spinner } from 'react-bootstrap';

// import { initialAuthStateChanged } from 'auth/utils';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { onAuthStateChanged } from 'firebase/auth';
import { setCurrentUser, setLogout } from 'auth/authSlice';
import { DEFAULT_PATHS } from '../../config';
import { sendEmailVerificationSellerHub } from './actions';
import OnboardingListNav from './OnboardingListNav';
import '../seller/style.scss';

const EmailVerificationWaiting = () => {
  const history = useHistory();
  // const dispatch = useDispatch();
  const title = 'Waiting for email verification';
  const description = 'Waiting for email verification';

  const { isLoginLoading, emailVerificationSuccessful = false } = useSelector((state) => state.auth);
  console.log('🚀 ~ file: EmailVerificationWaiting.js ~ line 22 ~ EmailVerificationWaiting ~ emailVerificationSuccessful', emailVerificationSuccessful);
  console.log('🚀 ~ file: EmailVerificationWaiting.js ~ line 22 ~ EmailVerificationWaiting ~ isLoginLoading', isLoginLoading);

  // useEffect(() => {
  //   if (isLogin && emailVerificationSuccessful) {
  //     toast.success('Email has successfully verified. We are taking you to the next step', {
  //       position: 'top-right',
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //     });
  //     setTimeout(() => {
  //       history.replace(DEFAULT_PATHS.LOGIN);
  //     });
  //   }
  // }, [isLogin, isLoginLoading, emailVerificationSuccessful]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // const { uid, name, email, emailVerified } = user;
        console.log('this util called', user);
        const { emailVerified } = user;
        console.log('🚀 ~ file: EmailVerificationWaiting.js ~ line 44 ~ onAuthStateChanged ~ emailVerified', emailVerified);
        if (emailVerified) {
          toast.success('Email has successfully verified. We are taking you to the next step', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          setCurrentUser({
            emailVerified,
          });
          history.push(DEFAULT_PATHS.SELLER_ONBOARDING);
        }

        // dispatch(
        //   setCurrentUser({
        //     id: uid,
        //     name,
        //     thumb: '/img/profile/profile-9.webp',
        //     role: USER_ROLE.Admin,
        //     email,
        //     emailVerified,
        //   })
        // );
        // if (!isApp) {
        //   history.push(DEFAULT_PATHS.APP);
        // }
      } else {
        // dispatch(setLogout());
      }
    });

    // initialAuthStateChanged({ dispatch, history });
  }, []);
  useEffect(() => {
    const intereval = window.setInterval(() => {
      window.location.reload();
    }, 5000);
    return () => window.clearInterval(intereval);
  }, []);
  const dispatch = useDispatch();
  const handleLogout = async () => {
    await auth.signOut();
    dispatch(setLogout());
    window.localStorage.authToken = null;
    history.replace(DEFAULT_PATHS.LOGIN);
  };
  const handleResendEmailVerification = async () => {
    await sendEmailVerificationSellerHub({});
    // window.localStorage.authToken = null;
    // history.replace(DEFAULT_PATHS.LOGIN);
  };

  const rightSide = (
    <div className="sw-lg-80 min-h-100 d-flex justify-content-center align-items-center py-5 full-page-content-right-border">
      <div className="sw-lg-60 px-5">
        {/* <div className="sh-11">
          <NavLink to="/">
            <div className="mb-7">
              <img src="/img/profile/logo.png" alt="logo" width="400" />
            </div>
          </NavLink>
        </div> */}
        <div className="mb-5">
          {/* <h2 className="cta-1 mb-0 text-primary"></h2> */}
          <h2 className="display-2 text-primary">Its the time to verify your email</h2>
        </div>
        <div className="mb-5">
          <p className="h6">An verification link has been sent to your email account</p>
          <p className="">
            <small>Please click on that link on email to complete the registration</small>
          </p>
          <p>
            Waiting for your action <Spinner animation="border" size="sm" />{' '}
          </p>
        </div>

        <div>
          Email not yet received &nbsp;{' '}
          <Button variant="outline-primary" size="sm" onClick={handleResendEmailVerification}>
            <CsLineIcons icon="send" size={14} /> <span>Resend</span>
          </Button>
        </div>
        <div className="mt-3">
          Register with different email? &nbsp;
          <Button variant="outline-primary" size="sm" onClick={handleLogout}>
            <CsLineIcons icon="arrow-left" size={14} /> <span>Go Back</span>
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="onboard">
        <HtmlHead title={title} description={description} />
        <div className="containSection ">
          <OnboardingListNav />

          <div className="loginSection">
            <div className="m-lg-7">{rightSide}</div>
          </div>
        </div>
        <SellerOnboardingFullPage left={<></>} right={<></>} />
      </div>
    </>
  );
};

export default EmailVerificationWaiting;
