import React, { useState } from 'react';
import { Table, Popover, Whisper, Dropdown, IconButton } from 'rsuite';
import MoreIcon from '@rsuite/icons/legacy/More';
import moment from 'moment';
import OrdersViewDetail from 'views/orders/OrdersViewDetail';
import { Modal } from 'react-bootstrap';

const { Column, HeaderCell, Cell } = Table;

const renderMenu = ({ onClose, left, top, className }, ref) => {
  const handleSelect = (eventKey) => {
    onClose();
    console.log(eventKey);
  };
  return (
    <Popover ref={ref} className={className} style={{ left, top }} full>
      <Dropdown.Menu onSelect={handleSelect}>
        <Dropdown.Item eventKey={1}>View Company Details</Dropdown.Item>
        <Dropdown.Item eventKey={2}>View Transactions</Dropdown.Item>
      </Dropdown.Menu>
    </Popover>
  );
};

const OrderList = ({ orders = [], tableHeight = 400, otherCells = [] }) => {
  const [showOrderModal, setShowOrderModal] = useState();
  const [isLoading] = useState(false);
  const CustomCell = ({ rowData, ...rest }) => {
    switch (rest.dataKey) {
      case 'created_at': {
        return (
          <Cell rowData={rowData} {...rest}>
            <If condition={rowData.created_at}>
              {' '}
              <span className="light-badge-success">{moment(Number(rowData.created_at)).format('DD-MM-YYYY hh:mm a')}</span>
            </If>
          </Cell>
        );
      }
      case 'scheduled_on': {
        return (
          <Cell rowData={rowData} {...rest}>
            <If condition={rowData.delivery_completed_at}>
              {' '}
              <span className="light-badge-success">{moment(Number(rowData.delivery_completed_at)).format('DD-MM-YYYY hh:mm a')}</span>
            </If>
          </Cell>
        );
      }
      case 'delivery_completed_at': {
        return (
          <Cell rowData={rowData} {...rest}>
            <If condition={rowData.delivery_completed_at}>
              <span className="light-badge-success">Yes</span>
            </If>
            <If condition={!rowData.delivery_completed_at}>
              <span className="light-badge-danger">No</span>
            </If>
          </Cell>
        );
      }
      case 'reference_no': {
        return (
          <Cell rowData={rowData} {...rest}>
            <div className="hover-trigger">
              <span
                className="cursor-pointer"
                onClick={() => {
                  setShowOrderModal(rowData.reference_no);
                }}
              >
                {rowData.reference_no} &nbsp;
              </span>
            </div>
          </Cell>
        );
      }
      default:
        return (
          <Cell rowData={rowData} {...rest}>
            <Whisper placement="autoVerticalStart" trigger="click" speaker={renderMenu}>
              <IconButton appearance="subtle" icon={<MoreIcon />} />
            </Whisper>
          </Cell>
        );
    }
  };
  return (
    <>
      <Modal className="modal-right x1-large" show={!!showOrderModal} onHide={() => setShowOrderModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">#{showOrderModal}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OrdersViewDetail id={showOrderModal} />
        </Modal.Body>
      </Modal>
      <Table height={tableHeight} data={orders} id="table" bordered loading={isLoading}>
        <Column width={150}>
          <HeaderCell>Order #</HeaderCell>
          {/* <Cell>{(rowData) => `${rowData.reference_no}`}</Cell> */}
          <CustomCell dataKey="reference_no" />
        </Column>
        {otherCells.map(({ label, key, width = 90 }) => {
          return (
            <Column width={width} key={key}>
              <HeaderCell>{label}</HeaderCell>
              {/* <Cell>{(rowData) => `${rowData.reference_no}`}</Cell> */}
              <CustomCell dataKey={key} />
            </Column>
          );
        })}
        <Column width={120}>
          <HeaderCell>Order Amount</HeaderCell>
          <Cell>{(rowData) => `AED ${rowData.sub_total}`}</Cell>
        </Column>
        <Column width={220}>
          <HeaderCell>Order Date</HeaderCell>
          <CustomCell dataKey="created_at" />
        </Column>
      </Table>
    </>
  );
};

export default OrderList;
