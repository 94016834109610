import React, { useEffect, useState } from 'react';
import './Listing.scss';
import { useDispatch, useSelector } from 'react-redux';
import 'react-drawer/lib/react-drawer.css';
import { useUrlSearchParams } from 'use-url-search-params';
import moment from 'moment';
import Pagination from '@vlsergey/react-bootstrap-pagination';
import { Form, Modal, Badge } from 'react-bootstrap';

import { Table, Row, Col, DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from 'reactstrap';
import OrdersViewDetail from 'views/orders/OrdersViewDetail';
import ViewQuestionDetail from '../ViewQuestionDetail/ViewQuestionDetail';
import { fetchQaList } from '../../action/action';

const ListingComponent = () => {
  const [searchValue, setSearchValue] = useUrlSearchParams({ search: '' });
  const [paginationValue, setPaginationValue] = useUrlSearchParams({ page: 0 });
  const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 10 });
  const [orderMode] = useState('All');
  const { country = [] } = useSelector((state) => state.country);
  const [showOrderModal, setShowOrderModal] = useState();
  const { currentUser } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const [startDate] = useState('');
  const [endDate] = useState('');

  const fetchMessages = () => {
    if (currentUser?.sellerId) {
      fetchQaList({
        params: {
          start: Number(paginationValue.page * limitValue.limit),
          limit: Number(limitValue.limit),
          search_qry: searchValue.search,
          seller_id: currentUser?.sellerId,
          date_from: startDate,
          date_to: endDate === undefined || endDate === '' ? startDate : endDate,
          sort_by: 'is_replied',
          order_by: 'ASC',
        },
        dispatch,
      });
    }
  };

  useEffect(() => {
    if (currentUser?.sellerId) {
      fetchMessages();
    }
  }, [startDate, paginationValue.page, limitValue, currentUser]);
  const limitByValues = [5, 10, 20, 50];
  const { list } = useSelector((state) => state.messages);
  const listOrders = list;
  const orderData = list?.orderList;

  let pageIndex = Number(listOrders?.start);
  const handlePagination = (e) => {
    setPaginationValue({ page: e.target.value });
  };
  const handleSearch = (e) => {
    setSearchValue({ search: e.target.value });
  };
  const handleLimit = (e) => {
    setLimitValue({ limit: Number(e) });
  };
  const [btnprimary1, setBtnprimary1] = useState(false);

  return (
    <div className="">
      <Modal className="modal-right x2-large" show={!!showOrderModal} onHide={() => setShowOrderModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">#{showOrderModal}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OrdersViewDetail id={showOrderModal} />
        </Modal.Body>
      </Modal>
      <Row className=" dh-TabRow textAlignRight ">
        <Col className="MobileView textAlignRight ">
          <div className="search-box me-2  d-inline-block">
            <div className="position-relative">
              <Form.Control type="text" placeholder="Search" value={searchValue.search} id="listSearch" onChange={handleSearch} />
              <i className="bx bx-search-alt search-icon" />
            </div>
          </div>
        </Col>
      </Row>
      {orderData?.length > 0 ? (
        <>
          <Table className="OrderListTable table mb-0">
            <thead>
              <tr>
                <th>SI.No</th>
                <th>Date</th>
                <th nowrap="nowrap">Reference NO</th>
                <th>Question</th>
                <th>Replied</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {(orderData || []).map((order) => {
                pageIndex += 1;
                const newMessage = Number(order?.is_replied) || order?.created_by !== 'customer' ? '' : 'new-message';
                let orderId = order.entity_id;
                let split = 'All';
                let orderCn = order.country.toLowerCase();
                const orderSplits = order.reference_no.slice(0, 2);
                if ((orderMode === 'split' && country === 'ae') || orderSplits === 'WH' || orderSplits === 'CR' || orderSplits === 'ST') {
                  split = 'split';
                  orderId = order.order_id;
                  orderCn = 'ae';
                } else if ((orderMode === 'b2b' && country === 'ae') || orderSplits === 'BB') {
                  split = 'b2b';
                  orderId = order.order_id;
                  orderCn = 'ae';
                } else if (orderMode === 'store' && (country === 'ae' || orderCn === 'ae')) {
                  split = 'store';
                  orderId = order.entity_id;
                  orderCn = 'ae';
                } else if (orderMode === 'store' && country !== 'ae' && orderCn !== 'ae') {
                  split = 'All';
                  orderId = order.entity_id;
                }

                return (
                  <tr key={order?.qa_id} className={`message-item ${newMessage}`}>
                    <th scope="row" className="serial-no">
                      {pageIndex}
                    </th>

                    <td>
                      <span className="highlightInfo2 ">{moment(order.created_at).format('DD-MM-YYYY hh:mm a')}</span>
                    </td>
                    <td>
                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          setShowOrderModal(order?.reference_no);
                        }}
                      >
                        {order?.reference_no}
                      </span>
                    </td>
                    <td className="QData">
                      <span className="qDetail">{order?.description}</span>
                      <br />
                      <If condition={order?.created_by === 'customer'}>
                        <Badge bg="dark">Asked by customer</Badge>
                      </If>
                    </td>
                    <td>{order?.is_replied === '0' ? 'No' : 'Yes'}</td>
                    <td>
                      <ViewQuestionDetail
                        shipment={order?.reference_no}
                        question={order}
                        openPopup={false}
                        orderCn={orderCn}
                        split={split}
                        orderId={orderId}
                        refetch={fetchMessages}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div className="dataTblFooter">
            <div>
              <Dropdown isOpen={btnprimary1} toggle={() => setBtnprimary1(!btnprimary1)}>
                <DropdownToggle tag="button" className="btn btn-primary">
                  {limitValue.limit} Items <i className="mdi mdi-chevron-down" />
                </DropdownToggle>
                <DropdownMenu>
                  {limitByValues.map((limit, i) => {
                    return (
                      <DropdownItem key={i} href="#" onClick={() => handleLimit(limit)}>
                        {limit} Items
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
            </div>

            <div className="d-flex justify-content-center">
              <Pagination id="listPage" value={paginationValue.page} totalPages={listOrders?.recordsTotal} onChange={(e) => handlePagination(e)} />
            </div>
          </div>
        </>
      ) : (
        <>
          <Table className="OrderListTable table mb-0">
            <thead>
              <tr>
                <th>SI.No</th>
                <th nowrap="nowrap">Reference NO</th>
                <th>Question</th>
                <th>Is Replied</th>
                <th>Action </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={7}>No Records found</td>
              </tr>
            </tbody>
          </Table>
        </>
      )}
    </div>
  );
};
export default ListingComponent;
