/* eslint no-underscore-dangle: 0 */

import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import LayoutFullpage from 'layout/LayoutFullpage';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import HtmlHead from 'components/html-head/HtmlHead';
import { auth, dhSignInWithEmailAndPassword } from 'helpers/Firebase';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUser, setLogin } from 'auth/authSlice';
// import { USER_ROLE } from 'constants/index';
import { DEFAULT_PATHS } from 'config';
// import { initialAuthStateChanged } from 'auth/utils';
import { CHECK_SELLER_ON_BOARDED } from 'views/catalog/actions/sellerActions';
import { useQuery } from '@apollo/client';
import ApolloClientSellerService from 'ApolloClientSellerService';
import { GET_USER_DETAIL } from 'views/catalog/actions/userActions';
import { fetchUserDetail, initialAuthStateChanged } from 'auth/utils';
import { toast } from 'react-toastify';

const Login = () => {
  const title = 'Login';
  const description = 'Login Page';
  const [isLoginProcessing, setLoginProcessing] = useState(false);
  const [loginUserFirebaseData, setLoginUserFirebaseData] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const { isLogin } = useSelector((state) => state.auth);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required('Email is required'),
    password: Yup.string().min(6, 'Must be at least 6 chars!').required('Password is required'),
  });
  const initialValues = { email: '', password: '' };

  const { data, refetch } = useQuery(CHECK_SELLER_ON_BOARDED(), {
    client: ApolloClientSellerService,
    fetchPolicy: 'no-cache',
  });

  const { data: userDetailData } = useQuery(GET_USER_DETAIL(), {
    client: ApolloClientSellerService,
    fetchPolicy: 'no-cache',
  });

  const navigateToApp = (finalData) => {
    // if (userDetailData?.getUserDetail?.isActive && userDetailData?.getUserDetail?.sellers[0]?.isActive) {

    if (finalData?.getUserDetail?._id) {
      dispatch(
        setCurrentUser({
          _id: finalData.getUserDetail?._id,
          id: finalData.getUserDetail?.fuid,
          name: finalData.getUserDetail?.name,
          thumb: '/img/profile/profile-9.webp',
          role: finalData?.getUserDetail?.role,
          email: finalData?.getUserDetail?.email,
          sellerName: finalData.getUserDetail?.sellers?.[0]?.business?.displayName,
          sellerId: finalData?.getUserDetail?.sellers?.[0]?._id,
          isActive: finalData.getUserDetail?.sellers?.[0]?.isActive,
          emailVerified: loginUserFirebaseData?.emailVerified,
          isEligibleForAds: finalData.getUserDetail?.sellers?.[0]?.isEligibleForAds,
        })
      );
      history.push(DEFAULT_PATHS.APP);
      // } else {
      //   history.push(DEFAULT_PATHS.INVALID_ACCESS);
      // }
    }
  };

  useEffect(() => {
    if (isLogin) {
      history.push(DEFAULT_PATHS.APP);
    }
  }, []);

  useEffect(() => {
    if (userDetailData) {
      const finalData = userDetailData;
      navigateToApp(finalData);
    }
  }, [userDetailData]);

  useEffect(() => {
    if (data) {
      if (!data?.checkSellerOnBoarded?.onBoarded && window.localStorage?.authToken && window.localStorage?.authToken !== 'null') {
        history.replace(DEFAULT_PATHS.SELLER_ONBOARDING);
        // } else if (data?.checkSellerOnBoarded?.isActive === 0) {
        //   window.localStorage.authToken = null;
        //   auth.signOut();
        //   dispatch(setLogout());
        //   history.replace(DEFAULT_PATHS.LOGIN);
        //   displayErrorToast('Seller is permanently blocked');
      } else if (data?.checkSellerOnBoarded?.isActive === 3) {
        history.replace({
          pathname: DEFAULT_PATHS.SELLER_ONBOARDING,
          state: { activeStep: 4 },
        });
      } else if (window.localStorage?.authToken) {
        fetchUserDetail((userDetailDataRefetch) => {
          if (userDetailDataRefetch?.getUserDetail.sellers?.[0]) {
            navigateToApp(userDetailDataRefetch);
            // history.push(DEFAULT_PATHS.APP);
          }
        });
      }
    }
  }, [data]);

  useEffect(() => {
    initialAuthStateChanged({
      dispatch,
      history,
      callback: () => {
        fetchUserDetail();
      },
    });
  }, []);

  const showLoginError = () => {
    toast.error(
      <div className="registerError">
        <p>
          <b>Login Error</b>
        </p>
        <p>Your username / password may be incorrect or account not exits</p>
      </div>,
      {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  };
  const onSubmit = (values) => {
    try {
      setLoginProcessing(true);
      dhSignInWithEmailAndPassword(auth, values.email, values.password)
        .then(async (user) => {
          const token = await user?.user?.getIdToken();
          console.log('🚀 ~ file: Login.js ~ line 82 ~ .then ~ token', token);
          setLoginProcessing(false);
          dispatch(
            setLogin({
              token: token,
            })
          );
          console.log(user.user.emailVerified);
          if (!user.user.emailVerified) {
            history.push(DEFAULT_PATHS.EMAIL_VERIFICATION_WAITING);
          } else {
            fetchUserDetail();
            setLoginUserFirebaseData(user.user);
            setTimeout(() => {
              refetch();
            }, 100);
          }
        })
        .catch((e) => {
          console.error(e);
          showLoginError();
          setLoginProcessing(false);
        });
    } catch (e) {
      console.error(e);
      showLoginError();
      setLoginProcessing(false);
    }
    console.log('submit form', values);
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  const leftSide = (
    <div className="min-h-100 d-flex align-items-center">
      <div className="w-100 w-lg-75 w-xxl-50">
        <div>
          <div className="mb-5">
            {/* <h1 className="display-3 text-white">Seller Hub</h1>
            <h1 className="display-3 text-white">Ready for Your Project</h1> */}
          </div>
          {/* <p className="h6 text-white lh-1-5 mb-5">Welcome to Seller Hub.</p> */}
          <div className="mb-5">
            {/* <Button size="lg" variant="outline-white" href="/">
              Learn More
            </Button> */}
          </div>
        </div>
      </div>
    </div>
  );

  const rightSide = (
    <div className="sw-lg-70 min-h-100 bg-foreground d-flex justify-content-center align-items-center shadow-deep py-5 full-page-content-right-border">
      <div className="sw-lg-50 px-5">
        <div className="mb-7">
          <img src="/img/profile/logo.png" alt="logo" width="400" />
        </div>
        <div className="mb-3">
          <h2 className="cta-1 mb-0 " style={{ color: 'black' }}>
            Welcome Back !
          </h2>
        </div>
        <div className="mb-6">
          <h2 className="cta-1" style={{ color: 'black', fontSize: 16 }}>
            Sign in to continue to Danube Home .
          </h2>
        </div>

        <div className="mb-7">
          <p className="h6" style={{ color: 'black' }}>
            Please use your credentials to login.
          </p>
          <p className="h5" style={{ color: 'black' }}>
            If you are not a member, please{' '}
            <NavLink to="/register" style={{ color: '#c4161c' }}>
              register
            </NavLink>
            .
          </p>
        </div>
        <div>
          <form id="loginForm" className="tooltip-end-bottom" onSubmit={handleSubmit}>
            <div className="mb-3 filled form-group tooltip-end-top">
              <CsLineIcons icon="email" />
              <Form.Control type="text" name="email" placeholder="Email" value={values.email} onChange={handleChange} />
              {errors.email && touched.email && <div className="d-block invalid-tooltip">{errors.email}</div>}
            </div>
            <div className="mb-3 filled form-group tooltip-end-top">
              <CsLineIcons icon="lock-off" />
              <Form.Control type="password" name="password" onChange={handleChange} value={values.password} placeholder="Password" />
              <NavLink className="text-small position-absolute t-3 e-3" to="/forgot-password" style={{ color: '#c4161c' }}>
                Forgot?
              </NavLink>
              {errors.password && touched.password && <div className="d-block invalid-tooltip">{errors.password}</div>}
            </div>
            <Button id="loginButton" size="lg" type="submit" disabled={isLoginProcessing} style={{ backgroundColor: '#c4161c' }}>
              Login
            </Button>
          </form>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <HtmlHead title={title} description={description} />
      <LayoutFullpage left={leftSide} right={rightSide} />
    </>
  );
};

export default Login;
