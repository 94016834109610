import { createSlice } from '@reduxjs/toolkit';

// import { DEFAULT_USER, IS_DEMO } from 'config.js';

const initialState = {
  returnStatus:[],
  isOrderLoading: true,
};

const returnStatusSlice = createSlice({
  name: 'returnStatus',
  initialState,
  reducers: {
    setReturnStatus(state, actions) {
      state.isOrderLoading = false;
      state.returnStatus = actions.payload;
    },
  },
});

export const { setReturnStatus } = returnStatusSlice.actions;

const returnStatusReducer = returnStatusSlice.reducer;
export default returnStatusReducer;
