import { createSlice } from '@reduxjs/toolkit';

// import { DEFAULT_USER, IS_DEMO } from 'config.js';

const initialState = {
  statementStatus: [],
  isstatementLoading: true,
};

const statementStatusSlice = createSlice({
  name: 'statementStatus',
  initialState,
  reducers: {
    setStatementStatus(state, actions) {
      state.isstatementLoading = false;
      state.statementStatus = actions.payload;
    },
  },
});

export const { setStatementStatus } = statementStatusSlice.actions;

const statementStatusReducer = statementStatusSlice.reducer;
export default statementStatusReducer;
