/* eslint no-underscore-dangle: 0 */

import { displayErrorToast, displaySuccessToast } from 'helpers/toaster';
import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Modal, Form, Spinner } from 'react-bootstrap';
// import ApolloClientSellerService from 'ApolloClientSellerService';
// import { useMutation } from '@apollo/client';
// import { GET_SELLER_DETAIL } from 'views/account/actions/getSellerDetailAction';
import { generateSellerSOA } from './actions/statementActions';

const SOAModal = (props) => {
  const { statementPeriodId } = props;
  const [agreedToTerms, setAgreeToTerms] = useState(false);
  const [waitingResponse, setWaitingResponse] = useState(false);
  // const [getSellerInformation, { data }] = useMutation(GET_SELLER_DETAIL, { client: ApolloClientSellerService });
  const handleActionChange = (e) => {
    setAgreeToTerms(e.target.checked);
  };
  useEffect(() => {
    // getSellerInformation({ variables: {} });
  }, []);
  const handleSubmit = async (values) => {
    try {
      setWaitingResponse(true);
      // const sellerDetails = data?.getSellerDetailById || {};
      console.log(values);
      // const formData = new FormData();
      // formData.append('sellerId', sellerDetails?._id);
      // formData.append('supplierWebId', sellerDetails?.supplierWebId);
      // formData.append('statementPeriodId', props?.statementPeriodId);

      const result = await generateSellerSOA({ statementPeriodId: statementPeriodId || '' });
      setWaitingResponse(false);
      if (!result.data?.error) {
        displaySuccessToast('SOA Generated Successfully!');
        props.onHide();
      } else {
        displayErrorToast(result.data?.message);
      }
      console.log(result);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" fullscreen centered backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Statement of Accounts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {/* <Col md="4">
              
            </Col> */}
            <Col md="12" className="shadow-lg">
              <iframe
                src={`${process.env.SELLER_HUB_SELLER_GRAPHQL_API}/preview-soa?statementPeriodId=${statementPeriodId || ''}&authorization=${
                  window.localStorage.authToken
                }`}
                title="Statement of Accounts Preview"
                style={{ width: '100%', height: '800px' }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="p-2">
          <div className="form-group">
            <Form.Check
              type="checkbox"
              name="agreedToTerms"
              onChange={(event) => {
                handleActionChange(event);
              }}
              label={<span className="text-medium">By Generating the Statement, you are agreeing to the transactions in the statement.</span>}
            />
          </div>
          <div className="d-flex align-items-center">
            <Button id="submitButton" size="lg" type="submit" disabled={waitingResponse || !agreedToTerms} onClick={handleSubmit}>
              {waitingResponse && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
              Generate SOA
            </Button>
          </div>
          <Button onClick={() => props.onHide()} variant="light">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SOAModal;
