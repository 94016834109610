import ApolloClientSellerService from 'ApolloClientSellerService';
import { gql } from '@apollo/client';
import { getHeaders } from 'auth/utils';
import axios from 'axios';
import { OMS_API } from 'constants/index';
import { setDashboardStatus } from 'dashboardSlice/dasboardStatus';

const apiUrl = OMS_API.BASE_URL;
// const token = 'RGFudWJlQXBpOkRhbnViZUAyMDIyJA==';

const SELLER_STATS_GQL = gql`
  query sellerStats {
    sellerStats {
      stats {
        bigText
        suffix
        bottomText
        compareText
        compareValue
        rate
      }
      deliveryCalender {
        expected_delivery_date
        numberOfDelivery
        percentage
        completed
      }
    }
  }
`;

export const getSellerStats = async () => {
  try {
    const results = await ApolloClientSellerService.query({
      query: SELLER_STATS_GQL,
      // variables: {...params},
      fetchPolicy: 'network-only',
    });
    //  console.log('getSellerDashboard Results:', results);
    return results?.data.sellerStats;
  } catch (e) {
    console.error(e);
    return {};
  }
};

const SELLER_DASHBOARD_GQL = gql`
  query sellerDashboard($sellerId: String) {
    sellerDashboard(sellerId: $sellerId) {
      salesToday
      unitsSoldToday
      unitsSold
      lateDelivery
      defectCount
      totalPaidRevenue
      totalUnPaidRevenue
      nextPaymentDate
      delayedShipping
      orderRejections
      orderReturns
      unfulfilledOrders
      deliveredOnTime
      noOfOrders
      lowStockProducts
      messages
      totalProducts
      productRating {
        ratingAverage
        totalReviews
      }
      sellerRating {
        ratingAverage
        totalReviews
      }
      productAsDescribed {
        totalProduct
        describedProduct
      }

      seller {
        _id
        business {
          name
          displayName
          contactNumber
        }

        isActive
        supplierWebId
        isEligibleForAds
      }
    }
  }
`;

export const MAIN_DASHBOARD_GQL = () => gql`
  query mainDashboard($last30Days: Boolean) {
    mainDashboard(last30Days: $last30Days) {
      productRatings {
        totalReviews
        star5 {
          percent
          count
        }
        star4 {
          percent
          count
        }
        star3 {
          percent
          count
        }
        star2 {
          percent
          count
        }
        star1 {
          percent
          count
        }
        ratingAverage
      }
      sellerRatings {
        totalReviews
        star5 {
          percent
          count
        }
        star4 {
          percent
          count
        }
        star3 {
          percent
          count
        }
        star2 {
          percent
          count
        }
        star1 {
          percent
          count
        }
        ratingAverage
      }
      productAsDescribed {
        totalProduct
        describedProduct
      }
    }
  }
`;

export const getDashboardStatusCounts = async ({ params, dispatch }) => {
  return axios
    .post(`${apiUrl}/order/dashboard_supplier`, { ...params }, { ...getHeaders() })
    .then((response) => {
      console.log(response, 'response-yaaa');
      dispatch(setDashboardStatus(response?.data));

      console.log(dispatch);
      console.log(response, 'hhhnnnmm');
    })
    .catch((error) => {
      throw error;
    });
};

export const getSellerDashboard = async ({ params }) => {
  const results = await ApolloClientSellerService.query({ query: SELLER_DASHBOARD_GQL, variables: { ...params }, fetchPolicy: 'network-only' });
  console.log('getSellerDashboard Results:', results);
  return results?.data.sellerDashboard;
};

export const TOP_SELLING_ITEMS = () => gql`
  query topSellingItems($sellerId: String, $type: String) {
    topSellingItems(sellerId: $sellerId, type: $type) {
      name
      image
      sku
      totalRevenue
      totalQuantity
    }
  }
`;
export const TOP_PERFORMING_CATEGORIES = () => gql`
  query topPerformingCategories($sellerId: Int) {
    topPerformingCategories(sellerId: $sellerId) {
      month
      year
      monthId
      topCategories {
        category
        categoryName
        totalQuantity
      }
    }
  }
`;
export const GET_SALES_ANALYSIS = () => gql`
  query salesAnalysis($sellerId: String) {
    salesAnalysis(sellerId: $sellerId) {
      gmvYestarday
      salesToday
      unitsSoldToday
      thisMonthSales
      thisWeekSales
      aspToday
      monthlySales {
        year
        month
        monthId
        totalSales
        totalQuantity
      }
      categoryLevel1Sales {
        month
        year
        monthId
        topCategories {
          category
          categoryName
          totalQuantity
          totalRevenue
        }
      }
      categoryLevel2Sales {
        month
        year
        monthId
        topCategories {
          category
          categoryName
          totalQuantity
          totalRevenue
        }
      }
      categoryLevel3Sales {
        month
        year
        monthId
        topCategories {
          category
          categoryName
          totalQuantity
          totalRevenue
        }
      }
    }
  }
`;
