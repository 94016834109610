/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
/* eslint no-underscore-dangle: 0 */
import { DAY_VALUES } from 'constants/index';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import isEmpty from 'lodash.isempty';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, FormLabel, Row, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import WarehouseLocation from 'views/account/WarehouseLocation';
import * as Yup from 'yup';
import countries from './Countries.json';
import cityList from './CityList.json';

const WizardFour = (props) => {
  const [sellerInfo, setSellerInfo] = useState(
    props.user?.warehouse[0] ||
      (window.localStorage.onBoardingWarehouse && JSON.parse(window.localStorage.onBoardingWarehouse)) || {
        id: 'WH1',
        country: 'AE',
        operatingDays: [0, 1, 2, 3, 4, 5, 6],
      }
  );
  const [errors, setError] = useState({});
  const { isLogin, currentUser, phone } = useSelector((state) => state.auth);
  useEffect(() => {
    if (!sellerInfo.phone && window.localStorage.registeredPhoneNumber) {
      setSellerInfo({ ...sellerInfo, phone: window.localStorage.registeredPhoneNumber });
    }
    if (!sellerInfo.email && currentUser.email) {
      setSellerInfo({ ...sellerInfo, email: currentUser.email });
    }
  }, []);

  const onInputChanged = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setSellerInfo((sellerInfo) => ({
      ...sellerInfo,
      [targetName]: targetValue,
    }));
    setError({ ...errors, [targetName]: '' });
  };

  const onChangeOperatingDays = (e, dayValue) => {
    const oldSellerInfo = { ...sellerInfo };
    if (!oldSellerInfo.operatingDays) {
      oldSellerInfo.operatingDays = [];
    }
    if (e.target.checked) {
      oldSellerInfo.operatingDays.push(dayValue);
    } else {
      const dayIndex = oldSellerInfo.operatingDays.findIndex((d) => d === dayValue);
      oldSellerInfo.operatingDays.splice(dayIndex, 1);
    }
    setSellerInfo({ ...oldSellerInfo });
  };

  useEffect(() => {
    if (!isEmpty(sellerInfo)) {
      window.localStorage.onBoardingWarehouse = JSON.stringify(sellerInfo);
    }
  }, [sellerInfo]);

  const validate = () => {
    const validationSchema = Yup.object().shape({
      id: Yup.string().required('Warehouse unique id is required'),
      address: Yup.string().required('Address is required'),
      area: Yup.string().required('Area is required'),
      city: Yup.string().required('City is required'),
      latitude: Yup.string().required('Latitude is required'),
      longitude: Yup.string().required('Longitude is required'),
      region: Yup.string().required('Region is required'),
      country: Yup.string().required('Country is required'),
    });

    validationSchema
      .validate(sellerInfo, { abortEarly: false })
      .then(function () {
        setError({});
        props.completeCallback(sellerInfo);
      })
      .catch(function (err) {
        const newErrors = {};
        err.inner.forEach((obj) => {
          newErrors[obj.path] = obj.message;
        });
        setError(newErrors);
      });
  };

  return (
    <>
      <Card>
        <Card.Header className="p-3">
          <h2 className="small-title p-0">Warehouse Information</h2>
        </Card.Header>
        <Card.Body className="p-0">
          <Row>
            <Col>
              <Card.Body>
                <FormLabel>Warehouse Name</FormLabel>
                <div className="mb-3">
                  <Form.Control type="text" name="id" value={sellerInfo?.id} onChange={(e) => onInputChanged(e)} />
                  <span style={{ color: 'red' }}>{errors.id}</span>
                </div>
                <FormLabel>Address</FormLabel>
                <div className="mb-3">
                  <Form.Control type="text" name="address" defaultValue={sellerInfo?.address} onChange={(e) => onInputChanged(e)} />
                  <span style={{ color: 'red' }}>{errors.address}</span>
                </div>
                <FormLabel>Area</FormLabel>
                <div className="mb-3">
                  <Form.Control type="text" name="area" defaultValue={sellerInfo?.area} onChange={(e) => onInputChanged(e)} />
                  <span style={{ color: 'red' }}>{errors.area}</span>
                </div>
                <FormLabel>City</FormLabel>
                {/* <div className="mb-3">
                  <Form.Control type="text" name="city" defaultValue={sellerInfo?.city} onChange={(e) => onInputChanged(e)} />
                  <span style={{ color: 'red' }}>{errors.city}</span>
                </div> */}
                <div className="mb-3 filled">
                  <Form.Select name="city" value={sellerInfo?.city} onChange={(e) => onInputChanged(e)}>
                    <option value="" />
                    {cityList.map((e, key) => {
                      return (
                        <option key={`city-${key}`} value={e.value}>
                          {e.label}
                        </option>
                      );
                    })}
                  </Form.Select>
                </div>
                <h5 className="font-weight-bold mt-4 mb-3">Contact Details</h5>
                <Row>
                  <Col>
                    <FormLabel>
                      Contact Person <span className="text-danger">*</span>
                    </FormLabel>
                    <div className="mb-3 filled">
                      <CsLineIcons icon="user" />
                      <Form.Control type="text" name="contacts" value={sellerInfo?.contacts} onChange={(e) => onInputChanged(e)} />
                    </div>
                    <FormLabel>
                      Email <span className="text-danger">*</span>
                    </FormLabel>
                    <div className="mb-3 filled">
                      <CsLineIcons icon="email" />
                      <Form.Control type="text" name="email" value={sellerInfo?.email} onChange={(e) => onInputChanged(e)} />
                    </div>
                    <FormLabel>
                      Phone Number<span className="text-danger">*</span>
                    </FormLabel>
                    <div className="mb-3 filled">
                      <CsLineIcons icon="phone" />
                      <Form.Control type="text" name="phone" value={sellerInfo?.phone} onChange={(e) => onInputChanged(e)} />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Col>
            <Col>
              <Card.Body>
                <FormLabel>Country</FormLabel>
                <div className="mb-3">
                  <Form.Select name="country" value={sellerInfo?.country} onChange={(e) => onInputChanged(e)}>
                    {countries.map((e, key) => {
                      return (
                        <option key={`country-${key}`} value={e.value}>
                          {e.label}
                        </option>
                      );
                    })}
                  </Form.Select>
                  <span style={{ color: 'red' }}>{errors.country}</span>
                </div>
                <FormLabel>Region</FormLabel>
                <div className="mb-3">
                  <Form.Select name="region" value={sellerInfo?.region} onChange={(e) => onInputChanged(e)}>
                    <option value="" />
                    {cityList
                      .find((c) => c.value === sellerInfo?.city)
                      ?.regions?.map((e, key) => {
                        return (
                          <option key={`region-${sellerInfo?.country}-${key}`} value={e}>
                            {e}
                          </option>
                        );
                      })}
                    {/* {countries
                      .find((c) => c.value === sellerInfo?.country)
                      ?.regions?.map((e, key) => {
                        return (
                          <option key={`region-${sellerInfo?.country}-${key}`} value={e.value}>
                            {e.label}
                          </option>
                        );
                      })} */}
                  </Form.Select>
                  <span style={{ color: 'red' }}>{errors.region}</span>
                </div>
                <WarehouseLocation
                  update={(location) => {
                    const oldSellerInfo = { ...sellerInfo };
                    oldSellerInfo.latitude = String(location.lat);
                    oldSellerInfo.longitude = String(location.lng);
                    setSellerInfo({ ...oldSellerInfo });
                  }}
                />
                <FormLabel>Latitude</FormLabel>
                <div className="mb-3">
                  <Form.Control
                    type="text"
                    value={sellerInfo?.latitude}
                    name="latitude"
                    defaultValue={sellerInfo?.latitude}
                    onChange={(e) => onInputChanged(e)}
                  />
                  <span style={{ color: 'red' }}>{errors.latitude}</span>
                </div>
                <FormLabel>Longitude</FormLabel>
                <div className="mb-3">
                  <Form.Control
                    type="text"
                    value={sellerInfo?.longitude}
                    name="longitude"
                    defaultValue={sellerInfo?.longitude}
                    onChange={(e) => onInputChanged(e)}
                  />
                  <span style={{ color: 'red' }}>{errors.longitude}</span>
                </div>
                <h5 className="font-weight-bold mb-3">Operating Days</h5>
                {DAY_VALUES.map((day) => {
                  return (
                    <Form.Check
                      key={day.value}
                      inline
                      label={day.label}
                      name="days"
                      type="checkbox"
                      checked={sellerInfo?.operatingDays?.includes(day.value)}
                      onChange={(e) => onChangeOperatingDays(e, day.value)}
                    />
                  );
                })}
              </Card.Body>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Button variant="outline-primary" className="me-2" onClick={() => props.setActiveStep(props.activeStep - 1)}>
            Back
          </Button>
          <Button disabled={props.isCompleteLoading} onClick={validate}>
            {props.isCompleteLoading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
            Finish
          </Button>
        </Card.Footer>
      </Card>
    </>
  );
};

export default WizardFour;
