/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
/* eslint no-underscore-dangle: 0 */
import { SELLER_ONBOARDING } from 'constants/index';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import isEmpty from 'lodash.isempty';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, FormLabel, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

const WizardOne = (props) => {
  const [sellerInfo, setSellerInfo] = useState(
    props.user?.business || (window.localStorage.onBoardingBusiness && JSON.parse(window.localStorage.onBoardingBusiness)) || {}
  );
  const { isLogin, currentUser, phone } = useSelector((state) => state.auth);
  const [errors, setError] = useState({});
  useEffect(() => {
    if (!sellerInfo.contactNumber && window.localStorage.registeredPhoneNumber) {
      setSellerInfo({ ...sellerInfo, contactNumber: window.localStorage.registeredPhoneNumber });
    }
  }, []);

  const onInputChanged = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setSellerInfo((sellerInfo) => ({
      ...sellerInfo,
      [targetName]: targetValue,
    }));
    setError({ ...errors, [targetName]: '' });
  };
  useEffect(() => {
    if (!isEmpty(sellerInfo)) {
      window.localStorage.onBoardingBusiness = JSON.stringify(sellerInfo);
    }
  }, [sellerInfo]);

  const validate = () => {
    const validationSchema = Yup.object().shape({
      name: Yup.string().min(3).required('Legal Full Name is required'), // .matches(SELLER_ONBOARDING.REG_EXP_ALPHABETS, 'Only alphabets are allowed'),
      displayName: Yup.string().min(3).required('Short Name is required'), // .matches(SELLER_ONBOARDING.REG_EXP_ALPHABETS, 'Only alphabets are allowed'),
      contactNumber: Yup.string().min(9).max(12).required('Contact number is required').matches(SELLER_ONBOARDING.REG_EXP_DIGITS, 'Only Digits are allowed'),
      trnNumber: Yup.string().required('TRN is required').matches(SELLER_ONBOARDING.REG_EXP_DIGITS, 'Only Digits are allowed'),
      tradeLicense: Yup.string().required('Trade license is required'),
    });

    validationSchema
      .validate(sellerInfo, { abortEarly: false })
      .then(function () {
        setError({});
        props.setActiveStep(props.activeStep + 1);
        props.userCallback(sellerInfo);
      })
      .catch(function (err) {
        const newErrors = {};
        err.inner.forEach((obj) => {
          newErrors[obj.path] = obj.message;
        });
        setError(newErrors);
      });
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Row className=" mb-2">
            <Col lg={6} sm={12}>
              <Card className="mb-2">
                <Card.Header className="p-1">
                  <h2 className="small-title">Business Information</h2>
                </Card.Header>
                <Card.Body>
                  <FormLabel>
                    Legal Business Name <span className="text-danger">*</span>
                  </FormLabel>
                  <div className="mb-3 filled">
                    <CsLineIcons icon="building" />
                    <Form.Control type="text" name="name" value={sellerInfo?.name} onChange={onInputChanged} />
                    <span style={{ color: 'red' }} className="text-medium mt-2">
                      {errors.name}
                    </span>
                  </div>
                  <FormLabel>
                    Short Name <span className="text-danger">*</span>
                  </FormLabel>
                  <div className="mb-3 filled">
                    <CsLineIcons icon="tag" />
                    <Form.Control type="text" name="displayName" value={sellerInfo?.displayName} onChange={onInputChanged} />
                    <span style={{ color: 'red' }} className="text-medium mt-2">
                      {errors.displayName}
                    </span>
                  </div>
                  <FormLabel>
                    Company Phone Number <span className="text-danger">*</span>
                  </FormLabel>
                  <div className="mb-3 filled">
                    <CsLineIcons icon="phone" />
                    <Form.Control type="text" name="contactNumber" value={sellerInfo?.contactNumber} onChange={onInputChanged} />
                    <span style={{ color: 'red' }} className="text-medium mt-2">
                      {errors.contactNumber}
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6} sm={12}>
              <Card>
                <Card.Header className="p-1">
                  <h2 className="small-title">Company Details</h2>
                </Card.Header>
                <Card.Body>
                  <FormLabel>
                    TRN <span className="text-danger">*</span>
                  </FormLabel>
                  <div className="mb-3 ">
                    <Form.Control type="text" name="trnNumber" value={sellerInfo?.trnNumber} onChange={onInputChanged} />
                    <span style={{ color: 'red' }} className="text-medium mt-2">
                      {errors.trnNumber}
                    </span>
                  </div>
                  <FormLabel>
                    Trade License / Commercial Registration. Document No. <span className="text-danger">*</span>
                  </FormLabel>
                  <div className="mb-3 ">
                    <Form.Control type="text" name="tradeLicense" value={sellerInfo?.tradeLicense} onChange={onInputChanged} />
                    <span style={{ color: 'red' }} className="text-medium mt-2">
                      {errors.tradeLicense}
                    </span>
                  </div>
                  {/* <FormLabel>
                Commission Rate
              </FormLabel>
              <div className="mb-3 filled">
                <CsLineIcons icon="money" />
                <Form.Control type="number" name="commissionRate" value={sellerInfo?.commissionRate} onChange={onInputChanged} />
              </div> */}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <br />
        </Card.Body>
        <Card.Footer>
          <Button onClick={validate}>Next</Button>
        </Card.Footer>
      </Card>
    </>
  );
};

export default WizardOne;
