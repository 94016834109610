import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, updateProfile } from 'firebase/auth';

import { FIREBASE_CONFIG } from 'constants/auth';

const app = initializeApp(FIREBASE_CONFIG);

export const auth = getAuth();
export const dhCreateUserWithEmailAndPassword = createUserWithEmailAndPassword;
export const dhSignInWithEmailAndPassword = signInWithEmailAndPassword;
export const dhSendPasswordResetEmail = sendPasswordResetEmail;
export const dhGetAuth = getAuth;
export const dhUpdateProfile = updateProfile;
// export const provider = new EmailAuthProvider();
// provider.setCustomParameters({ prompt: 'select_account' });

export const getStartToken = async () => {
  try {
    const messaging = getMessaging(app);
    const token = await getToken(messaging, { vapidKey: 'BP0NFWhC91owgpHVVykEstAj9ThURsPYpUENPp7sKigNaWR-OlJ0IHmieJJUtTEDVpzdRmfwYNeQbtCtniNnrXI' });
    if (token) {
      return token;
    }
    const permission = await messaging.requestPermission();
    if (permission === 'granted') {
      console.log('Permission Granted');
      // calls method again and to sent token to server
      await getStartToken();
    } else {
      console.log('Permission Denied');
    }
    return '';
  } catch (error) {
    console.log('🚀 ~ file: Firebase.js:42 ~ getStartToken ~ error', error);
    return '';
  }
};

export default app;
