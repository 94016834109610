import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { dhGetAuth } from 'helpers/Firebase';
import { onError } from '@apollo/client/link/error';

const httpLink = createHttpLink({
  uri: (req) => {
    return `${process.env.SELLER_HUB_SELLER_GRAPHQL_API}/gql?operationName=${req.operationName}&platform=sellerhub`;
  },
});
const authLink = setContext((_, { headers }) => {
  // it will always get unexpired version of the token
  try {
    return dhGetAuth()
      .currentUser.getIdToken()
      .then((token) => {
        window.localStorage.authToken = token;
        return {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
          },
        };
      });
  } catch (e) {
    if (window.localStorage.authToken)
      return new Promise((resolve) => {
        resolve({
          headers: {
            ...headers,
            authorization: window.localStorage.authToken ? `Bearer ${window.localStorage.authToken}` : '',
          },
        });
      });
    return {};
  }
});
// const link = ApolloLink.from([authLink, ...{/**ur other links */}])
const link = ApolloLink.from([
  onError(({ graphQLErrors, networkError }) => {
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`));
    }
  }),
  authLink,
  httpLink,
]);

const ApolloClientSellerService = new ApolloClient({
  link,
  uri: `${process.env.SELLER_HUB_SELLER_GRAPHQL_API}/gql?platform-sellerhub`,
  cache: new InMemoryCache({
    addTypename: false,
  }),
  errorPolicy: 'ignore',
});
export default ApolloClientSellerService;
