import { gql } from '@apollo/client';

export const CREATE_USER = () => gql`
  mutation addUser($email: String!, $name: String!, $role: String!, $password: String, $contactNumber: String) {
    addUser(email: $email, name: $name, role: $role, password: $password, contactNumber: $contactNumber) {
      success
      message
    }
  }
`;

export const GET_USER_LIST = () => gql`
  query listAllUsersWithSeller($page: Int, $limit: Int, $search: String, $orderByField: String, $order: Int) {
    listAllUsersWithSeller(page: $page, limit: $limit, search: $search, orderByField: $orderByField, order: $order) {
      _id
      email
      role
      name
      contactNumber
      isActive
      createdAt
    }
    sellerUsersCount(page: $page, limit: $limit, search: $search, orderByField: $orderByField, order: $order) {
      totalPages
      totalItems
    }
  }
`;

export const GET_USER_DETAIL = () => gql`
  query getUserDetail {
    getUserDetail {
      _id
      name
      email
      fuid
      role
      isActive
      sellers {
        _id
        isEligibleForAds
        business {
          name
          displayName
          contactNumber
        }

        isActive
        supplierWebId
      }
    }
  }
`;

export const EDIT_USER = () => gql`
  mutation editUser($_id: String, $name: String, $role: String, $isActive: Boolean, $token: String, $contactNumber: String) {
    editUser(_id: $_id, name: $name, role: $role, isActive: $isActive, token: $token, contactNumber: $contactNumber) {
      success
      message
    }
  }
`;

export const DELETE_USER = () => gql`
  mutation deleteUser($_id: String) {
    deleteUser(_id: $_id) {
      success
      message
    }
  }
`;
