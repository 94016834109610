import React from 'react';
import classNames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { USE_MULTI_LANGUAGE } from 'config.js';
import { userHasPrivilege } from 'routing/helper';

const SidebarMenuItems = React.memo(({ menuItems = [], isActive }) => {
  return menuItems.map((item, index) => {
    if (isActive && userHasPrivilege(isActive, item.path)) {
      return <SidebarMenuItem key={`menu.${item.path}.${index}`} id={item.path} item={item} isActive={isActive} />;
    }
    if (!isActive) {
      return <SidebarMenuItem key={`menu.${item.path}.${index}`} id={item.path} item={item} isActive={isActive} />;
    }
    return null;
  });
});
SidebarMenuItems.displayName = 'SidebarMenuItems';

const SidebarMenuItem = ({ item, id, isActive }) => {
  const { pathname } = useLocation();
  const { formatMessage: f } = useIntl();

  const isActiveItem = item.path.startsWith('#') ? false : pathname === item.path || pathname.indexOf(`${item.path}/`) > -1;

  const getLabel = (icon, label, iconSize) => (
    <>
      {icon && (
        <>
          <CsLineIcons icon={icon} size={iconSize} className="cs-icon icon" />{' '}
        </>
      )}
      <span className="label" data-testid={`side-menu-${label}`}>
        {USE_MULTI_LANGUAGE ? f({ id: label }) : label}
      </span>
    </>
  );

  if (item.subs) {
    return (
      <li>
        <NavLink to={item.path} className={classNames({ active: isActiveItem })} data-bs-target={item.path}>
          {getLabel(item.icon, item.label, item.iconSize)}
        </NavLink>
        <ul>
          <SidebarMenuItems menuItems={item.subs} isActive={isActive} />
        </ul>
      </li>
    );
  }
  if (item.isExternal) {
    return (
      <li key={id}>
        <a href={item.link} target="_blank" rel="noopener noreferrer">
          {getLabel(item.icon, item.label, item.iconSize)}
        </a>
      </li>
    );
  }
  return (
    <li>
      <NavLink to={item.path} className={classNames({ active: isActiveItem })} activeClassName="">
        {getLabel(item.icon, item.label, item.iconSize)}
      </NavLink>
    </li>
  );
};

export default SidebarMenuItems;
