const enMessages = {
  'menu.dashboards': 'de|Dashboards',
  'menu.default': 'de|Default',
  'menu.visual': 'de|Visual',
  'menu.analytic': 'de|Analytic',
  'menu.apps': 'de|Apps',
  'menu.calendar': 'de|Calendar',
  'menu.chat': 'de|Chat',
  'menu.contacts': 'de|Contacts',
  'menu.mailbox': 'de|Mailbox',
  'menu.tasks': 'de|Tasks',
  'menu.pages': 'de|Pages',
  'menu.authentication': 'de|Authentication',
  'menu.login': 'de|Login',
  'menu.register': 'de|Register',
  'menu.forgot-password': 'de|Forgot Password',
  'menu.reset-password': 'de|Reset Password',
  'menu.blog': 'de|Blog',
  'menu.home': 'de|Home',
  'menu.grid': 'de|Grid',
  'menu.list': 'de|List',
  'menu.detail': 'de|Detail',
  'menu.miscellaneous': 'de|Miscellaneous',
  'menu.faq': 'de|Faq',
  'menu.knowledge-base': 'de|Knowledge Base',
  'menu.error': 'de|Error',
  'menu.coming-soon': 'de|Coming Soon',
  'menu.pricing': 'de|Pricing',
  'menu.search': 'de|Search',
  'menu.mailing': 'de|Mailing',
  'menu.empty': 'de|Empty',
  'menu.portfolio': 'de|Portfolio',
  'menu.profile': 'de|Profile',
  'menu.standard': 'de|Standard',
  'menu.settings': 'de|Settings',
  'menu.blocks': 'de|Blocks',
  'menu.images': 'de|Images',
  'menu.tabular-data': 'de|Tabular Data',
  'menu.thumbnails': 'de|Thumbnails',
  'menu.cta': 'de|Cta',
  'menu.gallery': 'de|Gallery',
  'menu.stats': 'de|Stats',
  'menu.steps': 'de|Steps',
  'menu.details': 'de|Details',
  'menu.interface': 'de|Interface',
  'menu.components': 'de|Components',
  'menu.accordion': 'de|Accordion',
  'menu.alerts': 'de|Alerts',
  'menu.badge': 'de|Badge',
  'menu.breadcrumb': 'de|Breadcrumb',
  'menu.buttons': 'de|Buttons',
  'menu.button-group': 'de|Button Group',
  'menu.card': 'de|Card',
  'menu.close-button': 'de|Close Button',
  'menu.collapse': 'de|Collapse',
  'menu.dropdowns': 'de|Dropdowns',
  'menu.list-group': 'de|List Group',
  'menu.modal': 'de|Modal',
  'menu.navs': 'de|Navs',
  'menu.offcanvas': 'de|Offcanvas',
  'menu.pagination': 'de|Pagination',
  'menu.popovers': 'de|Popovers',
  'menu.progress': 'de|Progress',
  'menu.spinners': 'de|Spinners',
  'menu.toasts': 'de|Toasts',
  'menu.tooltips': 'de|Tooltips',
  'menu.forms': 'de|Forms',
  'menu.layouts': 'de|Layouts',
  'menu.validation': 'de|Validation',
  'menu.wizard': 'de|Wizard',
  'menu.input-group': 'de|Input Group',
  'menu.input-mask': 'de|Input Mask',
  'menu.generic-forms': 'de|Generic Forms',
  'menu.controls': 'de|Controls',
  'menu.autocomplete': 'de|Autocomplete',
  'menu.checkbox-radio': 'de|Checkbox-Radio',
  'menu.date-picker': 'de|Date Picker',
  'menu.dropzone': 'de|Dropzone',
  'menu.editor': 'de|Editor',
  'menu.input-spinner': 'de|Input Spinner',
  'menu.rating': 'de|Rating',
  'menu.select': 'de|Select',
  'menu.slider': 'de|Slider',
  'menu.tags': 'de|Tags',
  'menu.time-picker': 'de|Time Picker',
  'menu.plugins': 'de|Plugins',
  'menu.carousel': 'de|Carousel',
  'menu.charts': 'de|Charts',
  'menu.clamp': 'de|Clamp',
  'menu.context-menu': 'de|Context Menu',
  'menu.datatables': 'de|Datatables',
  'menu.editable-rows': 'de|Editable Rows',
  'menu.editable-boxed': 'de|Editable Boxed',
  'menu.server-side': 'de|Server Side',
  'menu.boxed-variations': 'de|Boxed Variations',
  'menu.lightbox': 'de|Lightbox',
  'menu.maps': 'de|Maps',
  'menu.notification': 'de|Notification',
  'menu.players': 'de|Players',
  'menu.scrollbar': 'de|Scrollbar',
  'menu.shortcuts': 'de|Shortcuts',
  'menu.sortable': 'de|Sortable',
  'menu.content': 'de|Content',
  'menu.icons': 'de|Icons',
  'menu.cs-line-icons': 'de|CS Line Icons',
  'menu.cs-interface-icons': 'de|CS Interface Icons',
  'menu.bootstrap-icons': 'de|Bootstrap Icons',
  'menu.tables': 'de|Tables',
  'menu.typography': 'de|Typography',
  'menu.menu': 'de|Menu',
  'menu.horizontal': 'de|Horizontal',
  'menu.vertical': 'de|Vertical',
  'menu.vertical-hidden': 'de|Vertical Hidden',
  'menu.vertical-no-hidden': 'de|Vertical No Hidden',
  'menu.mobile-only': 'de|Mobile Only',
  'menu.sidebar': 'de|Sidebar',
  'menu.Docs': 'de|Docs',
  'menu.connections': 'de|Connections',
  'menu.bookmarks': 'de|Bookmarks',
  'menu.requests': 'de|Requests',
  'menu.account': 'de|Account',
  'menu.password': 'de|Password',
  'menu.devices': 'de|Devices',
  'menu.notifications': 'de|Notifications',
  'menu.email': 'de|Email',
  'menu.sms': 'de|SMS',
  'menu.downloads': 'de|Downloads',
  'menu.documents': 'de|Documents',
  'menu.videos': 'de|Videos',
  'menu.dashboard': 'de|Dashboard',
  'menu.products': 'de|Products',
  'menu.orders': 'de|Orders',
  'menu.customers': 'de|Customers',
  'menu.storefront': 'de|Storefront',
  'menu.filters': 'de|Filters',
  'menu.categories': 'de|Categories',
  'menu.cart': 'de|Cart',
  'menu.checkout': 'de|Checkout',
  'menu.invoice': 'de|Invoice',
  'menu.shipping': 'de|Shipping',
  'menu.discount': 'de|Discount',
  'menu.general': 'de|General',

  'menu.upgrade': 'de|Upgrade',
  'menu.community': 'de|Community',
  'menu.community-list': 'de|Community List',
  'menu.getting-started': 'de|Getting Started',
  'menu.analysis': 'de|Analysis',
  'menu.services': 'de|Services',
  'menu.database': 'de|Database',
  'menu.database-add': 'de|New Database',
  'menu.database-detail': 'de|Database Detail',
  'menu.storage': 'de|Storage',
  'menu.hosting': 'de|Hosting',
  'menu.users': 'de|Users',
  'menu.billing': 'de|Billing',
  'menu.security': 'de|Security',
  'menu.support': 'de|Support',
  'menu.docs': 'de|Docs',
  'menu.docsdetail': 'de|Docs Detail',
  'menu.tickets': 'de|Tickets',
  'menu.tickets-detail': 'de|Teicket Detail',

  'menu.elearning': 'de|Elearning',
  'menu.school': 'de|School',
  'menu.courses': 'de|Courses',
  'menu.explore': 'de|Explore',
  'menu.quiz': 'de|Quiz',
  'menu.result': 'de|Result',
  'menu.paths': 'de|Paths',
  'menu.instructor': 'de|Instructor',
  'menu.misc': 'de|Miscellaneous',
  'menu.player': 'de|Player',
  'menu.material': 'de|Material',
  'menu.syllabus': 'de|Syllabus',
  'menu.views': 'de|View Orders',
  'menu.marketing': 'de|Marketing',
};
export default enMessages;
