import React, { memo, Suspense } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { DEFAULT_PATHS } from 'config.js';
import { userHasPrivilege } from 'routing/helper';
import RouteItem from './RouteItem';

const RouteIdentifier = ({ routes, isActive, fallback = <div className="loading" />, notFoundPath = DEFAULT_PATHS.NOTFOUND }) => (
  <Suspense fallback={fallback}>
    <Switch>
      {routes.map((route, rIndex) => {
        const privilege = userHasPrivilege(isActive, route.path);
        if (isActive && privilege) {
          return <RouteItem key={`r.${rIndex}`} {...route} />;
        }
        if (!isActive) {
          return <RouteItem key={`r.${rIndex}`} {...route} />;
        }
        return null;
      })}
      <Redirect to={notFoundPath} />
    </Switch>
  </Suspense>
);

export default memo(RouteIdentifier);
