import React, { useEffect } from 'react';

const LoadingPersist = ({ isLoading = true } = {}) => {
  useEffect(() => {
    const contentArea = document.querySelector('#contentArea');
    const htmlTag = document.documentElement;
    if (!isLoading) {
      setTimeout(() => {
        if (!contentArea) {
          htmlTag.setAttribute('data-show', 'true');
        } else {
          contentArea.style = 'opacity:1';
        }
        document.body.classList.remove('spinner');
      }, 30);
    } else {
      if (!contentArea) {
        htmlTag.setAttribute('data-show', 'false');
      } else {
        contentArea.style = 'opacity:0';
      }
      document.body.classList.add('spinner');
    }
  }, [isLoading]);

  return <></>;
};

export default LoadingPersist;
