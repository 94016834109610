import { DEFAULT_PATHS } from 'config.js';

import NotFound from 'views/default/NotFound';
import Login from 'views/default/Login';
import ForgotPassword from 'views/default/ForgotPassword';
import Register from 'views/default/Register';
// import InvoiceTemplate from 'views/orders/components/InvoiceTemplate2';
import ResetPassword from 'views/default/ResetPassword';
import Unauthorized from 'views/default/Unauthorized';
import EmailVerificationWaiting from 'views/default/EmailVerificationWaiting';
import InvalidAccess from 'views/default/InvalidAccess';
import App from 'App.js';
import Home from 'views/default/Home';
import SELLERONBOARDING from 'views/seller/SellerOnboarding';

/*
{ path: "/path", exact: true, component: ViewHome },
// or
{ path: "/path", component: ViewHome},
// or
{ path: "/path", exact: true, redirect: true, to: "/redirectPath" },
*/
const defaultRoutes = [
  { path: DEFAULT_PATHS.NOTFOUND, exact: true, component: NotFound },
  { path: DEFAULT_PATHS.LOGIN, exact: true, component: Login },
  { path: DEFAULT_PATHS.REGISTER, exact: true, component: Register },

  { path: DEFAULT_PATHS.FORGOT_PASSWORD, exact: true, component: ForgotPassword },
  { path: DEFAULT_PATHS.RESET_PASSWORD, exact: true, component: ResetPassword },
  { path: DEFAULT_PATHS.UNAUTHORIZED, exact: true, component: Unauthorized },
  { path: DEFAULT_PATHS.EMAIL_VERIFICATION_WAITING, exact: true, component: EmailVerificationWaiting },
  { path: DEFAULT_PATHS.INVALID_ACCESS, exact: true, component: InvalidAccess },

  { path: DEFAULT_PATHS.SELLER_ONBOARDING, exact: true, component: SELLERONBOARDING },
  { path: DEFAULT_PATHS.APP, component: App },
  { path: '/', exact: true, component: Home },
];

export default defaultRoutes;
