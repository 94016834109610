/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Button, Form, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import LayoutFullpage from 'layout/SellerOnboardingFullPage';
// import LayoutFullpage from 'layout/LayoutFullpage';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import HtmlHead from 'components/html-head/HtmlHead';
import { SELLER_ONBOARDING } from 'constants/index';
import { auth, dhCreateUserWithEmailAndPassword, dhUpdateProfile } from 'helpers/Firebase';
// import { setCurrentUser } from 'auth/authSlice';
// import { USER_ROLE } from 'constants/index';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
// import { sendEmailVerification } from 'firebase/auth';
import { DEFAULT_PATHS } from 'config';
import { setLogin } from 'auth/authSlice';
import { sendEmailVerificationSellerHub } from './actions';
import OnboardingListNav from './OnboardingListNav';
import '../seller/style.scss';

const Register = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isRegistrationProcessing, setRegistrationProcessing] = useState(false);
  const title = 'Register';
  const description = 'Register Page';

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field'),
    email: Yup.string().email().max(255, 'Email should not exceed 255 chars').required('Email is required'),
    phone: Yup.string().min(7).max(12).required('Phone number is required').matches(SELLER_ONBOARDING.REG_EXP_UAE_PHONE_NO, 'Only UAE Phone number allowed'),
    password: Yup.string().min(6, 'Must have at least 6 characters').max(20, 'Maximum upto 20 characters').required('Password is required'),
    terms: Yup.bool().required().oneOf([true], 'Terms must be accepted'),
  });
  const initialValues = { name: '', email: '', password: '', terms: false };
  const onSubmit = (values) => {
    try {
      setRegistrationProcessing(true);
      dhCreateUserWithEmailAndPassword(auth, values.email, values.password)
        .then(async (userCredential) => {
          const { user } = userCredential;
          await dhUpdateProfile(user, {
            displayName: values.name,
            phoneNumber: values.phone,
          });
          window.localStorage.registeredPhoneNumber = values.phone;
          // sendEmailVerification(user);

          const token = await user?.getIdToken();

          sendEmailVerificationSellerHub({ token, name: values.name, phone: values.phone });
          dispatch(
            setLogin({
              token: token,
              name: values.name,
              phone: values.phone,
            })
          );
          // dispatch(
          //   setCurrentUser({
          //     id: user.uid,
          //     name: user.name,
          //     thumb: '/img/profile/profile-9.webp',
          //     role: USER_ROLE.Admin,
          //     email: values.email,
          //   })
          // );
          history.push(DEFAULT_PATHS.EMAIL_VERIFICATION_WAITING);
        })
        .catch((error) => {
          // const errorCode = error.code;
          // const errorMessage = error.message;
          console.error(error);
          setRegistrationProcessing(false);
          toast.error(
            <div className="registerError">
              <p>
                <b>Registration error.</b>
              </p>
              <p>The email address {values.email} is already in use by another account.</p>
              <p>Please use forgot username/password to retrieve account.</p>
            </div>,
            {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        });
    } catch (error) {
      // const errorCode = error.code;
      // const errorMessage = error.message;
      console.log(error);
      toast.error(
        <div className="registerError">
          <p>
            <b>Something went wrong!</b>
          </p>
          <p>Please check your internet connection and try again</p>
        </div>,
        {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setRegistrationProcessing(false);
    }
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  // const leftSide = (
  //   <div className="min-h-100 d-flex align-items-center">
  //     <div className="w-100 w-lg-75 w-xxl-50">
  //       <div>&nbsp;</div>
  //     </div>
  //   </div>
  // );

  const rightSide = (
    <div className="sw-lg-70 min-h-100  py-5 full-page-content-right-border">
      <div className="sw-lg-50 px-5">
        {/* <div className="mb-7">
          <img src="/img/profile/logo.png" alt="logo" width="400" />
        </div> */}
        <div className="mb-5">
          <h2 className="cta-1 mb-0 " style={{ color: 'black' }}>
            Welcome,
          </h2>
          {/* <h2 className="cta-1 " style={{ color: 'black' }}>
            let's get the ball rolling!
          </h2> */}
        </div>
        <div className="mb-5">
          <p className="h6" style={{ color: 'black' }}>
            Please use the form to register.
          </p>
          <p className="h6" style={{ color: 'black' }}>
            If you are a member, please{' '}
            <NavLink to="/login" style={{ color: '#c4161c' }}>
              login
            </NavLink>
            .
          </p>
        </div>
        <div>
          <form id="registerForm" className="tooltip-end-bottom" onSubmit={handleSubmit}>
            <div className="mb-3 filled form-group tooltip-end-top">
              <CsLineIcons icon="user" />
              <Form.Control type="text" name="name" placeholder="Name" value={values.name} onChange={handleChange} />
              {errors.name && touched.name && (
                <div className="d-block invalid-tooltip" id="errorfield_name">
                  {errors.name}
                </div>
              )}
            </div>
            <div className="mb-3 filled form-group tooltip-end-top">
              <CsLineIcons icon="phone" />
              <Form.Control type="text" name="phone" placeholder="Phone" value={values.phone} onChange={handleChange} />
              {errors.phone && touched.phone && (
                <div className="d-block invalid-tooltip" id="errorfield_phone">
                  {errors.phone}
                </div>
              )}
            </div>
            <div className="mb-3 filled form-group tooltip-end-top">
              <CsLineIcons icon="email" />
              <Form.Control type="text" name="email" placeholder="Email" value={values.email} onChange={handleChange} />
              {errors.email && touched.email && (
                <div className="d-block invalid-tooltip" id="errorfield_email">
                  {errors.email}
                </div>
              )}
            </div>
            <div className="mb-3 filled form-group tooltip-end-top">
              <CsLineIcons icon="lock-off" />
              <Form.Control type="password" name="password" onChange={handleChange} value={values.password} placeholder="Password" />
              {errors.password && touched.password && (
                <div className="d-block invalid-tooltip" id="errorfield_password">
                  {errors.password}
                </div>
              )}
            </div>
            <div className="mb-3 position-relative form-group">
              <div className="form-check">
                <input type="checkbox" className="form-check-input" name="terms" onChange={handleChange} value={values.terms} />
                <label className="form-check-label">
                  I have read and accept the{' '}
                  <NavLink to="/" target="_blank" style={{ color: '#c4161c' }}>
                    terms and conditions.
                  </NavLink>
                </label>
                {errors.terms && touched.terms && (
                  <div className="d-block invalid-tooltip" id="errorfield_terms">
                    {errors.terms}
                  </div>
                )}
              </div>
            </div>
            <Button size="lg" type="submit" className="registerButton" disabled={isRegistrationProcessing} style={{ backgroundColor: '#c4161c' }}>
              {isRegistrationProcessing ? <Spinner animation="border" size="sm" /> : null}Signup
            </Button>
          </form>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="onboard">
        <HtmlHead title={title} description={description} />
        <div className="containSection ">
          <OnboardingListNav />

          <div className="loginSection">
            <div className="m-lg-7 ">{rightSide}</div>
          </div>
        </div>
        <LayoutFullpage left={<></>} right={<></>} />
      </div>
    </>
  );
};

export default Register;
