import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Rating from 'react-rating';
import { Progress } from 'rsuite';

export default ({ ratings = {} }) => {
  return (
    <Card>
      <Card.Body className=" p-2 pt-2 pb-2 align-items-center">
        <Row className="g-0">
          <Col>
            {/* <div className="sh-5 d-flex align-items-center lh-1-25 ps-3 cta-2">Seller Ratings</div> */}
            <div className="cta-3 text-primary ps-3">
              <span className="cta-2 pe-2">{ratings.ratingAverage}</span>
              <Rating
                initialRating={ratings.ratingAverage}
                readonly
                emptySymbol={<i className="cs-star text-muted" />}
                fullSymbol={<i className="cs-star-full text-warning" />}
              />
            </div>
            <div className="text-muted text-small d-flex ms-3 me-3 border-bottom pb-2 ">Based on {ratings?.totalReviews || 0} Customer Ratings</div>
            <div className="ps-3 pe-3 pt-3">
              {[5, 4, 3, 2, 1].map((r) => {
                const star = ratings?.[`star${r}`];
                return (
                  <div key={`rating-${r}`} className="d-flex flex-row align-items-center justify-content-between mb-2">
                    <div className="d-flex flex-column">
                      <div className="text-nowrap text-tertiary">
                        {r} <i className="cs-star-full text-small" />
                      </div>
                    </div>
                    <Progress.Line percent={star?.percent} strokeWidth={4} status="active" showInfo={false} />
                    <div className="d-flex text-small">({star?.count})</div>
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
