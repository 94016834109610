import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO } from 'config.js';

const initialState = {
  isLogin: false,
  currentUser: {},
  isLoginLoading: true,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLogin(state, action) {
      state.isLogin = true;
      state.isLoginLoading = false;
      state.token = action.payload.token;
      if (action.email) {
        state.email = action.email;
      }
      if (action.phone) {
        state.phone = action.phone;
      }
    },
    setCurrentUser(state, action) {
      state.currentUser = action.payload;
      // state.isLogin = true;
      // state.isLoginLoading = false;
      state.emailVerificationSuccessful = !!state.currentUser?.emailVerified;
    },
    setLogout(state) {
      state.currentUser = {};
      state.isLogin = false;
      state.isLoginLoading = false;
      state.token = '';
    },
    setLoginLoadingHide(state) {
      state.isLoginLoading = false;
    },
    setUserPrivilege(state, action) {
      state.currentUser.privilege = action.payload;
    },
  },
});

export const { setCurrentUser, setLogout, setLogin, setLoginLoadingHide, setUserPrivilege } = authSlice.actions;
const authReducer = authSlice.reducer;

export default authReducer;
