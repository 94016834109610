import { createSlice } from '@reduxjs/toolkit';
import { format } from 'date-fns';

// import { DEFAULT_USER, IS_DEMO } from 'config.js';

const initialState = {
  list: [],
  isLoading: true,
};

const statementSlice = createSlice({
  name: 'statements',
  initialState,
  reducers: {
    setStatementList(state, actions) {
      state.isLoading = false;
      state.list = actions.payload?.statementList?.list?.map((record) => {
        return {
          ...record,
          statement_period_from_date: format(new Date(Number(record.statement_period_from_date)), 'dd/MM/yyyy'),
          statement_period_end_date: format(new Date(Number(record.statement_period_end_date)), 'dd/MM/yyyy'),
        };
      });
      state.count = actions.payload.statementList?.count || 0;
    },
  },
});

export const { setStatementList } = statementSlice.actions;
const statementReducer = statementSlice.reducer;

export default statementReducer;
