import React from 'react';

// import { Col, Container, Row } from 'reactstrap';
import HtmlHead from 'components/html-head/HtmlHead';

import Listing from './components/Listing/Listing.component';

const QaOrders = () => {
  const title = 'Messages';
  const description = 'Customer Messages';
  return (
    <>
    <HtmlHead title={title} description={description} />
      <div className="page-content">
        {/* <Container fluid>
          <Row>
            <Col xl={6}>
              <DashBreadcrumb title="QA Orders" breadcrumbItem="Manage QA Orders" />
            </Col>
          </Row>
        </Container> */}
        <Listing />
      </div>
    </>
  );
};
export default QaOrders;
