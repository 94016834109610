import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { Form, Button } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { getOrderReport } from './actions/reportActions';

import './Report.style.scss';

// import { useSelector } from 'react-redux';

const ReportViews = () => {
  const [stsReportLoad, setReportLoad] = useState(false);
  const dispatch = useDispatch();
  const { reportStatus } = useSelector((state) => state.reportStatus);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [orderStatus, setOrderStatus] = useState(null);
  const onDate = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    console.log(start, end);
  };
  const handleStatusChange = (event) => {
    setOrderStatus(event?.target?.value);
  };
  const handleReportUpload = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const params = {
      startDate,
      endDate,
      reportType: orderStatus,
    };
    setReportLoad(true);
    await getOrderReport({ params, dispatch });
    setReportLoad(false);
    // const form = event.currentTarget;
    // const formData = new FormData();
    // console.log('Start Date:', startDate);
    // console.log('End Date:', endDate);
    // formData.append('report_type', event?.target?.report_type?.value);
    // formData.append('date', event?.target?.date?.value);
    // formData.append('supplier_id', event?.target?.supplier_id?.value);

    // console.log(event?.currentTarget?.id);
    // console.log(event?.target?.report_type?.value);
    // console.log(event?.target?.date?.value);
    // console.log(event?.event?.target?.supplier_id?.value);
    // if (!form.checkValidity() === false) {
    //   setReportLoad({ stsReportLoad: true });
    //   getreportStatusCounts({
    //     formData,

    //     dispatch,
    //   });
    // }
    // setReportLoad(event.currentTarget.id);

    // console.log(stsReportLoad);
  };
  useEffect(() => {}, [reportStatus.path]);
  console.log(reportStatus, 'report status');
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between float_left">
              <h4 className="page-title mb-2 font-size-18">Reports</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <Form>
            <div className="tab-content twitter-bs-wizard-tab-content">
              <div className="row mb-5">
                <div className="col-lg-2">
                  <input type="radio" name="report_type" checked="checked" />
                  <span className="space">Order Wise </span>
                </div>
                {/*  <div className="col-lg-2"><input type="radio" name="report_type" checked="checked" /><span className="space">Supplier Wise</span></div> */}
              </div>
              <div className="row">
                {/* <div className="col-lg-3 box_size supplier_div">
                                    <select className="form-control form-select">
                                        <option value="">All Suppliers</option>
                                    </select>

                                </div> */}
                <div className="col-lg-3 box_size status_div">
                  <select onClick={handleStatusChange} name="type" className="form-control form-select" Placeholder="Please select status">
                    <option value="">Select any status</option>
                    <option value="0">Pending Orders</option>
                    <option value="1">Accepted Orders</option>
                    <option value="2">Pending for collection Orders</option>
                    <option value="3">Picked</option>
                    <option value="4">Delivered</option>
                    <option value="9">Invoice Pending</option>
                    {/* <option value="8">Completed</option>
                    <option value="5">Rejected by customer</option> */}
                    <option value="6">Rejected by User</option>
                  </select>
                </div>
                <div className="col-lg-3 box_size date_div">
                  <DatePicker
                    className="form-control"
                    selected={startDate}
                    onChange={onDate}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    name="date"
                  />
                  <input name="supplier_id" type="hidden" size="sm" />
                </div>
                <div className="col-lg-2 box_size">
                  <Button
                    type="submit"
                    disabled={stsReportLoad}
                    style={{ paddingLeft: '5px', paddingRight: '5px' }}
                    variant="success"
                    data-id="invoiceBtn"
                    onClick={handleReportUpload}
                  >
                    Generate Report
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ReportViews;
