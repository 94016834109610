import { createSlice } from '@reduxjs/toolkit';
import { COUNTRIES } from 'constants/index';

const findOrDefault = (key) => {
  return COUNTRIES.find((x) => x.iso2_code === key) || COUNTRIES[0];
};

const initialState = {
  COUNTRIES,
  currentCountry: findOrDefault(COUNTRIES[0]),
};

const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    changeCountry(state, action) {
      state.currentCountry = findOrDefault(action.payload);
    },
  },
});
export const { changeCountry } = countrySlice.actions;
const countryReducer = countrySlice.reducer;

export default countryReducer;
