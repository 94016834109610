import React, { useState, useCallback, useRef, useEffect } from 'react';
import { FormLabel } from 'react-bootstrap';
import { GoogleMap, useJsApiLoader, Marker, StandaloneSearchBox } from '@react-google-maps/api';
import Geocode from 'react-geocode';
import gmapStyle from './gmapStyle.json';

const GOOGLE_MAP_API_KEY = 'AIzaSyDUZ_Rafbp7ewAfNIxcehFnyht7y9r9ak4';

const country = 'ae';

const WarehouseLocation = ({ index = 0, update = () => {}, wareLocLatLng }) => {
  const center = {
    ae: {
      lat: 25.1675739,
      lng: 55.2614555,
    },
    qa: {
      lat: 25.2842534,
      lng: 51.3719073,
    },
    om: {
      lat: 23.5880307,
      lng: 58.3828717,
    },
    kw: {
      lat: 29.3760606,
      lng: 47.9468472,
    },
    bh: {
      lat: 26.2213902,
      lng: 50.5848642,
    },
  };
  const contryBounds = {
    ae: {
      north: 26.427929393569457,
      south: 22.427935149720497,
      west: 49.88720042141598,
      east: 57.8381892803542,
    },
    qa: {
      north: 26.2171,
      south: 24.471118,
      west: 50.7211001,
      east: 51.7144001,
    },
    om: {
      north: 28.808004285620157,
      south: 12.427888171440916,
      west: 38.38409066937567,
      east: 70.18813730023504,
    },
    kw: {
      north: 33.15088596365788,
      south: 25.497326905942987,
      west: 39.665945329232116,
      east: 55.5679686446618,
    },
    bh: {
      north: 26.653167758085463,
      south: 25.667091017781722,
      west: 49.63994697023152,
      east: 51.627694184966074,
    },
  };
  const inputStyle = {
    boxSizing: `border-box`,
    border: `1px solid transparent`,
    width: `240px`,
    height: `32px`,
    padding: `0 12px`,
    borderRadius: `3px`,
    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
    fontSize: `14px`,
    outline: `none`,
    textOverflow: `ellipses`,
    position: 'absolute',
    top: '10px',
    right: '10px',
  };
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries: ['drawing', 'places', 'marker'],
  });
  const onLoad = useCallback(function callback(map) {
    console.log(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    console.log(map);
  }, []);
  const [selectedLatLng, setSelectedLatLng] = useState(wareLocLatLng || center?.[country]);
  const [isScrollToMarker, setIsScrollToMarker] = useState(false);
  const [, setSelectedPlusCode] = useState(null);
  const [, setSelectedAddress] = useState(null);
  const [, setSelectedCountry] = useState(null);
  const [, setSelectedCountryCode] = useState('');
  const [, setSelectedCity] = useState(null);
  const [, setSelectedRegion] = useState(null);
  // const [selectedPlusCode, setSelectedPlusCode] = useState(null);
  // const [selectedAddress, setSelectedAddress] = useState(null);
  // const [selectedAddressErrorMsg, setSelectedAddressErrorMsg] = useState(null);
  // const [selectedCountry, setSelectedCountry] = useState(null);
  // const [selectedCountryCode, setSelectedCountryCode] = useState('');
  // const [selectedCity, setSelectedCity] = useState(null);
  // const [selectedRegion, setSelectedRegion] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [loadingLocation, setLoadingLocation] = useState(false);
  const searchBox = useRef(null);

  const getFormattedAddressAndCode = (lat, lng) => {
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        setSelectedPlusCode(response?.plus_code);
        setSelectedAddress(response?.results?.[0]?.formatted_address);
        setSearchValue(response?.results?.[0]?.formatted_address);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const getCountryCityRegionFromGeoCode = (lat, lng) => {
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        response?.results?.forEach((result) => {
          if (result?.types[0] === 'country') {
            setSelectedCountry(result?.address_components[0]?.long_name);
            setSelectedCountryCode(result?.address_components[0]?.short_name);
          }
          if (result?.types[0] === 'locality') {
            setSelectedRegion(result?.address_components[0]?.long_name);
          }
          if (result?.types[0] === 'administrative_area_level_1') {
            setSelectedCity(result?.address_components[0]?.long_name);
          }
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const onPlacesChanged = () => {
    setIsScrollToMarker(true);
    setLoadingLocation(true);
    searchBox?.current?.state?.searchBox?.getPlaces()?.forEach((item) => {
      if (item?.plus_code) {
        setSelectedPlusCode(item?.plus_code);
        setSelectedAddress(item?.formatted_address);
        setSearchValue(item?.formatted_address);
      } else {
        getFormattedAddressAndCode(item?.geometry?.location?.lat(), item?.geometry?.location?.lng());
      }
      getCountryCityRegionFromGeoCode(item?.geometry?.location?.lat(), item?.geometry?.location?.lng());
      setSelectedLatLng({
        lat: item?.geometry?.location?.lat(),
        lng: item?.geometry?.location?.lng(),
      });
      console.log('item', item);
      update({ lat: item?.geometry?.location?.lat(), lng: item?.geometry?.location?.lng(), index, name: item.name });
      setSearchValue(item.name);
    });
  };
  const onMapClick = (...args) => {
    setIsScrollToMarker(false);
    setSelectedLatLng({
      lat: args?.[0]?.latLng?.lat(),
      lng: args?.[0]?.latLng?.lng(),
    });
    update({ lat: args?.[0]?.latLng?.lat(), lng: args?.[0]?.latLng?.lng(), index });

    getFormattedAddressAndCode(args?.[0]?.latLng?.lat(), args?.[0]?.latLng?.lng());
    getCountryCityRegionFromGeoCode(args?.[0]?.latLng?.lat(), args?.[0]?.latLng?.lng());
  };

  useEffect(() => {
    if (wareLocLatLng && wareLocLatLng.lat) setSelectedLatLng(wareLocLatLng);
  }, [wareLocLatLng]);
  // const centerMarker = center?.[country];
  console.log('selectedLatLng', selectedLatLng);
  return (
    <>
      <FormLabel>Pin warehouse location</FormLabel>
      {isLoaded && (
        <div style={{ height: '300px' }}>
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: '100%' }}
            zoom={13}
            onLoad={onLoad}
            onUnmount={onUnmount}
            styles={gmapStyle}
            options={{
              styles: gmapStyle,
              draggable: true,
              streetViewControl: false,
              fullscreenControl: false,
              mapTypeControl: false,
              restriction: {
                latLngBounds: contryBounds[country],
                strictBounds: true,
              },
            }}
            center={isScrollToMarker ? selectedLatLng : selectedLatLng} // TODO
            onClick={onMapClick}
          >
            <>
              <Marker
                position={selectedLatLng}
                // icon={{
                //   url: 'https://cdn.danubehome.com/media/email/dhMapMarker.svg',
                //   anchor: new google.maps.Point(17, 46),
                //   scaledSize: new google.maps.Size(37, 37),
                // }}
                animation="DROP"
              />

              <StandaloneSearchBox onPlacesChanged={onPlacesChanged} ref={searchBox} bounds={contryBounds[country]}>
                <>
                  <input
                    type="text"
                    placeholder="Search Location"
                    style={inputStyle}
                    value={searchValue}
                    onChange={(event) => {
                      if (!loadingLocation) {
                        setSearchValue(event?.target?.value);
                      } else {
                        setLoadingLocation(false);
                      }
                    }}
                  />
                </>
              </StandaloneSearchBox>
            </>
          </GoogleMap>
        </div>
      )}
    </>
  );
};

export default WarehouseLocation;
