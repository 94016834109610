import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'reduxjs-toolkit-persist/es/constants';
import { persistReducer } from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import persistStore from 'reduxjs-toolkit-persist/es/persistStore';
import scrollspyReducer from 'components/scrollspy/scrollspySlice';
import langReducer from 'lang/langSlice';
import menuReducer from 'layout/nav/main-menu/menuSlice';
import settingsReducer from 'settings/settingsSlice';
import notificationReducer from 'layout/nav/notifications/notificationSlice';
import authReducer from 'auth/authSlice';
import orderReducer from 'orderSlice/index';
import orderDetailReducer from 'orderSlice/OrderDetail';
import orderStatusReducer from 'orderSlice/OrderStatus';
import orderCountsReducer from 'orderSlice/OrderCounts';
import orderAWBReducer from 'orderSlice/OrderAWB';
import layoutReducer from 'layout/layoutSlice';
import invoiceStatusReducer from 'orderSlice/invoiceStatus';
import downloadAWBReducer from 'orderSlice/OrderAWBdownload';
import reportStatusReducer from 'reportSlice/reportStatus';
import statementStatusReducer from 'statementSlice/statementStatus';
import returnReducer from 'returnSlice/index';
import returnDetailReducer from 'returnSlice/ReturnDetail';
import returnStatusReducer from 'returnSlice/ReturnStatus';
import dashboardStatusReducer from 'dashboardSlice/dasboardStatus';
import countryReducer from 'country/countrySlice';
import { REDUX_PERSIST_KEY } from 'config.js';
import statementReducer from 'statementSlice';
import messageReducer from 'messageSlice';

const persistConfig = {
  key: REDUX_PERSIST_KEY,
  storage,
  whitelist: ['menu', 'lang', 'auth', 'country'],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    settings: settingsReducer,
    layout: layoutReducer,
    lang: langReducer,
    auth: authReducer,
    order: orderReducer,
    orderDetail: orderDetailReducer,
    orderStatus: orderStatusReducer,
    orderCounts: orderCountsReducer,
    orderAWB: orderAWBReducer,
    returns: returnReducer,
    returnDetail: returnDetailReducer,
    returnStatus: returnStatusReducer,
    menu: menuReducer,
    notification: notificationReducer,
    scrollspy: scrollspyReducer,
    invoiceStatus: invoiceStatusReducer,
    reportStatus: reportStatusReducer,
    statementStatus: statementStatusReducer,
    dashboardStatus: dashboardStatusReducer,
    orderAWBdownload: downloadAWBReducer,
    country: countryReducer,
    statements: statementReducer,
    messages: messageReducer,
  })
);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
const persistedStore = persistStore(store);
export { store, persistedStore };
