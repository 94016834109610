import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO } from 'config.js';

const initialState = {
    reportStatus: [],
    isreportLoading: true,
};

const reportStatusSlice = createSlice({
    name: 'reportStatus',
    initialState,
    reducers: {
        setReportStatus(state, actions) {
            state.isInvoiceLoading = false;
            state.reportStatus = actions.payload;
        },
    },
});

export const { setReportStatus } = reportStatusSlice.actions;

const reportStatusReducer = reportStatusSlice.reducer;
export default reportStatusReducer;
