import HtmlHead from 'components/html-head/HtmlHead';

import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Dropdown, Form, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { format } from 'date-fns';
import classNames from 'classnames';
import SupportTicketsDetail from 'views/support/SupportTicketsDetail';
import { Progress, Table } from 'rsuite';
// import Select from 'react-select';
import { NavLink, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { hideSideBar, showSideBar } from 'settings/settingsSlice';
import OrdersViewDetail from 'views/orders/OrdersViewDetail';
import { displayErrorToast, displayInfoToast, displaySuccessToast } from 'helpers/toaster';
import { useIntl } from 'react-intl';
import copy from 'clipboard-copy';
import { useUrlSearchParams } from 'use-url-search-params';
import { SORTING_ORDER } from 'constants/index';
import { acknowledgePaymentReceipt, fetchAllStatementsOrderTrasnaction } from './actions/statementActions';
import SOAModal from './SOAModal';

const { Column, HeaderCell, Cell } = Table;

// const defaultData = mockUsers(100);

export const TRANSACTION_TYPES = [
  { value: 'all', label: 'All transactions' },
  { value: 'orders', label: 'Orders' },
  { value: 'refund', label: 'Refund' },
  { value: '3pl_ship', label: 'Emirates Post Charges' },
  { value: '3pl_ship_reverse', label: 'Emirates Post Charges - Reverse' },
  { value: 'service_fee', label: 'Service Fee' },
  { value: 'cancellation_charge', label: 'Cancellation Charges' },
  { value: 'other', label: 'Other Charges' },
];

const Transactions = () => {
  const title = 'Statement';

  const { formatMessage: f } = useIntl();
  const params = useParams();

  const [sortingValue, setSortingValue] = useUrlSearchParams({ sort: '_date', order: SORTING_ORDER.ASC }, null, true);
  const [paginationValue] = useUrlSearchParams({ page: 0 }, null, true);

  const [sortingName, setSortingName] = useState(f({ id: 'sort.date_asc' }));
  const [list, setTransactionList] = useState([]);
  const [, setTransactionCount] = useState({});

  const [showOrderModal, setShowOrderModal] = useState();
  const [showInvoiceModal, setShowInvoiceModal] = useState();
  const [showInvoiceDocUrl, setShowInvoiceDocUrlModal] = useState();
  const [showAcknowledgementModal, setShowAcknowledgementModal] = useState(false);

  const [showPoDModal, setShowPoDModal] = useState();
  const [showPoDDocUrl, setShowPoDDocUrl] = useState();

  const [showOrderLevelVerification] = useState('');
  const [verifyOrderId] = useState('');
  const [waitingResponse, setWaitingResponse] = useState(false);
  const [showSOAModal, setSOAModal] = useState(false);

  const CustomCell = ({ rowData, ...rest }) => {
    switch (rest.dataKey) {
      case 'view':
        return (
          <Cell rowData={rowData} {...rest}>
            <NavLink to={`/statements/transactions/detail/order-list/${rowData.statement_period_id}`} className="">
              View
            </NavLink>
          </Cell>
        );
      case 'reference_no':
        return (
          <Cell rowData={rowData} {...rest}>
            <If condition={rowData.refunds && !rowData.isSummary && !rowData?.ads_platform}>
              <span className="border light-badge-warning position-absolute ps- text-nowrap text-small" style={{ top: 0, left: -2 }}>
                RETURN
              </span>
            </If>
            <If condition={rowData?.ads_platform}>
              <span className="border light-badge-warning position-absolute ps- text-nowrap text-small" style={{ top: 0, left: -2 }}>
                MARKETING EXPENSE
              </span>
            </If>
            <div className="hover-trigger">
              <span
                className="cursor-pointer"
                onClick={() => {
                  setShowOrderModal(rowData.reference_no);
                }}
              >
                {rowData.reference_no}
              </span>
              &nbsp;
              <NavLink to={`/orders/detail/${rowData.reference_no}`} className="visible-hover" target="_blank">
                <CsLineIcons width={12} height={12} icon="link" className="text-small" />
              </NavLink>
              &nbsp;
              <span
                className="visible-hover cursor-pointer"
                onClick={() => {
                  copy(rowData.reference_no);
                  displayInfoToast(`Copied: ${rowData.reference_no}`, 2000);
                }}
              >
                <CsLineIcons width={12} height={12} icon="duplicate" className="text-small" />
              </span>
            </div>
            <div className="text-muted text-small">{rowData.order_creation_date}</div>
          </Cell>
        );
      case 'invoice_no': {
        const deliveryInvoiceUrl = rowData.delivery_invoice_doc || `seller.danubehome.com/uploads/tax_invoice/${rowData.delivery_invoice_no}.pdf`;
        const returns = (rowData?.return_invoice_no && rowData?.return_invoice_no?.split(',')) || [];
        const returnInvoiceNumbers = returns.length ? returns : [];
        return (
          <Cell rowData={rowData} {...rest}>
            <div className="hover-trigger">
              <span
                className="cursor-pointer"
                onClick={() => {
                  setShowInvoiceModal(rowData.invoice_no);
                  setShowInvoiceDocUrlModal(rowData.payment_invoice_doc);
                }}
              >
                {rowData.invoice_no}
              </span>
              &nbsp;
              <NavLink to={{ pathname: `https://${rowData.payment_invoice_doc}` }} className="visible-hover" target="_blank">
                <CsLineIcons width={12} height={12} icon="link" className="text-small" />
              </NavLink>
              &nbsp;
              <span
                className="visible-hover cursor-pointer"
                onClick={() => {
                  copy(rowData.invoice_no);
                  displayInfoToast(`Copied: ${rowData.invoice_no}`, 2000);
                }}
              >
                <CsLineIcons width={12} height={12} icon="duplicate" className="text-small" />
              </span>
            </div>
            <div className="text-muted text-small">{rowData.invoice_date}</div>
            <If condition={rowData.delivery_invoice_no}>
              <div className="hover-trigger">
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    setShowInvoiceModal(rowData.delivery_invoice_no);
                    setShowInvoiceDocUrlModal(deliveryInvoiceUrl);
                  }}
                >
                  {rowData.delivery_invoice_no}
                </span>
                &nbsp;
                <NavLink to={{ pathname: `https://${deliveryInvoiceUrl}` }} className="visible-hover" target="_blank">
                  <CsLineIcons width={12} height={12} icon="link" className="text-small" />
                </NavLink>
                &nbsp;
                <span
                  className="visible-hover cursor-pointer"
                  onClick={() => {
                    copy(rowData.delivery_invoice_no);
                    displayInfoToast(`Copied: ${rowData.delivery_invoice_no}`, 2000);
                  }}
                >
                  <CsLineIcons width={12} height={12} icon="duplicate" className="text-small" />
                </span>
              </div>
              <div className="text-muted text-small">{rowData.delivery_invoice_date}</div>
            </If>
            {returnInvoiceNumbers.map((item, index) => {
              const returnInvoiceUrl = `seller.danubehome.com/uploads/tax_invoice/${item}.pdf`;
              return item?.length ? (
                <div className="hover-trigger" key={index}>
                  <span>{item}</span>
                  &nbsp;
                  <NavLink to={{ pathname: `https://${returnInvoiceUrl}` }} className="visible-hover" target="_blank">
                    <CsLineIcons width={12} height={12} icon="link" className="text-small" />
                  </NavLink>
                  &nbsp;
                  <span
                    className="visible-hover cursor-pointer"
                    onClick={() => {
                      copy(item);
                      displayInfoToast(`Copied: ${item}`, 2000);
                    }}
                  >
                    <CsLineIcons width={12} height={12} icon="duplicate" className="text-small" />
                  </span>
                </div>
              ) : null;
            })}
          </Cell>
        ); }
      case 'credit_note':
        return (
          <Cell rowData={rowData} {...rest}>
            <div className="hover-trigger">
              <span
                className="cursor-pointer"
                onClick={() => {
                  setShowInvoiceModal(rowData.credit_note_number);
                  setShowInvoiceDocUrlModal(rowData.credit_note_url);
                }}
              >
                {rowData.credit_note_number}
              </span>
              &nbsp;
              <NavLink to={{ pathname: `https://${rowData.credit_note_url}` }} className="visible-hover" target="_blank">
                <CsLineIcons width={12} height={12} icon="link" className="text-small" />
              </NavLink>
              &nbsp;
              <span
                className="visible-hover cursor-pointer"
                onClick={() => {
                  copy(rowData.credit_note_number);
                  displayInfoToast(`Copied: ${rowData.credit_note_number}`, 2000);
                }}
              >
                <CsLineIcons width={12} height={12} icon="duplicate" className="text-small" />
              </span>
            </div>
            <div className="text-muted text-small">{rowData.credit_note_date}</div>
          </Cell>
        );
      case 'delivery_completed_at': {
        if (rowData.isSummary) return null;
        let docUrl = '';
        let shipTitle = '';
        if (rowData.awb_no) {
          docUrl = `https://www.emiratespost.ae/all-services/track-a-package/step-two?q=${rowData.awb_no}`;
          shipTitle = rowData.awb_no;
        } else {
          docUrl = rowData.delivery_proof;
          shipTitle = 'Self Ship Completed';
        }

        return (
          <Cell rowData={rowData} {...rest}>
            <div className="hover-trigger">
              <span
                className="cursor-pointer"
                onClick={() => {
                  setShowPoDModal(shipTitle);
                  setShowPoDDocUrl(docUrl);
                }}
              >
                {shipTitle}
              </span>
              <If condition={docUrl}>
                &nbsp;
                <NavLink to={{ pathname: docUrl }} className="visible-hover" target="_blank">
                  <CsLineIcons width={12} height={12} icon="link" className="text-small" />
                </NavLink>
              </If>
              &nbsp;
              <If condition={rowData.awb_no}>
                <span
                  className="visible-hover cursor-pointer"
                  onClick={() => {
                    copy(rowData.awb_no);
                    displayInfoToast(`Copied: ${rowData.awb_no}`, 2000);
                  }}
                >
                  <CsLineIcons width={12} height={12} icon="duplicate" className="text-small" />
                </span>
              </If>
            </div>
            <div className="text-muted text-small">{rowData.delivery_completed_at}</div>
          </Cell>
        );
      }
      case 'entity_id': {
        if (rowData.isSummary) return null;
        // let docUrl = '';
        // let shipTitle = '';
        if (rowData.entity_id) {
          // docUrl = `https://betdev.danubehome.com/orders/${rowData.entity_id}/?order_mode=All`;
          // shipTitle = rowData.entity_id;
        }

        return (
          <Cell rowData={rowData} {...rest}>
            <div className="">
              {/* <span
                className="cursor-pointer"
                onClick={() => {
                  setShowPoDModal(shipTitle);
                  setShowPoDDocUrl(docUrl);
                }}
              >
                <CsLineIcons width={12} height={12} icon="link" className="text-small" />
              </span> */}
            </div>
            {/* <div className="text-muted text-small">{rowData.po_number}</div> */}
          </Cell>
        );
      }
      case 'po_number': {
        if (rowData.isSummary) return null;
        let docUrl = '';
        let shipTitle = '';
        if (rowData.po_number) {
          docUrl = `https://seller.danubehome.com/uploads/lslpo/${rowData.po_number}.pdf?v=1`;
          shipTitle = rowData.po_number;
        }
        const returns = rowData.po_return_number.split(',');

        return (
          <Cell rowData={rowData} {...rest}>
            <div className="hover-trigger">
              <span
                className="cursor-pointer"
                onClick={() => {
                  setShowPoDModal(shipTitle);
                  setShowPoDDocUrl(docUrl);
                }}
              >
                {shipTitle}
              </span>
              <If condition={docUrl}>
                &nbsp;
                <NavLink to={{ pathname: docUrl }} className="visible-hover" target="_blank">
                  <CsLineIcons width={12} height={12} icon="link" className="text-small" />
                </NavLink>
              </If>
              &nbsp;
              <If condition={rowData.po_number}>
                <span
                  className="visible-hover cursor-pointer"
                  onClick={() => {
                    copy(rowData.po_number);
                    displayInfoToast(`Copied: ${rowData.po_number}`, 2000);
                  }}
                >
                  <CsLineIcons width={12} height={12} icon="duplicate" className="text-small" />
                </span>
              </If>
            </div>
            {returns.map((item, index) => {
              return (
                <div className="hover-trigger" key={index}>
                  <span>{item}</span>
                  <span
                    className="visible-hover cursor-pointer"
                    onClick={() => {
                      copy(item);
                      displayInfoToast(`Copied: ${item}`, 2000);
                    }}
                  >
                    <CsLineIcons width={12} height={12} icon="duplicate" className="text-small" />
                  </span>
                </div>
              );
            })}
            {/* <div className="text-muted text-small">{rowData.po_number}</div> */}
          </Cell>
        );
      }
      case 'accounts_team_verified':
      case 'payable_team_verified':
      case 'audit_team_verified': {
        if (rowData.isSummary) return null;
        const verified = Number(rowData[rest.dataKey]);
        return (
          <Cell rowData={rowData} {...rest}>
            <If condition={verified}>
              <span variant="light" size="sm">
                <CsLineIcons icon="check" />
              </span>
            </If>
            <If condition={!verified}>
              {/* <span className="cursor-pointer text-medium text-warning text-decoration-underline">
                Not verified 
              </span> */}
              <If condition={showOrderLevelVerification === rest.dataKey && rowData.reference_no === verifyOrderId}>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              </If>
              <If condition={showOrderLevelVerification !== rest.dataKey || rowData.reference_no !== verifyOrderId}>
                <span data-testid="importNewProductButton" variant="success" className="light-badge-warning p-1 text-nowrap text-medium">
                  <span>Not verified</span>
                </span>
              </If>
            </If>
          </Cell>
        );
      }

      case 'refunds':
      case 'sales':
      case 'commission':
      case 'commission_reversed':
      case 'total_payout':
      case 'shipping_charge': {
        return (
          <Cell rowData={rowData} {...rest}>
            <span className="text-muted">AED</span>
            <b className={classNames('', { 'text-warning': rowData.isSummary })}> {Number(rowData[rest.dataKey]).toFixed(2)}</b>
          </Cell>
        );
      }
      // case 'si': {
      //   return (
      //     <Cell rowData={rowData} {...rest}>
      //       <span>{rowData.si}</span>

      //     </Cell>
      //   );
      // }
      default:
        return (
          <Cell rowData={rowData} {...rest}>
            <a href="https://google.com" target="_blank" rel="noreferrer">
              {rowData[rest.dataKey]}
            </a>
          </Cell>
        );
    }
  };
  const [limitValue] = useUrlSearchParams({ limit: 10 }, null, true);

  const limitByValues = [5, 10, 20, 50];
  const handleSorting = (type, order = SORTING_ORDER.ASC, name) => {
    setSortingValue({ sort: type, order });
    setSortingName(name);
  };
  const sortByValues = [
    { key: 'date', order: SORTING_ORDER.ASC, label: f({ id: 'sort.date_asc' }) },
    { key: 'date', order: SORTING_ORDER.DESC, label: f({ id: 'sort.date_desc' }) },
    { key: 'status', order: SORTING_ORDER.ASC, label: f({ id: 'sort.status_asc' }) },
    { key: 'status', order: SORTING_ORDER.DESC, label: f({ id: 'sort.status_desc' }) },
  ];

  // const [limitValue, setLimitValue] = React.useState(10);
  // const [page, setPage] = React.useState(1);

  // const handleChangeLimit = (dataKey) => {
  //   setPage(1);
  //   setLimit(dataKey);
  // };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(hideSideBar());
    return () => {
      dispatch(showSideBar());
    };
  }, []);
  const [isLoading, setLoading] = useState(true);
  const [transactionSummary, setTransactionSummary] = useState({});
  const getTransactions = async (download = '') => {
    try {
      setLoading(true);
      const data = await fetchAllStatementsOrderTrasnaction({
        params: { statementPeriodId: params.statementId, page: paginationValue.page, limit: Number(limitValue.limit), download },
        dispatch,
      });
      const {
        statementOrderTransactions: { list: newList, count: newCount, summary: newSummary },
      } = data;
      setTransactionList(
        newList?.map((record) => {
          return {
            ...record,
            commission: Number(record.commission || 0).toFixed(2),
            order_creation_date: record.order_creation_date ? format(new Date(Number(record.order_creation_date)), 'dd/MM/yyyy hh:m a') : '',
            invoice_date: record.invoice_date ? format(new Date(Number(record.invoice_date)), 'dd/MM/yyyy') : '',
            delivery_invoice_date: record.delivery_invoice_date ? format(new Date(Number(record.delivery_invoice_date)), 'dd/MM/yyyy') : '',
            credit_note_date: record.credit_note_date ? format(new Date(Number(record.credit_note_date)), 'dd/MM/yyyy') : '',
            delivery_completed_at: record.delivery_completed_at ? format(new Date(Number(record.delivery_completed_at)), 'dd/MM/yyyy hh:m a') : '',
          };
        })
      );
      setTransactionCount(newCount);
      setTransactionSummary(newSummary || {});
      setLoading(false);
    } catch (e) {
      displayErrorToast('Something went wrong, please try after sometime');
      console.error(e);
      setLoading(false);
    }
  };
  useEffect(() => {
    getTransactions();
  }, [paginationValue.page, limitValue.limit, sortingValue.sort]);

  useEffect(() => {
    if (transactionSummary?.excelLink) {
      window.open(`${process.env.SELLER_HUB_SELLER_GRAPHQL_API}${transactionSummary?.excelLink}`);
    }
  }, [transactionSummary?.excelLink]);

  const handleReceiptAcknowledgement = async () => {
    try {
      setWaitingResponse(true);
      const results = await acknowledgePaymentReceipt({
        params: {
          // sellerId: Number(sellerId),
          statementPeriodId: params.statementId,
        },
      });
      setWaitingResponse(false);
      if (results?.acknowledgePaymentReceipt?.success) {
        getTransactions();
        displaySuccessToast(`Payment Receipt acknowledged.`);
      } else {
        displayErrorToast(`Something went wrong while updating ${params.statementId}`);
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  return (
    <>
      <HtmlHead title="Transactions" description="Account Transactions" />
      <Modal className="modal-right x2-large" show={!!showOrderModal} onHide={() => setShowOrderModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">#{showOrderModal}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OrdersViewDetail id={showOrderModal} />
        </Modal.Body>
      </Modal>
      <Modal className="modal-right x2-large" show={!!showInvoiceModal} onHide={() => setShowInvoiceModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">#{showInvoiceModal}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe src={`https://${showInvoiceDocUrl}`} className="w-100 min-vh-100" title="Invoice" />
        </Modal.Body>
      </Modal>
      <Modal className="modal-right x2-large" show={!!showPoDModal} onHide={() => setShowPoDModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">#{showPoDModal}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe src={`${showPoDDocUrl}`} className="w-100 min-vh-100" title="Invoice" />
        </Modal.Body>
      </Modal>
      <Modal className="modal-right x2-large" show={!!showAcknowledgementModal} onHide={() => setShowAcknowledgementModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">#{showPoDModal}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe src={`${showPoDDocUrl}`} className="w-100 min-vh-100" title="Invoice" />
        </Modal.Body>
      </Modal>

      {showSOAModal && (
        <SOAModal
          statementPeriodId={params.statementId}
          show={showSOAModal}
          onHide={() => {
            setSOAModal(false);
            getTransactions();
          }}
        />
      )}

      {/* Title Start */}
      <div className="page-title-container mb-3">
        <Row className="g-0">
          <Col className="mb-2 align-items-start" xs="6">
            <h1 className="mb-2 pb-0 display-4">
              {title} <b>#{params.statementId}</b>{' '}
              <span className="text-muted font-heading text-too-small align-items-start vertical-align-top">
                <span data-testid="importNewProductButton" variant="success" className="invoice-status--paid p-1">
                  <span>{list?.[0]?.statusBadge}</span>
                </span>
              </span>
            </h1>

            <div className="text-muted font-heading text-small"> </div>
          </Col>
          <Col xs="6">
            <div className="float-end">
              &nbsp;
              <If condition={transactionSummary.payment_processed_on && !transactionSummary.payment_received_on}>
                <Button
                  size="sm"
                  variant="outline-success"
                  onClick={() => {
                    handleReceiptAcknowledgement();
                  }}
                  disabled={waitingResponse}
                  className="float-end ms-2"
                >
                  {waitingResponse && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                  Acknowledge Payment Received
                </Button>
              </If>
              <If condition={transactionSummary.payment_received_on}>
                <span variant="success" className="invoice-status--paid p-1">
                  <span>Payment Received On {format(new Date(Number(transactionSummary.payment_received_on)), 'dd/MM/yyyy hh:m a')}</span>
                </span>
              </If>
              &nbsp;
              <If condition={!transactionSummary.soa_doc && !list?.[0]?.isCurrentStatement}>
                <Button
                  data-testid="importNewProductButton"
                  onClick={() => {
                    setSOAModal(true);
                  }}
                  variant="primary"
                  className="btn-icon btn-icon-start ms-0 ms-sm-1 w-100 w-md-auto float-end"
                >
                  <CsLineIcons icon="invoice" /> <span>Generate SOA</span>
                </Button>
              </If>
              <If condition={transactionSummary.soa_doc}>
                <a
                  className="btn btn-outline-success text-center p-1 px-2"
                  target="_blank"
                  rel="noreferrer"
                  href={`https://${transactionSummary.soa_doc}`}
                  download
                >
                  <CsLineIcons icon="download" size={14} className="mr-1" />
                  Download SOA
                </a>
              </If>
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md="5" lg="3" xxl="2" className="mb-1">
            <div className="d-inline-block float-md-start me-1 mb-1 search-input-container w-100 shadow bg-foreground">
              <Form.Control type="text" placeholder="Search" id="listSearch" />
              <span className="search-magnifier-icon">
                <CsLineIcons icon="search" />
              </span>
              <span className="search-delete-icon d-none">
                <CsLineIcons icon="close" />
              </span>
            </div>
          </Col>
          <Col md="7" lg="9" xxl="10" className="mb-1 text-end">
            <Dropdown align={{ xs: 'end' }} className="d-inline-block ms-1">
              <OverlayTrigger delay={{ show: 1000, hide: 0 }} placement="top" overlay={<Tooltip id="tooltip-top">Export</Tooltip>}>
                <Dropdown.Toggle variant="foreground-alternate" className="dropdown-toggle-no-arrow btn btn-icon btn-icon-only shadow">
                  <CsLineIcons icon="download" />
                </Dropdown.Toggle>
              </OverlayTrigger>
              <Dropdown.Menu className="shadow dropdown-menu-end">
                {/* <Dropdown.Item href="#">Copy</Dropdown.Item> */}
                <Dropdown.Item
                  href="#"
                  onClick={() => {
                    getTransactions('orders');
                  }}
                >
                  Order Wise
                </Dropdown.Item>
                {/* <Dropdown.Item
                  href="#"
                  onClick={() => {
                    getTransactions('transactions');
                  }}
                >
                  Transaction Wise
                </Dropdown.Item> */}
                {/* <Dropdown.Item href="#">Cvs</Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown align={{ xs: 'end' }} className="d-inline-block ms-1" id="listSort">
              <Dropdown.Toggle variant="foreground-alternate" className="shadow sw-19">
                {f({ id: 'menu.sortby' })} {sortingName}
              </Dropdown.Toggle>

              <Dropdown.Menu className="shadow dropdown-menu-end" data-testid="sortItemDropdown">
                {sortByValues.map((obj, i) => {
                  return (
                    <Dropdown.Item
                      href="#"
                      value={obj.key}
                      key={i}
                      onClick={() => {
                        handleSorting(obj.key, obj.order, obj.label);
                      }}
                    >
                      {obj.label}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            {/* Length End */}
            <Dropdown align={{ xs: 'end' }} className="d-inline-block ms-1" id="listLimit">
              <Dropdown.Toggle variant="foreground-alternate" className="shadow sw-13">
                {limitValue.limit} Items
              </Dropdown.Toggle>

              <Dropdown.Menu className="shadow dropdown-menu-end">
                {limitByValues.map((limit, i) => {
                  return (
                    <Dropdown.Item key={i} href="#" onClick={() => handleLimit(limit)}>
                      {limit} Items
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            {/* Length End */}
          </Col>
        </Row>
      </div>

      {/* <Card className="mb-2">
        <Card.Body>
          <Row>
            <Col lg="3">
              <FormLabel>Transaction Type:</FormLabel>
              <div className="mb-3">
                <Select classNamePrefix="react-select" options={TRANSACTION_TYPES} name="offerId" placeholder="Select" />
              </div>
            </Col>
            <Col lg="3">
              <div>
                <FormLabel>With in:</FormLabel>
                <div className=" ml-2">
                  <label className="form-check  position-relative p-0 m-0">
                    <input type="radio" className="form-check-input " name="period_type" />
                    Statement Period
                  </label>
                </div>
              </div>

              <div className=" ml-2">
                <label className="form-check  position-relative p-0 m-0">
                  <input type="radio" className="form-check-input " name="period_type" />
                  Past number of days
                </label>
              </div>
              <div className=" ml-2">
                <label className="form-check  position-relative p-0 m-0">
                  <input type="radio" className="form-check-input " name="period_type" />
                  Custom Date Range
                </label>
              </div>
            </Col>
            <Col lg="4">
              <div>
                <div className="">
                  <Select
                    classNamePrefix="react-select"
                    options={TRANSACTION_TYPES}
                    name="offerId"
                    // value={getSelectedDropdownValue(offerOptions, offerInfo?.offerId)}
                    placeholder="Select"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card> */}

      <div className="mb-5">
        <Table
          loading={isLoading}
          bordered
          cellBordered
          autoHeight
          data={list}
          wordWrap="break-word"
          headerHeight={50}
          rowClassName={(rowData) => {
            if (rowData && rowData.isSummary) {
              return 'summary-row';
            }
            return '';
          }}
        >
          <Column width={50} fixed align="center">
            <HeaderCell>SI #</HeaderCell>
            <Cell dataKey="si" />
          </Column>

          <Column width={200} fixed>
            <HeaderCell>Order Reference #</HeaderCell>
            <CustomCell dataKey="reference_no" />
          </Column>
          <Column width={200} align="center">
            <HeaderCell>LPO</HeaderCell>
            <CustomCell dataKey="po_number" />
          </Column>
          <Column width={200} align="center">
            <HeaderCell>AWB / Delivery Proof</HeaderCell>
            <CustomCell dataKey="delivery_completed_at" />
          </Column>

          <Column width={150}>
            <HeaderCell>Invoice / Credit Note</HeaderCell>
            <CustomCell dataKey="invoice_no" />
          </Column>

          {/* <Column width={180}>
            <HeaderCell>Credit Note</HeaderCell>
            <CustomCell dataKey="credit_note" />
          </Column> */}

          <Column width={150} align="center">
            <HeaderCell>Order Total</HeaderCell>
            <CustomCell dataKey="sales" />
          </Column>

          <Column width={150} align="center">
            <HeaderCell>Danube Fees</HeaderCell>
            <CustomCell dataKey="commission" />
          </Column>
          <Column width={150} align="center">
            <HeaderCell>Shipping Charges</HeaderCell>
            <CustomCell dataKey="shipping_charge" />
          </Column>
          {/* 
          <Column width={80} align="center">
            <HeaderCell>Refund</HeaderCell>
            <CustomCell dataKey="refunds" />
          </Column>

          <Column width={100} align="center">
            <HeaderCell>Danube Fees (Refund)</HeaderCell>
            <CustomCell dataKey="commission_reversed" />
          </Column> */}
          {/* <Column width={100} align="center">
            <HeaderCell>Interal Team Verified</HeaderCell>
            <CustomCell dataKey="accounts_team_verified" />
          </Column> */}

          <Column width={100} align="center">
            <HeaderCell>Payable Team Verified</HeaderCell>
            <CustomCell dataKey="payable_team_verified" />
          </Column>
          <Column width={100} align="center">
            <HeaderCell>Audit Team Verified </HeaderCell>
            <CustomCell dataKey="audit_team_verified" />
          </Column>

          <Column width={120} fixed="right" align="center">
            <HeaderCell>Total Payable</HeaderCell>
            <CustomCell dataKey="total_payout" />
          </Column>
        </Table>

        {/* <div style={{ padding: 20 }}>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={count?.totalPages}
            limitOptions={[10, 30, 50]}
            limit={limitValue.limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          />
        </div> */}
      </div>
      <SupportTicketsDetail
        typeId="Statements"
        typeIdentifier={params.statementId}
        Activity={() => (
          <Col xxl="4">
            {/* Activity Start */}
            <h2 className="small-title">Approval Process</h2>
            <Card className="mb-5">
              <Card.Body>
                <Row className="g-0">
                  <Col xs="auto" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative me-4">
                    <div className="w-100 d-flex sh-1" />
                    <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                      <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                    </div>
                    <div className="w-100 d-flex h-100 justify-content-center position-relative">
                      <div className="line-w-1 bg-separator h-100 position-absolute" />
                    </div>
                  </Col>
                  <Col className="mb-4">
                    <div className="h-100">
                      <div className="d-flex flex-column justify-content-start">
                        <div className="d-flex flex-column">
                          <Button variant="link" className="p-0 pt-1 heading text-start">
                            Approval Level1 - Payable Team
                          </Button>
                          <div className="text-alternate">
                            <div style={{ width: 60 }} className="ms-md-6">
                              <Progress.Circle percent={transactionSummary.payableProgress} strokeWidth={10} strokeColor="#ffc107" className="p-0" />
                            </div>
                          </div>
                          <div className="text-muted mt-1">
                            <If condition={transactionSummary.approved_by_payment_team_on}>
                              <CsLineIcons icon="check" />
                              <If condition={transactionSummary.payment_team_approval_doc !== ''}>
                                <a
                                  className="text-medium text-success text-truncate"
                                  rel="noreferrer"
                                  target="_blank"
                                  href={`https://${transactionSummary.payment_team_approval_doc}`}
                                  download
                                >
                                  <CsLineIcons icon="download" size={14} />
                                </a>
                              </If>{' '}
                              Approved By {transactionSummary.approved_by_payment_team_member_name} on{' '}
                              {format(new Date(Number(transactionSummary.approved_by_payment_team_on)), 'dd/MM/yyyy hh:m a')}
                            </If>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="g-0">
                  <Col xs="auto" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative me-4">
                    <div className="w-100 d-flex sh-1 position-relative justify-content-center">
                      <div className="line-w-1 bg-separator h-100 position-absolute" />
                    </div>
                    <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                      <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                    </div>
                    <div className="w-100 d-flex h-100 justify-content-center position-relative">
                      <div className="line-w-1 bg-separator h-100 position-absolute" />
                    </div>
                  </Col>
                  <Col className="mb-4">
                    <div className="h-100">
                      <div className="d-flex flex-column justify-content-start">
                        <div className="d-flex flex-column">
                          <Button variant="link" className="p-0 pt-1 heading text-start">
                            Approval Level 2 - Audit Team
                          </Button>
                          <div className="text-alternate">
                            <div style={{ width: 60 }} className="ms-md-6">
                              <Progress.Circle percent={transactionSummary.auditProgress} strokeWidth={10} strokeColor="#ffc107" className="p-0" />
                            </div>
                          </div>
                          <div className="text-muted mt-1">
                            <If condition={transactionSummary.approved_by_audit_team_on}>
                              <CsLineIcons icon="check" />
                              <If condition={transactionSummary.audit_team_approval_doc !== ''}>
                                <a
                                  className="text-medium text-success text-truncate"
                                  rel="noreferrer"
                                  target="_blank"
                                  href={`https://${transactionSummary.audit_team_approval_doc}`}
                                  download
                                >
                                  <CsLineIcons icon="download" size={14} />
                                </a>
                              </If>{' '}
                              Approved By {transactionSummary.approved_by_audit_team_member_name} on{' '}
                              {format(new Date(Number(transactionSummary.approved_by_audit_team_on)), 'dd/MM/yyyy hh:m a')}
                            </If>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="g-0">
                  <Col xs="auto" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative me-4">
                    <div className="w-100 d-flex sh-1 position-relative justify-content-center">
                      <div className="line-w-1 bg-separator h-100 position-absolute" />
                    </div>
                    <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                      <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                    </div>
                    <div className="w-100 d-flex h-100 justify-content-center position-relative" />
                  </Col>
                  <Col>
                    <div className="h-100">
                      <div className="d-flex flex-column justify-content-start">
                        <div className="d-flex flex-column">
                          <Button variant="link" className="p-0 pt-1 heading text-start">
                            Final Payment - Account Team
                          </Button>
                        </div>
                        <div className="text-muted mt-1">
                          <If condition={transactionSummary.payment_processed_on}>
                            <CsLineIcons icon="check" />
                            <If condition={transactionSummary.payment_processed_doc !== ''}>
                              <a
                                className="text-medium text-success text-truncate"
                                rel="noreferrer"
                                target="_blank"
                                href={`https://${transactionSummary.payment_processed_doc}`}
                                download
                              >
                                <CsLineIcons icon="download" size={14} />
                              </a>
                            </If>{' '}
                            Payment Processed By {transactionSummary.payment_processed_by_member_name} on{' '}
                            {format(new Date(Number(transactionSummary.payment_processed_on)), 'dd/MM/yyyy hh:m a')}
                          </If>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            {/* Activity End */}
          </Col>
        )}
      />
    </>
  );
};

export default Transactions;
