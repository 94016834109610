import React, { useMemo, useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getMenuItems } from 'routing/helper';
import routesAndMenuItems from 'routes.js';
import { useHistory } from 'react-router-dom';
import SidebarMenuItems from './SidebarMenuItems';
import MessageAlert from '../MessageAlert';

const SidebarMenu = () => {
  const [modalShow, setModalShow] = useState(false);
  const { isLogin, currentUser } = useSelector((state) => state.auth);
  const { useSidebar } = useSelector((state) => state.menu);
  const history = useHistory();
  const { statusSummary = {} } = useSelector((state) => state.order);
  const { showSidebar = true } = useSelector((state) => state.settings);
  const { unread, supportTicketOpenStatusCount } = useSelector((state) => state.messages);

  const handleHide = () => {
    history.push('/dashboard/customer-messages');
    setModalShow(false);
  };

  useEffect(() => {
    if (unread) {
      let isShow = false;
      if (window.localStorage.messagePopupLastShown) {
        if (new Date(window.localStorage.messagePopupLastShown).getTime() + 15 * 60 * 1000 <= new Date().getTime()) {
          isShow = true;
        }
      } else isShow = true;
      if (isShow) {
        setModalShow(true);
        window.localStorage.messagePopupLastShown = new Date().toISOString();
      }
    }
  }, [unread]);

  const menuItemsMemo = useMemo(
    () =>
      getMenuItems({
        data: routesAndMenuItems({ orderStatusSummary: statusSummary, unreadMessages: unread, supportTicketOpenStatusCount, sellerId: currentUser.sellerId })
          .sidebarItems,
        isLogin,
        userRole: currentUser.role,
        pathname: history?.location?.pathname,
      }),
    [isLogin, currentUser, history?.location?.pathname, statusSummary]
  );

  if (!useSidebar === true || !showSidebar) {
    return <></>;
  }
  return (
    <Col xs="auto" className="side-menu-container">
      <ul className="sw-25 side-menu mb-0 primary" id="menuSide">
        <SidebarMenuItems menuItems={menuItemsMemo} isActive={currentUser?.isActive || ''} />
      </ul>
      <MessageAlert show={modalShow} onHide={handleHide} count={unread} />
    </Col>
  );
};
export default SidebarMenu;
