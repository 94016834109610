import { gql } from '@apollo/client';

export const GET_SUPPORT_TICKET_THREAD_LIST = () => gql`
  query ListAllSupportTicketThreads($typeIdentifier: String, $typeId: String, $page: Int, $search: String, $date: Date) {
    listAllSupportTicketThreads(typeIdentifier: $typeIdentifier, typeId: $typeId, page: $page, search: $search, date: $date) {
      supportTicketThreads {
        _id
        rating
        title

        review
        reviewBy {
          name
          email
          isAdmin
        }

        reviewDate
        media {
          name
          url
          type
        }
        reviewStatus
      }
    }
  }
`;

export const GET_SUPPORT_TICKET_LIST = () => gql`
  query ListAllSupportTicket($typeIdentifier: String, $typeId: String, $page: Int, $search: String, $date: Date, $status: String) {
    listAllSupportTicket(typeIdentifier: $typeIdentifier, typeId: $typeId, page: $page, search: $search, date: $date, status: $status) {
      supportTicketThreads {
        _id
        ticketId
        rating
        title
        review
        lastComment
        reviewBy {
          name
          email
          isAdmin
        }
        reviewDate
        media {
          name
          url
          type
        }
        reviewStatus
        history {
          name
          statusDate
          status
        }
        replyPendingFromSeller
        replyPendingFromDanube
        lastReplyDate
      }
      totalItems
      statuses {
        _id
        total
        replyPendingFromSellerCount
        replyPendingFromDanubeCount
      }
    }
  }
`;
