export const convertPolygonToGeoJson = (polygon) => {
  if (polygon?.length > 0) {
    return {
      type: 'Feature',
      properties: {},
      geometry: {
        coordinates: [
          polygon.map((p) => {
            return [p.lng, p.lat];
          }),
        ],
        type: 'Polygon',
      },
    };
  }
  return {};
};

export const convertGeoJsonToPolygon = (feature) => {
  return (
    feature?.geometry?.coordinates?.[0]?.map((coords) => {
      return { lng: coords[0], lat: coords[1] };
    }) || []
  );
};

export const citiesGeo = {
  'Abu Dhabi': {
    latitude: 24.4539,
    longitude: 54.3773,
  },
  Dubai: {
    latitude: 25.276987,
    longitude: 55.296249,
  },
  Sharjah: {
    latitude: 25.3463,
    longitude: 55.4209,
  },
  Ajman: {
    latitude: 25.4018,
    longitude: 55.54,
  },
  'Umm Al Quwain': {
    latitude: 25.5647,
    longitude: 55.5552,
  },
  'Ras Al Khaimah': {
    latitude: 25.685,
    longitude: 55.9214,
  },
  Fujairah: {
    latitude: 25.3313,
    longitude: 56.3414,
  },
};
function degToRad(deg) {
  return deg * (Math.PI / 180);
}
export function calculateDistance({ lat1, lon1, lat2, lon2 }) {
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = degToRad(lat2 - lat1);
  const dLon = degToRad(lon2 - lon1);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(degToRad(lat1)) * Math.cos(degToRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;

  return distance;
}
