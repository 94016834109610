import { gql } from '@apollo/client';

export const GET_SUPPORT_TICKET_THREAD_LIST = () => gql`
  query ListAllSupportTicketThreads($typeIdentifier: String, $typeId: String, $page: Int, $search: String, $date: Date) {
    listAllSupportTicketThreads(typeIdentifier: $typeIdentifier, typeId: $typeId, page: $page, search: $search, date: $date) {
      supportTicketThreads {
        _id
        rating
        title
        breakerMessage
        review
        reviewBy {
          name
          email
          isAdmin
        }
        reviewDate
        media {
          name
          url
          type
        }
        reviewStatus
      }
    }
  }
`;

export const UPDATE_RATING_REVIEW = () => gql`
  mutation updateRatingReview($_id: String, $reviewStatus: String) {
    updateRatingReview(_id: $_id, reviewStatus: $reviewStatus) {
      _id
    }
  }
`;
