import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import CsLineIcons from 'cs-line-icons/CsLineIcons';

const MessageAlert = (props) => {
  const { onHide = () => {}, count } = props;
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
      <Modal.Header className="bg-success">
        <Modal.Title id="contained-modal-title-vcenter">
          <CsLineIcons icon="notification" size={18} className="cs-icon icon" /> New Message Alert
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          You have {count} new {count > 1 ? 'messages' : 'message'} from customer.
        </p>
      </Modal.Body>
      <Modal.Footer className="p-3">
        <Button onClick={onHide}>View Message</Button>
      </Modal.Footer>
    </Modal>
  );
};
export default React.memo(MessageAlert);
