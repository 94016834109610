import { COMMENT_DISPLAY_DATE_FORMAT } from 'constants/index';
import moment from 'moment';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import MediaItem from './MediaItem';

const ThreadItem = ({ item }) => {
  // const [showCreateModal, setShowCreateModal] = useState(false);
  // const [medias, setMedias] = useState([]);
  // const [search, setSearch] = useState('');
  // const [date, setDate] = useState('');
  // const [page, setPage] = useState(0);

  if (item.breakerMessage) {
    if (item.breakerMessage?.indexOf('Assigned to') > -1) {
      return null;
    }
    return (
      <div className="thread-separator text-center">
        <div className="line" />
        <h2>
          {item.breakerMessage}{' '}
          <span
            xs="12"
            className="order-3 order-sm-0 col-sm-auto sw-sm-11 sw-sm-11 lh-1-5 text-small text-muted text-sm-end d-flex flex-column justify-content-sm-center"
          >
            {item?.reviewDate && moment(Number(item?.reviewDate || 0)).format(COMMENT_DISPLAY_DATE_FORMAT)}
          </span>
        </h2>
        <div className="line" />
      </div>
    );
  }
  return (
    <div className="border-bottom mb-4 pb-4">
      <Row className="g-0 sh-sm-5 h-auto">
        <Col className="ps-0">
          <Row className="h-100 g-2">
            <Col className="h-sm-100 d-flex flex-column justify-content-sm-center mb-1 mb-sm-0">
              <div>{item?.reviewBy?.name}</div>
              <div className="text-small text-muted">{item?.reviewBy?.email}</div>
            </Col>
            <Col
              xs="12"
              className="order-3 order-sm-0 col-sm-auto sw-sm-11 sw-sm-11 lh-1-5 text-small text-muted text-sm-end d-flex flex-column justify-content-sm-center"
            >
              {item?.reviewDate && moment(Number(item?.reviewDate || 0)).format(COMMENT_DISPLAY_DATE_FORMAT)}
            </Col>
          </Row>
        </Col>
      </Row>
      <div>
        <div className="mt-4">{item.review}</div>
        <If condition={item?.media?.length}>
          <div className="mt-4 d-flex flex-row flex-wrap">
            {item.media?.map((media) => {
              return <MediaItem media={media} key={media.name} />;
            })}
          </div>
        </If>
      </div>
    </div>
  );
};

export default ThreadItem;
