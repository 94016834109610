/* eslint-disable */
import { lazy } from 'react';
import { USER_ROLE } from 'constants/index';
import { DEFAULT_PATHS } from 'config.js';

import ReportIndex from 'views/reports/ReportViews';
import StatementViewsV2 from 'views/statements/StatementViewsV2';
import StatementDetail from 'views/statements/StatementDetail';
import Transactions from 'views/statements/Transactions';
import GettingStarted from 'views/dashboard/DashboardGettingStarted';
import QaOrders from 'views/messages';

const reports = {
  index: ReportIndex,
};
const statements = {
  index: StatementViewsV2,
  detail: StatementDetail,
  transactions: Transactions,
};

const messages = {
  index: QaOrders,
};

const dashboard = {
  gettingStarted: GettingStarted,
  analysis: lazy(() => import('views/dashboard/DashboardAnalysis')),
  messages: lazy(() => import('views/support/SupportTickets')),
  supportTicketDetail: lazy(() => import('views/support/SupportTicketsDetail')),
  salesAnalysis: lazy(() => import('views/dashboard/SalesAnalysis')),
  productAnalysis: lazy(() => import('views/dashboard/ProductAnalysis')),
  deliverySheet: lazy(() => import('views/orders/DeliveryTrack')),
  customerMessages: QaOrders,
  // accountHealth: lazy(() => import('views/dashboard/AccountHealth')),
};
const catalog = {
  products: lazy(() => import('views/catalog/list')),
  offers: lazy(() => import('views/catalog/Offers')),
  offerCreate: lazy(() => import('views/catalog/Offers/Create')),
  offerDetail: lazy(() => import('views/catalog/Offers/Detail')),
  imports: lazy(() => import('views/catalog/ProductImports')),
  importHistory: lazy(() => import('views/catalog/ImportHistory')),
  databaseAdd: lazy(() => import('views/catalog/ServicesDatabaseAdd')),
  productDetail: lazy(() => import('views/catalog/detail/ProductsDetail')),
  storage: lazy(() => import('views/catalog/ServicesStorage')),
  storageFolder: lazy(() => import('views/catalog/ServicesStorageFolder')),
  hosting: lazy(() => import('views/catalog/ServicesHosting')),
  users: lazy(() => import('views/catalog/ServicesUsers')),
  auditLog: lazy(() => import('views/catalog/AuditLog')),
  brands: lazy(() => import('views/catalog/brands/index')),
  productRatingsAndReviews: lazy(() => import('views/catalog/ratingReview/ProductRatingsAndReviews')),
  questionAnswer: lazy(() => import('views/catalog/questionAnswer/QuestionAnswer')),
};
const account = {
  accountDetail: lazy(() => import('views/account/AccountDetail')),
  users: lazy(() => import('views/account/Users')),
  ratingsAndReviews: lazy(() => import('views/account/RatingsAndReviews')),
};

const orders = {
  views: lazy(() => import('views/orders/OrdersView')),
  detail: lazy(() => import('views/orders/OrdersViewDetail')),
};
const returns = {
  views: lazy(() => import('views/returns/ReturnsView')),
  detail: lazy(() => import('views/returns/ReturnsViewDetail')),
};
const marketing = {
  couponList: lazy(() => import('views/marketing/CouponList')),
  couponDetail: lazy(() => import('views/marketing/CouponDetail')),
  // promotionList: lazy(() => import('views/marketing/PromotionList')),
  // bannerSlots: lazy(() => import('views/marketing/BannerSlots')),
  accountDetail: lazy(() => import('views/account/AccountDetail')),
  adsPlatform: lazy(() => import('views/marketing/AdsPlatform')),
  adsCampaignDetails: lazy(() => import('views/marketing/AdsCampaignDetails')),
};
const penalty = {
  penaltyList: lazy(() => import('views/penalty/PenaltyList')),
};
const appRoot = DEFAULT_PATHS.APP.endsWith('/') ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length) : DEFAULT_PATHS.APP;

const routesAndMenuItems = ({ orderStatusSummary = {}, unreadMessages = 0, supportTicketOpenStatusCount = 0, sellerId, showAds = false } = {}) => {
  console.log('orderStatusSummary', orderStatusSummary);
  return {
    mainMenuItems: [
      {
        path: DEFAULT_PATHS.APP,
        exact: true,
        redirect: true,
        to: `${appRoot}/dashboard/getting-started`,
      },
      {
        path: `${appRoot}/catalog`,

        label: 'menu.catalog',
        icon: 'book-open',
      },
      {
        path: `${appRoot}/orders`,
        label: 'menu.orders',
        icon: 'dollar',
        // exact: true,
        redirect: true,
        component: orders.views,
        to: `${appRoot}/orders/views`,
      },

      {
        path: `${appRoot}/reports`,
        label: 'menu.reports',
        icon: 'file-text',
        component: reports.index,
      },
      {
        path: `${appRoot}/statements`,
        label: 'menu.statements',
        // exact: true,
        icon: 'trend-up',
        component: statements.index,
        redirect: true,
        to: `${appRoot}/statements/pending`,
      },
      // {
      //   path: `${appRoot}/marketing/campaigns`,
      //   label: 'Campaigns',
      //   icon: 'board-1',
      //   showWhen: showAds,
      // },
      {
        path: `${appRoot}/marketing/promotions`,
        label: 'Promotions',
        icon: 'promo',
      },
      {
        path: `${appRoot}/penalty`,
        label: 'Penalties',
        icon: 'anchor',
        component: penalty.penaltyList,
      },
      {
        path: `${appRoot}/account`,
        label: 'menu.account',
        icon: 'user',
      },
      // {
      //   path: `${appRoot}/messages`,
      //   label: 'Customer Messages',
      //   // exact: true,
      //   icon: 'email',
      //   component: messages.index,
      // },
    ],
    sidebarItems: [
      {
        path: `${appRoot}/dashboard`,
        label: 'menu.dashboard',
        icon: 'grid-1',
        exact: true,
        redirect: true,
        to: `${appRoot}/dashboard/getting-started`,
        subs: [
          { path: '/getting-started', label: 'menu.dashboard', icon: 'navigate-diagonal', component: dashboard.gettingStarted },
          { path: '/customer-messages', label: `Customer Messages (${unreadMessages || 0})`, icon: 'support', component: dashboard.customerMessages },

          {
            path: '/messages',
            label: `Danubehome Support (${supportTicketOpenStatusCount || 0})`,
            icon: 'news',
            exact: true,
            component: dashboard.messages,
            subs: [{ path: '/detail/:id', exact: true, label: 'menu.messages', icon: 'email', hideInMenu: true, component: dashboard.supportTicketDetail }],
          },
          { path: '/sales-analysis', label: 'menu.sales_analysis', icon: 'chart-4', component: dashboard.salesAnalysis },
          { path: '/product-analysis', label: 'menu.product_analysis', icon: 'chart-2', component: dashboard.productAnalysis },
          { path: '/ratings-and-reviews', label: 'Product Reviews', icon: 'star', component: catalog.productRatingsAndReviews },
          {
            path: 'https://seller-academy.danubehome.com/en',
            link: 'https://seller-academy.danubehome.com/en',
            label: 'Seller Academy',
            icon: 'file-text',
            isExternal: true,
          },
          {
            // hideInMenu: sellerId === 'ATOZFURNITURE' ? false : true,
            path: '/delivery-track-sheet',
            label: `Delivery Track Sheet`,
            icon: 'delivery_truck',
            exact: true,
            component: dashboard.deliverySheet,
          },
          // { path: '/account-health', label: 'Account Health', icon: 'chart-4', component: dashboard.accountHealth },
        ],
      },
      {
        path: `${appRoot}/catalog`,
        label: 'menu.company_catalog',
        icon: 'grid-1',
        exact: true,

        redirect: true,
        showWhen: (pathname) => {
          return pathname?.indexOf('catalog') > -1;
        },
        to: `${appRoot}/catalog/partner`,
        component: catalog.products,
        subs: [
          {
            path: '/partner',
            label: 'menu.catalog',
            icon: 'book-open',
            component: catalog.products,
            subs: [
              { path: '/add', label: 'menu.database-add', hideInMenu: true, component: catalog.databaseAdd },
              { path: '/detail/:id', label: 'menu.database-detail', hideInMenu: true, component: catalog.productDetail },
            ],
          },
          {
            path: '/inventory',
            label: 'menu.inventory',
            icon: 'book-open',
            component: catalog.products,
          },
          {
            path: '/offers',
            label: 'menu.offers',
            icon: 'dollar',
            component: catalog.offers,
            subs: [
              { path: '/detail/:offerListId', label: 'menu.database-add', hideInMenu: true, component: catalog.offerDetail },
              { path: '/create', label: 'menu.database-add', hideInMenu: true, component: catalog.offerCreate },
            ],
          },

          { path: '/import', label: 'menu.import', icon: 'cloud-upload', component: catalog.imports },
          // { path: '/exports', label: 'menu.exports', icon: 'cloud-download', component: catalog.hosting },
          { path: '/import-history', label: 'menu.import_history', icon: 'clock', component: catalog.importHistory },
          { path: '/audit-logs', label: 'menu.activity', icon: 'shield', component: catalog.auditLog },
          { path: '/ratings-and-reviews', label: 'menu.ratings-and-reviews', icon: 'star', component: catalog.productRatingsAndReviews },
          { path: '/question-and-answer', label: 'menu.question-and-answer', icon: 'question-hexagon', component: catalog.questionAnswer },
        ],
      },
      {
        path: `${appRoot}/all-catalog`,
        label: 'Sellerhub Catalog',
        icon: 'grid-1',
        exact: true,
        hideInMenu: true,

        redirect: true,
        showWhen: (pathname) => {
          return pathname?.indexOf('catalog') > -1;
        },
        to: `${appRoot}/catalog/partner`,
        component: catalog.products,
        subs: [
          {
            path: '/partner',
            label: 'All Catalog',
            icon: 'book-open',
            component: catalog.products,
            subs: [
              { path: '/add', label: 'menu.database-add', hideInMenu: true, component: catalog.databaseAdd },
              { path: '/detail/:id', label: 'menu.database-detail', hideInMenu: true, component: catalog.productDetail },
            ],
          },
          { path: '/brands', label: 'Brand Management', icon: 'dollar', component: catalog.brands },
        ],
      },

      {
        path: `${appRoot}/orders`,
        label: 'menu.orders',

        exact: true,
        redirect: true,
        icon: 'bookmark',
        //  hideInMenu: false,
        showWhen: (pathname) => {
          return pathname?.indexOf('orders') > -1 || pathname?.indexOf('returns') > -1;
        },
        to: `${appRoot}/orders/views/all`,
        subs: [
          {
            path: '/views/all',
            label: `All Orders (${orderStatusSummary?.[20] || 0})`,
            // icon: 'orders',
            component: orders.views,
            exact: true,
          },
          {
            path: '/views/pending',
            label: `Pending (${orderStatusSummary?.[0] || 0})`,
            // // icon: 'bookmark',
            component: orders.views,
            exact: true,
          },
          {
            path: '/views/packing',
            label: `Accepted (${orderStatusSummary?.[1] || 0})`,
            // icon: 'bookmark',
            component: orders.views,
            exact: true,
          },
          {
            path: '/views/shipping_pending',
            label: `Ready To Pickup (${orderStatusSummary?.[2] || 0})`,
            // icon: 'bookmark',
            component: orders.views,
            exact: true,
          },
          {
            path: '/views/shipped',
            label: `Shipped/Picked (${orderStatusSummary?.[3] || 0})`,
            // icon: 'bookmark',
            component: orders.views,
            exact: true,
          },
          {
            path: '/views/out_for_delivery',
            label: `Out for Delivery (${orderStatusSummary?.[11] || 0})`,
            // icon: 'bookmark',
            component: orders.views,
            exact: true,
          },
          {
            path: '/views/delivered',
            label: `Delivered (${orderStatusSummary?.[4] || 0})`,
            // icon: 'bookmark',
            component: orders.views,
            exact: true,
          },
          {
            path: '/views/undelivered',
            label: `Un Delivered (${orderStatusSummary?.[10] || 0})`,
            // icon: 'bookmark',
            component: orders.views,
            exact: true,
          },
          // {
          //   path: '/views/invoice_pending',
          //   label: `Invoice Pending (${orderStatusSummary?.[9] || 0})`,
          //   // icon: 'bookmark',
          //   component: orders.views,
          //   exact: true,
          // },
          {
            path: '/views/completed',
            label: `Completed (${orderStatusSummary?.[8] || 0})`,
            // icon: 'bookmark',
            component: orders.views,
            exact: true,
          },
          {
            path: '/views/rejected',
            label: `Rejected (${orderStatusSummary?.[6] || 0})`,
            // icon: 'bookmark',
            component: orders.views,
            exact: true,
          },
          {
            path: '/views/cancelled',
            label: `Cancelled (${orderStatusSummary?.[15] || 0})`,
            component: orders.views,
            exact: true,
          },

          { path: '/detail/:id', label: 'menu.orders-detail', icon: 'file-empty', component: orders.detail, hideInMenu: true },
        ],
      },
      {
        path: `${appRoot}/returns`,
        label: 'RETURNS',
        icon: 'user',
        exact: true,
        redirect: true,
        //  hideInMenu: false,
        showWhen: (pathname) => {
          return pathname?.indexOf('orders') > -1 || pathname?.indexOf('returns') > -1;
        },
        to: `${appRoot}/returns/views`,
        subs: [
          {
            path: `${appRoot}/returns`,
            label: `Returns (${orderStatusSummary?.[25] || 0})`,
            icon: 'trend-up',
            exact: true,
            component: returns.views,
            to: `${appRoot}/returns/views`,
          },
          { path: '/detail/:id', label: 'menu.orders-detail', icon: 'file-empty', component: returns.detail, hideInMenu: true },
        ],
      },
      {
        path: `${appRoot}/statements`,
        label: 'menu.statements',
        icon: 'user',

        redirect: true,
        showWhen: (pathname) => {
          return pathname.includes('statements');
        },
        to: `${appRoot}/statements/all`,
        subs: [
          { path: '/overview', label: 'Overview', icon: 'building', component: account.accountDetail },
          { path: '/transactions', label: 'Transactions', icon: 'building', component: statements.transactions, exact: true },
          { path: '/all', label: 'All Statements', icon: 'bank', iconSize: 16, component: statements.index },
          {
            path: '/transactions/detail/:statementId',
            label: 'Statement Period - ',
            hideInMenu: true,
            icon: 'bank',
            iconSize: 16,
            component: statements.transactions,
          },
          // { path: '/additional', label: 'Additional Documents', icon: 'folder', component: account.accountDetail },
          // { path: '/pickup', label: 'Warehouse Address / Pickup Location', icon: 'pin', component: account.accountDetail },
        ],
      },
      {
        path: `${appRoot}/marketing`,
        label: 'menu.marketing',
        icon: 'trend-up',

        redirect: true,
        showWhen: (pathname) => {
          return pathname.includes('marketing');
        },
        to: `${appRoot}/marketing/promotions`,
        subs: [
          {
            path: '/promotions',
            label: 'Ongoing Promotions',
            icon: 'trend-up',
            component: marketing.couponList,
            exact: true,
            subs: [{ path: '/detail/:id', label: 'menu.orders-detail', icon: 'file-empty', component: marketing.couponDetail, hideInMenu: true }],
          },
          {
            path: '/campaigns',
            label: 'Campaigns',
            icon: 'board-1',
            component: marketing.adsPlatform,
            exact: true,
            subs: [
              { path: '/detail/:id', exact: true, label: 'ads.campaignDetails', icon: 'email', hideInMenu: true, component: marketing.adsCampaignDetails },
            ],
          },

          // { path: '/banners', label: 'Banners', icon: 'trend-up', component: marketing.bannerSlots, exact: true },
          // { path: '/search', label: 'Search Results', icon: 'trend-up', component: marketing.bannerSlots },
        ],
      },
      {
        path: `${appRoot}/penalty`,
        label: 'Penalties',
        icon: 'trend-up',

        redirect: true,
        showWhen: (pathname) => {
          return pathname.includes('penalty');
        },
        to: `${appRoot}/penalty/list`,
        subs: [
          {
            path: '/penalty/list',
            label: 'Penalties',
            icon: 'trend-up',
            component: penalty.penaltyList,
            exact: true,
            subs: [],
          },
        ],
      },


      {
        path: `${appRoot}/account`,
        label: 'menu.account',
        icon: 'user',

        redirect: true,
        showWhen: (pathname) => {
          return pathname.includes('account');
        },
        to: `${appRoot}/account/settings/business`,
        subs: [
          { path: '/settings/business', label: 'Business Information', icon: 'building', component: account.accountDetail },
          { path: '/settings/bank', label: 'Bank Account Details', icon: 'bank', iconSize: 16, component: account.accountDetail },
          { path: '/settings/documents', label: 'Additional Documents', icon: 'folder', component: account.accountDetail },
          { path: '/settings/warehouse', label: 'Warehouse Address / Pickup Location', icon: 'pin', component: account.accountDetail },
          { path: '/settings/shipping', label: 'Shipping Settings', icon: 'pin', component: account.accountDetail },
          { path: '/settings/costs', label: 'Costs', icon: 'pin', component: account.accountDetail },
          // { path: '/settings/pricing', label: 'Price Setttings', icon: 'dollar', component: account.accountDetail },
          { path: '/settings/notification', label: 'menu.notification_settings', icon: 'bell', component: account.accountDetail },
          // { path: '/settings/store-page', label: 'Store Page', icon: 'shop', component: account.accountDetail },
          { path: '/settings/advancedShippingSettings', label: 'Advanced Shipping Settings', icon: 'shop', component: account.accountDetail },
          //  { path: '/settings', label: 'menu.settings', icon: 'gear', component: account.accountDetail },
          { path: '/users', label: 'menu.users', icon: 'user', component: account.users },
          { path: '/ratings-and-reviews', label: 'menu.ratings-and-reviews', icon: 'star', component: account.ratingsAndReviews },
          { path: '/settings/api', label: 'API Settings', icon: 'gear', iconSize: 16, component: account.accountDetail },
        ],
      },
    ],
  };
};
export default routesAndMenuItems;
