import React from 'react';
import { Col, Row, Card, Badge } from 'react-bootstrap';

const ReturnDetails = ({ returns }) => {
  const renderStatus = (itemStatus) => {
    let returnStatus = '';
    switch (itemStatus) {
      case 'picked':
        returnStatus = 'Picked';
        break;
      case 'received':
        returnStatus = 'Received';
        break;
      default:
        returnStatus = 'Initiated';
    }
    return returnStatus;
  };
  return (
    <Row className="row-cols-12 row-cols-lg-12 g-2 mb-5">
      <Card className="h-100">
        <Card.Header className="p-2">
          <Row className="g-2 mb-2">
            <Col md="6" lg="6" xs="6">
              <b>Returns</b>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="pb-0">
          {/* List Header Start */}
          <Row className="g-0 h-100 align-content-center d-none d-md-flex ps-5 pe-5 mb-2 custom-sort">
            <Col md="1" className="d-flex flex-column mb-lg-0 pe-3 justify-content-center">
              <div className=" text-medium cursor-pointer fw-bold">S.No</div>
            </Col>
            <Col md="2" className="d-flex flex-column pe-1 justify-content-center">
              <div className="text-medium cursor-pointer fw-bold">Image</div>
            </Col>
            <Col md="2" className="d-flex flex-column pe-1 justify-content-center">
              <div className=" text-medium cursor-pointer fw-bold">Name</div>
            </Col>
            <Col md="2" className="d-flex flex-column pe-1 justify-content-center">
              <div className=" text-medium cursor-pointer fw-bold">Sku</div>
            </Col>
            <Col md="1" className="d-flex flex-column pe-1 justify-content-center">
              <div className=" text-medium cursor-pointer fw-bold">Qty</div>
            </Col>
            <Col md="1" className="d-flex flex-column pe-1 justify-content-center">
              <div className="text-medium cursor-pointer fw-bold">Price</div>
            </Col>
            <Col md="1" className="d-flex flex-column pe-1 justify-content-center">
              <div className="text-medium cursor-pointer fw-bold">Commission (Refund)</div>
            </Col>
            <Col md="1" className="d-flex flex-column pe-1 justify-content-center">
              <div className="text-medium cursor-pointer fw-bold">Total</div>
            </Col>
            <Col md="1" className="d-flex flex-column pe-1 justify-content-center">
              <div className="text-medium cursor-pointer fw-bold">Action</div>
            </Col>
          </Row>
          {/* List Header End */}

          {/* List Items Start */}
          {returns.map((item, index) => {
            return (
              <Card className="border-default mb-2" key={item.sku}>
                <Card.Body className="pt-0 pb-0 sh-22 sh-md-12" key={item.sku}>
                  <Row className="g-0 h-100 align-content-center cursor-default">
                    <Col xs="12" md="1" className="d-flex flex-column justify-content-center mb-2 mb-md-0 order-3 order-md-2">
                      <div className="text-muted text-small d-md-none">S.No</div>
                      <div className="text-body fw-bold text-medium text-muted text-wrap">{index + 1}</div>
                    </Col>
                    <Col xs="12" md="2" className="d-flex flex-column justify-content-center mb-2 mb-md-0 order-3 order-md-2">
                      <div className="text-muted text-small d-md-none">Image</div>
                      <div className="sw-10 me-1 mb-1 d-inline-block">
                        <img src={item.image} className="img-fluid rounded-md" alt="thumb" />
                      </div>
                    </Col>
                    <Col xs="12" md="2" className="d-flex flex-column justify-content-center mb-2 mb-md-0 order-3 order-md-2">
                      <div className="text-muted text-small d-md-none">Name</div>
                      <div className="text-body fw-bold  text-medium text-muted text-wrap">{item.name}</div>
                    </Col>
                    <Col xs="12" md="2" className="d-flex flex-column justify-content-center mb-2 mb-md-0 order-3 order-md-2">
                      <div className="text-muted text-small d-md-none">Sku</div>
                      <div className="text-body fw-bold text-medium text-muted text-wrap">{item.item_sku}</div>
                    </Col>
                    <Col xs="12" md="1" className="d-flex flex-column justify-content-center mb-2 mb-md-0 order-3 order-md-2">
                      <div className="text-muted text-small d-md-none">Qty</div>
                      <div className="text-body fw-bold text-medium text-muted text-wrap">{item.return_item_qty}</div>
                    </Col>
                    <Col xs="12" md="1" className="d-flex flex-column justify-content-center mb-2 mb-md-0 order-3 order-md-2">
                      <div className="text-muted text-small d-md-none">Price</div>
                      <div className="text-body fw-bold text-medium text-muted text-wrap">{item.now_price}</div>
                    </Col>
                    <Col xs="12" md="1" className="d-flex flex-column justify-content-center mb-2 mb-md-0 order-3 order-md-2">
                      <div className="text-muted text-small d-md-none">Commission (Refund)</div>
                      <div className="text-body fw-bold text-medium text-muted text-wrap">
                        {(item.item_commission / item.ordered_item_qty) * item.return_item_qty}
                      </div>
                    </Col>
                    <Col xs="12" md="1" className="d-flex flex-column justify-content-center mb-2 mb-md-0 order-3 order-md-2">
                      <div className="text-muted text-small d-md-none">Total</div>
                      <div className="text-body fw-bold text-medium text-muted text-wrap">{item.now_price * item.return_item_qty}</div>
                    </Col>
                    <Col xs="12" md="1" className="d-flex flex-column justify-content-center mb-2 mb-md-0 order-3 order-md-2">
                      <div className="text-muted text-small d-md-none">Action</div>
                      <div className="text-body fw-bold text-medium text-muted text-wrap">
                        <Badge bg="badge bg-primary">{renderStatus(item.item_status)}</Badge>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            );
          })}
        </Card.Body>
      </Card>
    </Row>
  );
};
export default ReturnDetails;
