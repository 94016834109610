import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO } from 'config.js';

const initialState = {
  orderStatus:[],
  isOrderLoading: true,
};

const orderStatusSlice = createSlice({
  name: 'orderStatus',
  initialState,
  reducers: {
    setOrderStatus(state, actions) {
      state.isOrderLoading = false;
      state.orderStatus = actions.payload;
    },
  },
});

export const { setOrderStatus } = orderStatusSlice.actions;

const orderStatusReducer = orderStatusSlice.reducer;
export default orderStatusReducer;
