import { gql } from '@apollo/client';
import ApolloClientUploadSellerService from 'ApolloClientUploadSellerService';

const ADD_SUPPORT_TICKET_THREAD = () => {
  return gql`
    mutation newComment($supportTicketThread: SupportTicketThreadInput, $allParticipantsEmails: String) {
      addSupportTicketThread(supportTicketThread: $supportTicketThread, allParticipantsEmails: $allParticipantsEmails)
    }
  `;
};
export const addSupportTicketThread = async ({ variables }) => {
  const result = await ApolloClientUploadSellerService.mutate({ mutation: ADD_SUPPORT_TICKET_THREAD(), variables });
  return result;
};

export const UPDATE_SUPPORT_TICKET = () => gql`
  mutation updateSupportTicket($id: String, $reviewStatus: String) {
    updateSupportTicket(id: $id, reviewStatus: $reviewStatus) {
      _id
    }
  }
`;
