/* eslint-disable jsx-control-statements/jsx-use-if-tag */
/* eslint-disable no-underscore-dangle */
import React, { createRef, useRef, useState } from 'react';
import { Row, Col, Card, Button, Form, Spinner, Badge, Modal } from 'react-bootstrap';

import isEmpty from 'lodash.isempty';

import { displayErrorToast, displayInfoToast, displaySuccessToast } from 'helpers/toaster';

import CsLineIcons from 'cs-line-icons/CsLineIcons';
import ApolloClientUploadSellerService from 'ApolloClientUploadSellerService';
// import {  UPDATE_RATING_REVIEW } from 'views/catalog/actions/sellerActions';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import { GET_SUPPORT_TICKET_LIST } from 'views/catalog/actions/supportTicketActions';
import OrdersViewDetail from 'views/orders/OrdersViewDetail';
import ThreadItem from './ThreadItem';
import { addSupportTicketThread } from './supportActions';
import MediaItem from './MediaItem';
import { GET_SUPPORT_TICKET_THREAD_LIST } from './actions/supportTicketActions';

const SupportTicketsDetail = ({
  typeId = '',
  typeIdentifier = '',

  Activity = () => {
    return <></>;
  },
}) => {
  const { id } = useParams();
  const history = useHistory();
  const actualTypeId = typeId || 'SupportTicket';
  const actualTypeIdentifier = typeIdentifier || id;
  const { data, refetch } = useQuery(GET_SUPPORT_TICKET_THREAD_LIST(), {
    variables: { typeId: actualTypeId, typeIdentifier: actualTypeIdentifier },
    client: ApolloClientUploadSellerService,
  });
  const { listAllSupportTicketThreads = { supportTicketThreads: [] } } = data || {};
  const { data: supportTicketData } = useQuery(GET_SUPPORT_TICKET_LIST(), {
    variables: { typeId: actualTypeId, typeIdentifier: actualTypeIdentifier },
    client: ApolloClientUploadSellerService,
  });
  const { listAllSupportTicket } = supportTicketData || {};

  const [commentInfo, setCommentInfo] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  console.log('🚀 ~ file: DocumentDetails.js:13 ~ DocumentDetails ~ commentInfo', commentInfo);

  // const [updateSupportTicketFn, { data: updateSupportData, error: updateSupportError, loading: supportLoading }] = useMutation(UPDATE_SUPPORT_TICKET(), {
  //   client: ApolloClientUploadSellerService,
  // });
  // useEffect(() => {
  //   if (updateSupportError) displayErrorToast('Failed to update the ticket');
  //   if (updateSupportData?.updateSupportTicket) {
  //     if (updateSupportData?.updateSupportTicket._id) {
  //       displaySuccessToast('Status updated successfully');
  //       supportTicketRefetch();
  //     } else {
  //       displayErrorToast('Failed to update the status');
  //     }
  //   }
  // }, [updateSupportData, updateSupportError]);

  const hiddenFileInput = useRef(createRef());

  const handleUploadFile = () => {
    // hiddenFileInput.current[index].click();
    hiddenFileInput.current.click();
  };

  const submitCommentData = async () => {
    try {
      if (!isEmpty(commentInfo)) {
        const supportTicketThread = {
          typeId: actualTypeId,
          typeIdentifier: actualTypeIdentifier,
        };
        setSubmitting(true);
        const { data: dataUpdate, error } = await addSupportTicketThread({
          variables: {
            supportTicketThread: { ...supportTicketThread, ...commentInfo },
            allParticipantsEmails: listAllSupportTicketThreads?.supportTicketThreads
              ?.filter((t) => t.reviewBy?.isAdmin)
              .map((t) => t?.reviewBy?.email)
              .join(','),
          },
        });
        setSubmitting(false);
        if (!error) {
          if (dataUpdate) {
            displaySuccessToast('Updated Successfully...');
            setCommentInfo({});
            if (id === 'new') {
              history.goBack();
            } else {
              refetch();
            }
          }
        } else {
          displayErrorToast('Something went wrong..');
        }
      } else {
        displayInfoToast('Please add comments or attach files');
      }
    } catch (e) {
      setSubmitting(false);
      displayErrorToast('Something went wrong, please retry again...');
    }
  };

  const onChangeFile = ({ target: { files } }) => {
    const newFile = { file: files?.[0] };
    const newCommentInfo = { ...commentInfo, media: [...(commentInfo.media || []), newFile] };
    console.log('newCommentInfo', newCommentInfo);
    setCommentInfo(newCommentInfo);
  };

  const onInputChanged = (event) => {
    const targetName = event.target.name;
    const targetValue = String(event.target.value);

    setCommentInfo({ ...commentInfo, [targetName]: targetValue });
  };
  const removeMediaItem = (i) => {
    const newCommentInfo = { ...commentInfo };
    newCommentInfo.media = newCommentInfo.media.splice(newCommentInfo.media, i);
    setCommentInfo(newCommentInfo);
  };

  // const handleStatusChange = (e) => {
  //   updateSupportTicketFn({ variables: { id, reviewStatus: e.target.value } });
  // };

  const getBadge = (status) => {
    if (status === 'opened') {
      return 'OPENED';
    }
    if (status === 'resolved') {
      return 'RESOLVED';
    }
    if (status === 'closed') {
      return 'CLOSED';
    }
    return status?.toUpperCase();
  };

  const statusHistory = [...(listAllSupportTicket?.supportTicketThreads?.[0]?.history || [])];
  const [showOrderModal, setShowOrderModal] = useState();
  return (
    <>
      <Modal className="modal-right x2-large" show={!!showOrderModal} onHide={() => setShowOrderModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">#{showOrderModal}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OrdersViewDetail id={showOrderModal} />
        </Modal.Body>
      </Modal>
      <Col className="">
        {listAllSupportTicket?.supportTicketThreads[0] && (
          <div className="page-title-container mb-3">
            <Row>
              <Col lg="6" className="mb-2">
                <h1 className="mb-2 pb-0 display-4">
                  {listAllSupportTicket?.supportTicketThreads[0]?.title} [{listAllSupportTicket?.supportTicketThreads[0]?.ticketId}]
                  <div className="text-decoration-underline text-link fw-bold  text-too-small mt-2 cursor-pointer">
                    {listAllSupportTicket?.supportTicketThreads[0]?.ticketId?.indexOf('BB-') > -1 ? (
                      <span
                        onClick={() => {
                          setShowOrderModal(listAllSupportTicket?.supportTicketThreads[0]?.ticketId);
                        }}
                      >
                        View Order
                      </span>
                    ) : null}
                  </div>
                </h1>
                <div className="font-heading">{listAllSupportTicket?.supportTicketThreads[0]?.review}</div>
                <If condition={listAllSupportTicket?.supportTicketThreads[0]?.media?.length}>
                  <div className="mt-4 d-flex flex-row flex-wrap">
                    {listAllSupportTicket?.supportTicketThreads[0].media?.map((media) => {
                      return <MediaItem media={media} key={media.name} />;
                    })}
                  </div>
                </If>
              </Col>
              <Col lg="2">
                <Badge bg="outline-primary">{getBadge(listAllSupportTicket.supportTicketThreads[0]?.reviewStatus)}</Badge>
              </Col>
            </Row>
          </div>
        )}
        {/* Title Start */}
        <div className="page-title-container mb-3">
          <Row>
            <Col xxl="8" className="mb-5 mb-xxl-0">
              {/* Ticket Details Start */}
              <If condition={!id}>
                <h2 className="small-title">Conversations</h2>
              </If>
              <If condition={id === 'new'}>
                <h2 className="cta-2">Create New Ticket</h2>
              </If>
              <If condition={listAllSupportTicket?.supportTicketThreads?.[0]?.reviewStatus?.toUpperCase() !== 'RESOLVED'}>
                {/* Ticket Answer Start */}
                <Card className="mb-2">
                  <Card.Body>
                    <If condition={actualTypeIdentifier === 'new'}>
                      <div className="mb-3 filled">
                        <CsLineIcons icon="notebook-1" />
                        <Form.Control placeholder="Summary" name="title" onChange={onInputChanged} value={commentInfo.title || ''} />
                      </div>
                    </If>
                    <div className="mb-3 filled">
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="Comments"
                        style={{ resize: 'vertical' }}
                        name="review"
                        onChange={onInputChanged}
                        value={commentInfo.review || ''}
                      />
                    </div>
                    <Button variant="outline-primary" className="btn-icon btn-icon-end" onClick={submitCommentData} disabled={isSubmitting}>
                      {isSubmitting && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                      <span>Send</span> <CsLineIcons icon="send" />
                    </Button>
                    <Button variant="outline-primary" className=" btn-icon btn-icon-only ms-1" onClick={() => handleUploadFile()}>
                      <input type="file" className="d-none" onChange={(e) => onChangeFile(e)} ref={hiddenFileInput} />
                      <CsLineIcons icon="attachment" />
                    </Button>
                    <div>
                      <div className="mt-4 d-flex flex-row flex-wrap">
                        {commentInfo?.media?.map((media, i) => {
                          return <MediaItem media={media} key={media.filename} removeMediaItem={() => removeMediaItem(i)} />;
                        })}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </If>
              {/* Ticket Answer End */}{' '}
              <If condition={listAllSupportTicketThreads?.supportTicketThreads?.length}>
                <Card className="mb-2">
                  <Card.Body>
                    {listAllSupportTicketThreads?.supportTicketThreads?.map((item) => {
                      const { _id: itemId } = item;
                      return <ThreadItem item={item} key={itemId} />;
                    })}
                  </Card.Body>
                </Card>
              </If>
              {/* Ticket Details End */}
            </Col>

            <Activity />
            <Col xxl="4">
              <Card className="mb-5">
                <Card.Body>
                  {statusHistory?.map((his) => {
                    return (
                      <Row className="g-0" key={his.statsDate}>
                        <Col xs="auto" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative me-4">
                          <div className="w-100 d-flex sh-1" />
                          <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                            <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                          </div>
                          <div className="w-100 d-flex h-100 justify-content-center position-relative">
                            <div className="line-w-1 bg-separator h-100 position-absolute" />
                          </div>
                        </Col>
                        <Col className="mb-4">
                          <div className="h-100">
                            <div className="d-flex flex-column justify-content-start">
                              <div className="d-flex flex-column">
                                {his.status?.toUpperCase()}
                                <br /> <span className="text-muted text-small">{moment(Number(his.statusDate)).fromNow()}</span>{' '}
                                <span className="text-muted text-small"> By {his.name || ''}</span>{' '}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
        {/* Title End */}
      </Col>
    </>
  );
};

export default SupportTicketsDetail;
