import { gql } from '@apollo/client';

export const CHECK_SELLER_ON_BOARDED = () => gql`
  query checkSellerOnBoarded {
    checkSellerOnBoarded {
      onBoarded
      isActive
      timestamp
    }
  }
`;

export const GET_RATINGS_AND_REVIEWS_LIST = () => gql`
  query listAllRatingReviews($typeIdentifier: String, $typeId: String, $page: Int, $search: String, $date: Date, $showAll: Boolean) {
    listAllRatingReviews(typeIdentifier: $typeIdentifier, typeId: $typeId, page: $page, search: $search, date: $date, showAll: $showAll) {
      ratingReviews {
        _id
        rating
        title
        itemInfo {
          itemId {
            _id
            fields {
              name {
                lang {
                  ar
                  en
                }
              }
              url_key {
                lang {
                  ar
                  en
                }
              }
            }
            media_gallery {
              value
            }
          }
          sameItem
        }
        review
        reviewBy {
          name
        }
        reviewDate
        media {
          name
          url
          type
        }
        reviewStatus
        reportReason
        rejectReason
      }
      ratingAverage
      totalItems
    }
  }
`;

export const UPDATE_RATING_REVIEW = () => gql`
  mutation updateRatingReview($_id: String, $reviewStatus: String, $reason: String) {
    updateRatingReview(_id: $_id, reviewStatus: $reviewStatus, reason: $reason) {
      _id
    }
  }
`;
