import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO } from 'config.js';

const initialState = {
  orderDetail: [],
  isOrderLoading: true,
};

const orderDetailSlice = createSlice({
  name: 'orderDetail',
  initialState,
  reducers: {
    setOrderDetail(state, actions) {
      state.isOrderLoading = false;
      state.orderDetail = actions.payload;
    },
    setOrderDetailV2(state, actions) {
      state.isOrderLoading = false;
      state.orderDetail = actions.payload;
    },
  },
});

export const { setOrderDetail } = orderDetailSlice.actions;

const orderDetailReducer = orderDetailSlice.reducer;
export default orderDetailReducer;
