import { getHeaders } from 'auth/utils';
import axios from 'axios';
import { OMS_API } from 'constants/index';
import { setReportStatus } from 'reportSlice/reportStatus';
import ApolloClientSellerService from 'ApolloClientSellerService';
import { gql } from '@apollo/client';

const apiUrl = OMS_API.BASE_URL;
// const token = 'RGFudWJlQXBpOkRhbnViZUAyMDIyJA==';

const ORDER_REPORT_GQL = gql`
  query orderReport($reportType: String, $startDate: Date, $endDate: Date) {
    orderReport(reportType: $reportType, startDate: $startDate, endDate: $endDate) {
      success
      message
      path
    }
  }
`;
export const getreportStatusCounts = async ({ formData, dispatch }) => {
  const config = await getHeaders();
  return axios
    .post(`${apiUrl}/order/report`, formData, { ...config, headers: { ...config.headers, 'Content-Type': 'multipart/form-data' } })
    .then((response) => {
      dispatch(setReportStatus(response?.data));
      window.location.href = response?.data?.path;
      console.log(setReportStatus);
      console.log(dispatch);
      console.log(response);
    })
    .catch((error) => {
      throw error;
    });
};

export const getOrderReport = async ({ params, dispatch }) => {
  const results = await ApolloClientSellerService.query({ query: ORDER_REPORT_GQL, variables: { ...params }, fetchPolicy: 'network-only' });
  console.log('results', results);
  dispatch(setReportStatus(results?.data));
  if (results?.data?.orderReport?.path && results?.data?.orderReport?.path !== '') {
    window.location.href = `${process.env.SELLER_HUB_SELLER_GRAPHQL_API}${results?.data?.orderReport?.path}`;
  }
};
