import CsLineIcons from 'cs-line-icons/CsLineIcons';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

export default ({ media, removeMediaItem }) => {
  const handleDownload = (url) => {
    console.log('🚀 ~ file: MediaItem.js:7 ~ handleDownload ~ url:', url);
    if (url) {
      window.open(`https:${url}`);
    }
  };

  return (
    <div className="sw-30 me-2 mb-2">
      <Row className="g-0 rounded-sm sh-8 border">
        <Col xs="auto">
          <div className="sw-10 d-flex justify-content-center align-items-center h-100">
            <CsLineIcons icon="file-text" className="text-primary" />
          </div>
        </Col>
        <Col className="rounded-sm-end d-flex flex-column justify-content-center pe-3">
          <div className="d-flex justify-content-between">
            <p className="mb-0 clamp-line" data-line="1">
              {media?.file?.name || media.name}
            </p>
            <span to="#">
              <If condition={media?.file?.size}>
                <span onClick={removeMediaItem}>
                  <CsLineIcons icon="close" size="17" className="alternate-link" onClick={removeMediaItem} />
                </span>
              </If>
              <If condition={!media?.file?.size}>
                <span onClick={() => handleDownload(media?.url)} className="cursor-pointer">
                  <CsLineIcons icon="download" size="17" className="alternate-link" />
                </span>
              </If>
            </span>
          </div>
          <div className="text-small text-primary">{media?.file?.size || media?.size}</div>
        </Col>
      </Row>
    </div>
  );
};
