/* eslint-disable no-debugger */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useMemo } from 'react';

// import redux for auth guard
import { useDispatch, useSelector } from 'react-redux';

// import layout
import Layout from 'layout/Layout';

// import routing modules
import RouteIdentifier from 'routing/components/RouteIdentifier';
import { getRoutes } from 'routing/helper';
import routesAndMenuItems from 'routes.js';
import LoadingPersist from 'components/loading/LoadingPersist';
import { useHistory, useLocation } from 'react-router-dom';

// import { setCurrentUser, setLogout } from 'auth/authSlice';
// import jwtDecode from 'jwt-decode';

import { DEFAULT_PATHS } from 'config';
// import { auth } from 'helpers/Firebase';
// import { onAuthStateChanged } from 'firebase/auth';
import { initialAuthStateChanged } from 'auth/utils';
import { onAuthStateChanged } from 'firebase/auth';
import { setLogin, setLogout, setUserPrivilege } from 'auth/authSlice';
import { store } from 'store';
import { auth, dhGetAuth, getStartToken } from 'helpers/Firebase';
import { useMutation } from '@apollo/client';
import { EDIT_USER } from 'views/catalog/actions/userActions';
import ApolloClientSellerService from 'ApolloClientSellerService';
import { getPrivilege } from 'constants/privilege';
import { displayErrorToast } from 'helpers/toaster';

const App = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentUser, isLogin, isLoginLoading, emailVerificationSuccessful = false } = useSelector((state) => state.auth);
  console.log('🚀 ~ file: App.js:37 ~ App ~ currentUser', currentUser);

  const { pathname } = useLocation();
  useEffect(() => {
    const privilegeInfo = getPrivilege(currentUser?.isActive, pathname);
    dispatch(setUserPrivilege(privilegeInfo));
  }, [pathname, currentUser]);

  useEffect(() => {
    if (!isLogin && !isLoginLoading) {
      history.push(DEFAULT_PATHS.LOGIN);
    }
    if (isLogin) {
      window.__onbaordingTimer = setInterval(() => {
        dhGetAuth()
          .currentUser.getIdToken()
          .then((d) => {
            if (!d) {
              dispatch(setLogout());
              displayErrorToast('Your session has been timed out or logined from different system. Kindly login again', 6000);
              setTimeout(() => {
                clearInterval(window.__onbaordingTimer);
                window.location.href = '/login';
              }, 6000);
            } else {
              window.localStorage.authToken = d;
            }
          });
      }, 1000 * 60 * 20);
    }
  }, [isLogin]);

  useEffect(() => {
    if (!isLogin && !isLoginLoading) {
      history.replace(DEFAULT_PATHS.LOGIN);
    } else if (isLogin && !isLoginLoading && !emailVerificationSuccessful) {
      history.replace(DEFAULT_PATHS.EMAIL_VERIFICATION_WAITING);
    }
  }, [isLogin, isLoginLoading, emailVerificationSuccessful]);

  const [editUserFn] = useMutation(EDIT_USER(), { client: ApolloClientSellerService });

  useEffect(() => {
    initialAuthStateChanged({ dispatch, history, isApp: true, isLoginLoading });

    // Push Notification Token Generation
    try {
      // Push Notification Token Generation
      if (typeof Notification !== 'undefined') {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            (async () => {
              const token = await getStartToken();
              if (token) {
                editUserFn({
                  variables: { _id: currentUser._id, token },
                });
              }
              console.log('🚀 ~ file: App.js:66 ~ currentUser', currentUser);
              console.log('🚀 ~ file: App.js:40 ~ token', token);
            })();
          }
        });
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  // function refresh() {
  //   // console.log(auth);
  //   // if (dhGetAuth.currentUser) {
  //   //   dhGetAuth.currentUser.getIdToken(true).then((token) => {
  //   //     console.log(token);
  //   //     // setCurrentUser((user) => ({ ...user, accessToken: token }));
  //   //   });
  //   // }
  //   onAuthStateChanged(auth, (user) => {
  //     console.log('🚀 ~ file: App.js ~ line 53 ~ onAuthStateChanged ~ user', user);
  //   });
  // }
  // rlxCWhsRmJrD9OSKaDsltF9WDTrrMU3w
  // let tokenData;
  // if (token) tokenData = jwtDecode(token);

  // console.log('🚀 ~ file: App.js ~ line 63 ~ App ~ tokenData', tokenData.exp, Math.floor(Date.now() / 1000));
  // if (tokenData.exp <= Math.floor(Date.now() / 1000)) {
  //   onAuthStateChanged(auth, (user) => {
  //     console.log('🚀 ~ file: utils.js ~ line 16 ~ onAuthStateChanged ~ user', user.accessToken);
  //     if (user) {
  //       // const { uid, name, email, emailVerified } = user;

  //       store.dispatch(
  //         setLogin({
  //           token: user.accessToken,
  //         })
  //       );
  //     }
  //   });
  // }

  // let time = tokenData?.exp - Math.floor(Date.now() / 1000);

  useEffect(() => {
    initialAuthStateChanged({ isApp: true });
    // const tokenRefreshInterval = setInterval(() => {
    // initialAuthStateChanged();

    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const tokens = await user?.getIdToken();
        store.dispatch(
          setLogin({
            token: tokens,
          })
        );
        // time = 3600;
      }
    });
    console.log('REFRESHING TOKEN');
    // }, (1 + time) * 1000);
    // return () => clearInterval(tokenRefreshInterval);
  }, []);

  const routes = useMemo(() => getRoutes({ data: routesAndMenuItems(), isLogin, userRole: currentUser.role }), [isLogin, currentUser]);
  if (routes && isLogin && emailVerificationSuccessful) {
    return (
      <Layout>
        <RouteIdentifier routes={routes} isActive={currentUser?.isActive || ''} />
        <LoadingPersist isLoading={isLoginLoading} />
      </Layout>
    );
  }
  // if (isLoginLoading) {
  return <LoadingPersist isLoading={isLoginLoading} />;
  // }
  //  return <></>;
};

export default App;
