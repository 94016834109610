import React, { useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import LayoutFullpage from 'layout/LayoutFullpage';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import HtmlHead from 'components/html-head/HtmlHead';
import { GET_USER_DETAIL } from 'views/catalog/actions/userActions';
import { useQuery } from '@apollo/client';
import ApolloClientSellerService from 'ApolloClientSellerService';
import { useDispatch, useSelector } from 'react-redux';
import { auth } from 'helpers/Firebase';
import { setCurrentUser, setLogout } from 'auth/authSlice';
import { DEFAULT_PATHS } from 'config';
// import { DEFAULT_PATHS } from 'config';

const NotFound = () => {
  const title = 'Awaiting';
  const description = 'Awaiting...';
  const { currentUser } = useSelector((state) => state.auth);
  // const history = useHistory();

  const { data: userDetailData } = useQuery(GET_USER_DETAIL(), {
    client: ApolloClientSellerService,
    fetchPolicy: 'no-cache',
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const handleLogout = () => {
    window.localStorage.authToken = null;
    auth.signOut();
    dispatch(setLogout());
    setTimeout(() => {
      history.replace(DEFAULT_PATHS.APP);
    }, 1000);
  };
  useEffect(() => {
    if (userDetailData?.getUserDetail && userDetailData?.getUserDetail?.sellers) {
      if (userDetailData?.getUserDetail?.sellers?.[0] && userDetailData?.getUserDetail?.sellers?.[0]?.isActive !== 4) {
        dispatch(
          setCurrentUser({
            ...currentUser,
            isActive: userDetailData?.getUserDetail?.sellers?.[0]?.isActive,
            sellerName: userDetailData?.getUserDetail?.sellers?.[0]?.business?.name,
          })
        );
        if (
          userDetailData?.getUserDetail?.sellers?.[0]?.isActive === 2 ||
          userDetailData?.getUserDetail?.sellers?.[0]?.isActive === 3 ||
          userDetailData?.getUserDetail?.sellers?.[0]?.isActive === 5
        ) {
          setTimeout(() => {
            history.push(DEFAULT_PATHS.APP);
          }, 100);
        }
      }
    }
  }, [userDetailData]);

  const rightSide = (
    <div className="sw-lg-80 min-h-100 bg-foreground d-flex justify-content-center align-items-center shadow-deep py-5 full-page-content-right-border">
      <div className="sw-lg-60 px-5">
        <If condition={userDetailData?.getUserDetail?.sellers?.[0]?.isActive === 4}>
          <div className="mb-5">
            <h2 className="cta-1 mb-0 text-primary">Your documents are being verified </h2>
            <h2 className="display-2 text-primary">Document Verification</h2>
          </div>
          <div className="mb-5">
            <p className="h6">
              Our team is verifying your documents, we will inform you via email once your documents are verified and ready to use the account
            </p>
          </div>
          <div>
            <NavLink to="/" className="btn btn-icon btn-foreground">
              <span>Check the status now</span>
            </NavLink>
          </div>
        </If>
        <If condition={userDetailData?.getUserDetail?.sellers?.[0]?.isActive !== 4}>
          <div className="mb-5">
            <h2 className="cta-1 mb-0 text-primary">Ooops, it looks like an error!</h2>
            <h2 className="display-2 text-primary">404 Not Found</h2>
          </div>
          <div className="mb-5">
            <p className="h6">It looks like the page you are looking for is not available.</p>
          </div>
          <div>
            <NavLink to="/" className="btn btn-icon btn-icon-start btn-primary">
              <CsLineIcons icon="arrow-left" /> <span>Back to Home</span>
            </NavLink>
            <NavLink to="#" className="btn btn-icon btn-icon-start btn-outline-info ml-2" onClick={handleLogout}>
              <span>Logout</span>
            </NavLink>
          </div>
        </If>
      </div>
    </div>
  );

  return (
    <>
      <HtmlHead title={title} description={description} />
      <LayoutFullpage right={rightSide} />
    </>
  );
};

export default NotFound;
