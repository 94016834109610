import ApolloClientSellerService from 'ApolloClientSellerService';
import { gql } from '@apollo/client';
import { setStatementList } from 'statementSlice';
import { getHeaders } from 'auth/utils';
import axios from 'axios';

// const GENERATE_SOA_GQL = gql`
//   query generateSellerSOA($start: Int, $limit: Int, $search: String, $orderByField: String, $order: String) {
//     generateSellerSOA(start: $start, limit: $limit, search: $search, orderByField: $orderByField, order: $order) {
//       sellerSOA
//     }
//   }
// `;

const ACKNOWLEDGE_PAYMENT_RECEIPT_GQL = gql`
  mutation acknowledgePaymentReceipt($statementPeriodId: String) {
    acknowledgePaymentReceipt(statementPeriodId: $statementPeriodId) {
      success
      message
    }
  }
`;

const STATEMENT_LIST_GQL = gql`
  query statementList($start: Int, $limit: Int, $search: String, $orderByField: String, $order: String) {
    statementList(start: $start, limit: $limit, search: $search, orderByField: $orderByField, order: $order) {
      list {
        si
        statement_id
        statement_period_id
        statement_period_from_date
        statement_period_end_date
        order_count
        isCurrentStatement
        statusBadge
        sales
        refunds
        commission
        shipping_charge
        total_payout
        payment_processed_on
        payment_received_on
      }
      count {
        totalPages
      }
    }
  }
`;

const STATEMENT_ORDER_TRANSACTIONS_GQL = gql`
  query statementOrderTransactions(
    $statementPeriodId: String
    $start: Int
    $limit: Int
    $search: String
    $orderByField: String
    $order: String
    $seller_id: Int
    $download: String
  ) {
    statementOrderTransactions(
      statementPeriodId: $statementPeriodId
      start: $start
      limit: $limit
      search: $search
      orderByField: $orderByField
      order: $order
      seller_id: $seller_id
      download: $download
    ) {
      list {
        si
        order_creation_date
        entity_id
        reference_no
        statement_id
        statement_period_id
        statement_period_from_date
        statement_period_end_date
        order_count
        isCurrentStatement
        statusBadge
        sales
        refunds
        ads_platform

        commission
        commission_reversed
        shipping_charge
        total_payout
        isSummary
        po_number
        po_return_number
        invoice_no
        delivery_invoice_no
        awb_no
        delivery_proof
        delivery_completed_at
        payment_invoice_doc
        invoice_date
        credit_note_url
        credit_note_number
        credit_note_date

        payable_team_verified
        payable_team_verified_on
        payable_team_verified_by
        payable_team_comments

        audit_team_verified
        audit_team_verified_on
        audit_team_verified_by
        audit_team_comments

        accounts_team_verified
        accounts_team_verified_on
        accounts_team_verified_by
        accounts_team_comments
      }
      count {
        totalPages
      }
      summary {
        auditProgress
        payableProgress
        approved_by_audit_team_member_name
        approved_by_payment_team_member_name
        approved_by_audit_team_on
        approved_by_payment_team_on
        payment_team_approval_doc
        audit_team_approval_doc
        payment_processed_on
        payment_processed_doc
        payment_received_on
        soa_doc
        excelLink
      }
    }
  }
`;

export const fetchAllStatements = async ({ params, dispatch }) => {
  const results = await ApolloClientSellerService.query({ query: STATEMENT_LIST_GQL, variables: { ...params }, fetchPolicy: 'network-only' });
  console.log('results', results);
  if (results) dispatch(setStatementList(results?.data));
};

export const fetchAllStatementsOrderTrasnaction = async ({ params }) => {
  const results = await ApolloClientSellerService.query({ query: STATEMENT_ORDER_TRANSACTIONS_GQL, variables: { ...params }, fetchPolicy: 'network-only' });
  console.log('results', results);
  return results.data;
  // if (results) dispatch(setStatementList(results?.data));
};

export const generateSellerSOA = async (params) => {
  const config = await getHeaders();
  const result = await axios
    .post(`${process.env.SELLER_HUB_SELLER_GRAPHQL_API}/generate-soa`, { ...params }, config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
  return result;
};

// export const generateSellerSOA = async ({ params }) => {
//   const results = await ApolloClientSellerService.query({ query: GENERATE_SOA_GQL, variables: { ...params }, fetchPolicy: 'network-only' });
//   console.log('results', results);
//   // if (results) dispatch(setStatementList(results?.data));
// };

export const acknowledgePaymentReceipt = async ({ params }) => {
  const results = await ApolloClientSellerService.mutate({ mutation: ACKNOWLEDGE_PAYMENT_RECEIPT_GQL, variables: { ...params }, fetchPolicy: 'network-only' });
  console.log('results', results);
  return results.data;
};
