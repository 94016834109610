/* eslint-disable no-underscore-dangle */
import { createSlice } from '@reduxjs/toolkit';
// import { format } from 'date-fns';

// import { DEFAULT_USER, IS_DEMO } from 'config.js';

const initialState = {
  list: [],
  unread: 0,
  isLoading: true,
};

const messageSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setMessageList(state, actions) {
      state.isLoading = false;
      state.list = actions.payload?.listOrders;
      const messageList = actions.payload?.listOrders?.orderList || [];
      const unreadList = messageList.filter((item) => item.is_replied === '0' && item.created_by === 'customer');
      state.unread = unreadList?.length || 0;
    },
    setSupportTicketListResult(state, actions) {
      state.supportTicketListResult = actions.payload;
      state.supportTicketOpenStatus = actions.payload?.statuses?.filter((st) => {
        if ((st._id === 'opened' || st._id === 'reopened' || st._id === 'esclated') && st.replyPendingFromSellerCount) {
          return true;
        }
        return false;
      });
      // console.log('actions.payload', actions.payload);
      state.supportTicketsUnreadList = actions.payload?.supportTicketThreads?.filter((st) => {
        if ((st.reviewStatus === 'opened' || st.reviewStatus === 'reopened' || st.reviewStatus === 'esclated') && st.replyPendingFromSeller) {
          return true;
        }
        return false;
      });
      state.supportTicketOpenStatusCount = state.supportTicketOpenStatus?.length;
      state.supportTicketsUnreadListCount = state.supportTicketOpenStatus?.length;
    },
  },
});

export const { setMessageList, setSupportTicketListResult } = messageSlice.actions;
const messageReducer = messageSlice.reducer;

export default messageReducer;
