import { map } from 'lodash';

const allPermissions = {
  // Active
  '/': { add: 1, delete: 1, update: 1 },
  '/dashboard': { add: 1, delete: 1, update: 1 },
  '/dashboard/getting-started': { add: 1, delete: 1, update: 1 },
  '/dashboard/messages': { add: 1, delete: 1, update: 1 },
  '/dashboard/messages/detail/': { add: 1, delete: 1, update: 1 },
  '/dashboard/sales-analysis': { add: 1, delete: 1, update: 1 },
  '/dashboard/product-analysis': { add: 1, delete: 1, update: 1 },
  '/dashboard/customer-messages': { add: 1, delete: 1, update: 1 },
  '/dashboard/ratings-and-reviews': { add: 1, delete: 1, update: 1 },
  '//seller-academy.danubehome.com/en': { add: 1, delete: 1, update: 1 },
  '/dashboard/delivery-track-sheet': { add: 1, delete: 1, update: 1 },
  '/dashboard/account-health': { add: 1, delete: 1, update: 1 },
  '/catalog': { add: 1, delete: 1, update: 1 },
  '/catalog/partner': { add: 1, delete: 1, update: 1 },
  '/catalog/partner/add': { add: 1, delete: 1, update: 1 },
  '/catalog/partner/detail/': { add: 1, delete: 1, update: 1 },
  '/catalog/inventory': { add: 1, delete: 1, update: 1 },
  '/catalog/offers': { add: 1, delete: 1, update: 1 },
  '/catalog/offers/detail/': { add: 1, delete: 1, update: 1 },
  '/catalog/offers/create': { add: 1, delete: 1, update: 1 },
  '/catalog/import': { add: 1, delete: 1, update: 1 },
  '/catalog/import-history': { add: 1, delete: 1, update: 1 },
  '/catalog/audit-logs': { add: 1, delete: 1, update: 1 },
  '/catalog/ratings-and-reviews': { add: 1, delete: 1, update: 1 },
  '/catalog/question-and-answer': { add: 1, delete: 1, update: 1 },
  '/all-catalog': { add: 1, delete: 1, update: 1 },
  '/all-catalog/partner': { add: 1, delete: 1, update: 1 },
  '/all-catalog/partner/add': { add: 1, delete: 1, update: 1 },
  '/all-catalog/partner/detail/': { add: 1, delete: 1, update: 1 },
  '/all-catalog/brands': { add: 1, delete: 1, update: 1 },
  '/orders': { add: 1, delete: 1, update: 1 },
  '/orders/views/all': { add: 1, delete: 1, update: 1 },
  '/orders/views/pending': { add: 1, delete: 1, update: 1 },
  '/orders/views/packing': { add: 1, delete: 1, update: 1 },
  '/orders/views/shipping_pending': { add: 1, delete: 1, update: 1 },
  '/orders/views/shipped': { add: 1, delete: 1, update: 1 },
  '/orders/views/delivered': { add: 1, delete: 1, update: 1 },
  '/orders/views/out_for_delivery': { add: 1, delete: 1, update: 1 },
  '/orders/views/undelivered': { add: 1, delete: 1, update: 1 },
  '/orders/views/invoice_pending': { add: 1, delete: 1, update: 1 },
  '/orders/views/completed': { add: 1, delete: 1, update: 1 },
  '/orders/views/rejected': { add: 1, delete: 1, update: 1 },
  '/orders/views/cancelled': { add: 1, delete: 1, update: 1 },
  '/orders/detail/': { add: 1, delete: 1, update: 1 },
  '/returns': { add: 1, delete: 1, update: 1 },
  '/returns/returns': { add: 1, delete: 1, update: 1 },
  '/returns/detail/': { add: 1, delete: 1, update: 1 },
  '/statements': { add: 1, delete: 1, update: 1 },
  '/statements/overview': { add: 1, delete: 1, update: 1 },
  '/statements/transactions': { add: 1, delete: 1, update: 1 },
  '/statements/all': { add: 1, delete: 1, update: 1 },
  '/statements/transactions/detail/': { add: 1, delete: 1, update: 1 },
  '/account': { add: 1, delete: 1, update: 1 },
  '/account/settings/business': { add: 1, delete: 1, update: 1 },
  '/account/settings/bank': { add: 1, delete: 1, update: 1 },
  '/account/settings/documents': { add: 1, delete: 1, update: 1 },
  '/account/settings/warehouse': { add: 1, delete: 1, update: 1 },
  '/account/settings/shipping': { add: 1, delete: 1, update: 1 },
  '/account/settings/costs': { add: 1, delete: 1, update: 1 },
  '/account/settings/notification': { add: 1, delete: 1, update: 1 },
  '/account/settings/store-page': { add: 1, delete: 1, update: 1 },
  '/account/settings/advancedShippingSettings': { add: 1, delete: 1, update: 1 },
  '/account/users': { add: 1, delete: 1, update: 1 },
  '/account/ratings-and-reviews': { add: 1, delete: 1, update: 1 },
  '/account/settings/api': { add: 1, delete: 1, update: 1 },
  '/reports': { add: 1, delete: 1, update: 1 },
  '/marketing': { add: 1, delete: 1, update: 1 },
  '/marketing/promotions': { add: 1, delete: 1, update: 1 },
  '/marketing/promotions/detail/': { add: 1, delete: 1, update: 1 },
  '/marketing/banners': { add: 1, delete: 1, update: 1 },
  '/marketing/campaigns': { add: 1, delete: 1, update: 1 },
  '/marketing/campaigns/detail/': { add: 1, delete: 1, update: 1 },

  '/marketing/search': { add: 1, delete: 1, update: 1 },
  '/messages': { add: 1, delete: 1, update: 1 },
  '/penalty': { add: 1, delete: 1, update: 1 },
  '/penalty/penalties': { add: 1, delete: 1, update: 1 },
  '/penalty/list': { add: 1, delete: 1, update: 1 },
};
const catalogSuspended = { ...allPermissions };
map(allPermissions, (perm, key) => {
  const newPerm = perm;
  if (key.indexOf('catalog') > -1) {
    perm = { ...newPerm, add: 0, delete: 0, update: 0 };
  }
  catalogSuspended[key] = perm;
});
const temporarilySuspended = { ...allPermissions };
map(allPermissions, (perm, key) => {
  const newPerm = perm;

  perm = { ...newPerm, add: 0, delete: 0, update: 0 };

  temporarilySuspended[key] = perm;
});
export const ACL = {
  1: {
    // Permanently Blocked
    '/': { add: 1, delete: 1, update: 1 },
  },
  2: {
    ...allPermissions,
  },
  3: temporarilySuspended,
  4: {
    // Onboarding Verification
    '/': { add: 1, delete: 1, update: 1 },
  },
  5: catalogSuspended,
};

export const getPrivilege = (isActive, routePath) => {
  if (isActive) {
    let modifiedRoute = routePath;

    if (routePath.includes('/detail/')) {
      const routeSplit = routePath.split('/');
      routeSplit.pop();
      modifiedRoute = `${routeSplit.join('/')}/`;
    }
    const privilegeKeys = Object.keys(ACL[isActive] || {});
    const privilegeIndex = privilegeKeys.find((p) => p === modifiedRoute);
    const privilege = ACL[isActive]?.[privilegeIndex];
    if (privilege) {
      return privilege;
    }
    return false;
  }
  return false;
};

export const enablePrivilege = (privilege, type) => {
  if (privilege && Object.keys(privilege)?.length > 0) {
    if (privilege[type]) {
      return true;
    }
    return false;
  }
  return false;
};
