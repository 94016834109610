import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO } from 'config.js';

const initialState = {
  orderCounts:[],
  isOrderLoading: true,
};

const orderCountsSlice = createSlice({
  name: 'orderCounts',
  initialState,
  reducers: {
    setOrderCounts(state, actions) {
      state.isOrderLoading = false;
      state.orderCounts = actions.payload;
    },
  },
});

export const { setOrderCounts } = orderCountsSlice.actions;

const orderCountsReducer = orderCountsSlice.reducer;
export default orderCountsReducer;
