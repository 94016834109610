import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
// import { dhGetAuth } from 'helpers/Firebase';
import { createUploadLink } from 'apollo-upload-client';
import { store } from './store';

const httpLink = createUploadLink({
  uri: (req) => {
    return `${process.env.SELLER_HUB_SELLER_GRAPHQL_API}/gql?operationName=${req.operationName}`;
  },
});

const authLink = setContext((_, { headers }) => {
  // it will always get unexpired version of the token
  // return dhGetAuth()
  //   .currentUser.getIdToken()
  //   .then((token) => {
  //     return {
  //       headers: {
  //         ...headers,
  //         authorization: token ? `Bearer ${token}` : '',
  //       },
  //     };
  //   });
  return {
    headers: {
      ...headers,
      authorization: store.getState().auth.token ? `Bearer ${store.getState().auth.token}` : '',
    },
  };
});
// const link = ApolloLink.from([authLink, ...{/**ur other links */}])
const link = ApolloLink.from([authLink, httpLink]);

const ApolloClientUploadSellerService = new ApolloClient({
  link,
  // uri: `${process.env.SELLER_HUB_CATALOG_SERVICE}/graphql`,
  cache: new InMemoryCache({
    addTypename: false,
  }),
});
export default ApolloClientUploadSellerService;
