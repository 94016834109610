import { createSlice } from '@reduxjs/toolkit';
// import { DEFAULT_USER, IS_DEMO } from 'config.js';

const initialState = {
  orderAWBdownload:[],
  isAWBLoading: true,
};

const downloadAWBSlice = createSlice({
  name: 'downloadAWB',
  initialState,
  reducers: {
    setAWBdownload(state, actions) {
      state.isAWBLoading = false;
      state.orderAWBdownload= actions.payload;
    },
  },
});

export const {  setAWBdownload } = downloadAWBSlice.actions;

const downloadAWBReducer = downloadAWBSlice.reducer;
export default downloadAWBReducer;
