// cra imports
import React, { useMemo } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from 'reportWebVitals.js';
import { QueryParamProvider } from 'use-query-params';

import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { onError } from '@apollo/client/link/error';
// import redux requirements
import { Provider } from 'react-redux';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import { store, persistedStore } from 'store.js';

// import html head tags requirements
import { Helmet } from 'react-helmet';
import { REACT_HELMET_PROPS } from 'config.js';

// import multi language
import LangProvider from 'lang/LangProvider';

// import routing modules
import { BrowserRouter as Router } from 'react-router-dom';
import RouteIdentifier from 'routing/components/RouteIdentifier';

// import routes
import { getLayoutlessRoutes } from 'routing/helper';
import defaultRoutes from 'routing/default-routes';
import routesAndMenuItems from 'routes.js';

// import toastify for notification
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// mock server register for demo
import '@mock-api';
import { dhGetAuth } from 'helpers/Firebase';
// import { dhGetAuth } from 'helpers/Firebase';

const httpLink = createHttpLink({
  uri: (req) => {
    return `${process.env.SELLER_HUB_CATALOG_GRAPHQL_API}/graphql?operationName=${req.operationName}`;
  },
});
const authLink = setContext((_, { headers }) => {
  // it will always get unexpired version of the token
  try {
    return dhGetAuth()
      .currentUser.getIdToken()
      .then((token) => {
        window.localStorage.authToken = token;
        return {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
          },
        };
      });
  } catch (e) {
    if (window.localStorage.authToken)
      return new Promise((resolve) => {
        resolve({
          headers: {
            ...headers,
            authorization: window.localStorage.authToken ? `Bearer ${window.localStorage.authToken}` : '',
          },
        });
      });
    return {};
  }

  // return {
  //   headers: {
  //     ...headers,
  //     authorization: store.getState().auth.token ? `Bearer ${store.getState().auth.token}` : '',
  //   },
  // };
});
// const link = ApolloLink.from([authLink, ...{/**ur other links */}])
const link = ApolloLink.from([
  onError(({ graphQLErrors, networkError }) => {
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`));
    }
  }),
  authLink,
  httpLink,
]);

const client = new ApolloClient({
  link,
  uri: `${process.env.SELLER_HUB_CATALOG_GRAPHQL_API}/graphql?platform=sellerhub`,
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

const Main = () => {
  const layoutlessRoutes = useMemo(() => getLayoutlessRoutes({ data: routesAndMenuItems() }), []);
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistedStore}>
          <Helmet {...REACT_HELMET_PROPS} />
          <ToastContainer transition={Slide} newestOnTop />
          <Router basename={process.env.SELLER_HUB_BASENAME}>
            <QueryParamProvider>
              <LangProvider>
                <RouteIdentifier routes={[...layoutlessRoutes, ...defaultRoutes]} />
              </LangProvider>
            </QueryParamProvider>
          </Router>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Main />);

/*
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
reportWebVitals();
