/* eslint-disable jsx-control-statements/jsx-use-if-tag */
/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
/* eslint no-underscore-dangle: 0 */
import React, { createRef, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { displayErrorToast } from 'helpers/toaster';

const WizardThree = (props) => {
  const defaultInput = [
    { name: 'Trade License', file: null },
    { name: 'VAT Certificate', file: null },
    { name: 'Company Logo', file: null },
    { name: 'Bank Details', file: null },
  ];
  const [companyLogo, setCompanyLogo] = useState(null);
  const hiddenFileInput = useRef([createRef(), createRef(), createRef(), createRef()]);
  const [sellerInfo, setSellerInfo] = useState(defaultInput);

  useEffect(() => {
    if (props.user?.documents?.length > 0) {
      let info = [];
      const inputInfo = [...defaultInput];
      props.user?.documents.forEach((d) => {
        const docIndex = inputInfo.findIndex((i) => i.name === d.name);
        if (docIndex !== -1) {
          inputInfo.splice(docIndex, 1);
        }
        info.push({ ...d });
      });
      info = [...info, ...inputInfo];
      setSellerInfo(info);
    }
  }, []);

  const handleUploadFile = (index) => {
    // hiddenFileInput.current[index].click();
    hiddenFileInput.current[index].current.click();
  };

  const onChangeFile = ({ target: { files } }, index) => {
    const oldSellerInfo = [...sellerInfo];
    if (oldSellerInfo[index]?.name) {
      oldSellerInfo[index] = { ...oldSellerInfo[index], file: files?.[0] };
      if (files?.[0] && oldSellerInfo[index]?.name === 'Company Logo') {
        setCompanyLogo(URL.createObjectURL(files?.[0]));
      }
    }
    setSellerInfo([...oldSellerInfo]);
  };

  const checkDocumentHasValues = () => {
    let bool = true;
    if (sellerInfo?.length > 0) {
      sellerInfo?.forEach((info) => {
        if (!info.file) {
          bool = false;
        }
      });
    } else {
      bool = false;
    }
    return bool;
  };

  const validate = () => {
    if (checkDocumentHasValues()) {
      props.setActiveStep(props.activeStep + 1);
      props.userCallback(sellerInfo);
    } else {
      displayErrorToast('Please upload all documents');
    }
  };

  return (
    <>
      <Card>
        <Card.Body>
          {sellerInfo?.map((document, i) => {
            return (
              <Row key={`${document}-${i}`}>
                <Col>
                  <Card className="mb-5">
                    <Row>
                      <Row>
                        <Col lg="11" md="11">
                          <div className="d-flex justify-content-between mt-2 mx-2 font-weight-bold">{document.name}</div>
                        </Col>
                      </Row>
                    </Row>
                    <Card.Body>
                      <Row>
                        <Col lg="2" md="2">
                          <Button size="sm" variant="success" onClick={() => handleUploadFile(i)}>
                            Select File
                          </Button>
                        </Col>
                        <Col lg="4" md="4">
                          {document.name === 'Company Logo' ? (
                            <input accept="image/*" type="file" className="d-none" onChange={(e) => onChangeFile(e, i)} ref={hiddenFileInput.current[i]} />
                          ) : (
                            <input type="file" className="d-none" onChange={(e) => onChangeFile(e, i)} ref={hiddenFileInput.current[i]} />
                          )}
                          <Row>
                            <Col>{document?.file && <span className="m-1">{document?.file?.name}</span>}</Col>
                            <Col>
                              {document.name === 'Company Logo' && companyLogo && <img alt="Company Logo" src={companyLogo} width={100} height={100} />}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  {/* Seller Details End */}
                </Col>
              </Row>
            );
          })}
        </Card.Body>
        <Card.Footer>
          <Button variant="outline-primary" className="me-2" onClick={() => props.setActiveStep(props.activeStep - 1)}>
            Back
          </Button>
          <Button onClick={validate}>Next</Button>
        </Card.Footer>
      </Card>
    </>
  );
};

export default WizardThree;
