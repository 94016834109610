import React, { useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { COUNTRIES, MENU_PLACEMENT } from 'constants/index';
import { changeCountry } from 'country/countrySlice';
import { layoutShowingNavMenu } from 'layout/layoutSlice';

const MENU_NAME = 'NavCountrySwitcher';
const NavCountrySwitcher = () => {
  const dispatch = useDispatch();

  const {
    behaviourStatus: { behaviourHtmlData },
    placementStatus: { view: placement },
    attrMobile,
    attrMenuAnimate,
  } = useSelector((state) => state.menu);
  const { color } = useSelector((state) => state.settings);
  const { showingNavMenu } = useSelector((state) => state.layout);
  const { currentCountry } = useSelector((state) => state.country);

  const onSelectCountry = (code) => {
    dispatch(changeCountry(code));
  };
  const onToggle = (status, event) => {
    if (event && event.stopPropagation) event.stopPropagation();
    else if (event && event.originalEvent && event.originalEvent.stopPropagation) event.originalEvent.stopPropagation();
    dispatch(layoutShowingNavMenu(status ? MENU_NAME : ''));
  };

  useEffect(() => {
    dispatch(layoutShowingNavMenu(''));
    // eslint-disable-next-line
  }, [attrMenuAnimate, behaviourHtmlData, attrMobile, color]);

  return (
    <div className="language-switch-container">
      <Dropdown onToggle={onToggle} show={showingNavMenu === MENU_NAME} align="end">
        <Dropdown.Toggle
          variant="empty"
          className={classNames('language-button', {
            show: showingNavMenu === MENU_NAME,
          })}
        >
          {currentCountry.label}
        </Dropdown.Toggle>

        <Dropdown.Menu
          popperConfig={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: () => {
                    if (placement === MENU_PLACEMENT.Vertical) {
                      return [6, 7];
                    }
                    return [0, 7];
                  },
                },
              },
            ],
          }}
        >
          {COUNTRIES.map((country) => (
            <Dropdown.Item key={country.iso2_code} onClick={() => onSelectCountry(country.iso2_code)}>
              {country.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
export default React.memo(NavCountrySwitcher);
