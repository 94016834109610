/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  Badge,
  Modal,
  Spinner,
  Alert,

  // Alert,

  //   Badge
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HtmlHead from 'components/html-head/HtmlHead';
// import { NavLink } from 'react-router-dom';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import Confetti from 'react-confetti';
import { useSelector, useDispatch } from 'react-redux';

import { setCurrentUser } from 'auth/authSlice';
import { format } from 'date-fns';
import { Calendar, Progress } from 'rsuite';
// import GaugeChart from 'react-gauge-chart';
import moment from 'moment';

import { useQuery } from '@apollo/client';
import {
  calculatePercent,
  // mapPercentToColor, mapPercentToColorReverse,
  mapPercentToColorShade,
} from 'helpers/fields';
import clipboardCopy from 'clipboard-copy';

import { displayErrorToast, displaySuccessToast } from 'helpers/toaster';
import { fetchAllOrders } from 'views/orders/actions/orderActions';
import { STATUS_ALL } from 'constants/index';
import { getSellerDashboard, getSellerStats, MAIN_DASHBOARD_GQL } from './actions/dashboardActions';

import RatingCard from './components/RatingCard';
import OrderList from './OrderList';

const DashboardGettingStarted = () => {
  const title = 'Dashboard';
  const description = 'Service Provider Getting Started';
  const [sellerDashboard, setSellerDashboard] = useState({});
  const [sellerStatus, setSellerStats] = useState([]);
  const [deliveryCalender, setDeliveryCalendar] = useState([]);

  const { dashboardStatus } = useSelector((state) => state.dashboardStatus);
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.auth);

  const { data: mainDashboardData } = useQuery(MAIN_DASHBOARD_GQL());
  const { mainDashboard = {} } = mainDashboardData || {};

  const fetchDashboard = async () => {
    const sellerDashboardResults = await getSellerDashboard({
      params: {},
    });
    const sellerStats = await getSellerStats({
      params: {},
    });
    setSellerStats(sellerStats?.stats);

    const deliveries = {};
    sellerStats?.deliveryCalender?.forEach((d) => {
      deliveries[format(new Date(d.expected_delivery_date), 'dd/MM/yyyy')] = d;
    });
    setDeliveryCalendar(deliveries);

    if (sellerDashboardResults.seller) {
      dispatch(
        setCurrentUser({
          ...currentUser,
          isActive: sellerDashboardResults.seller?.isActive,
          sellerName: sellerDashboardResults.seller?.business?.name,
        })
      );
    }
    setSellerDashboard(sellerDashboardResults);
  };

  useEffect(() => {
    fetchDashboard();
  }, []);
  const NUMBER_ONE = 1;
  console.log(dashboardStatus, 'dashboard');
  console.log(typeof dashboardStatus?.results, 'dashboardStatus?.results');
  const today = new Date();
  const paymentDate = sellerDashboard?.nextPaymentDate ? sellerDashboard.nextPaymentDate : new Date(today.getFullYear(), today.getMonth() + NUMBER_ONE, 0);
  const nextPaymentDate = moment(Number(paymentDate)).format('DD/MM/YYYY');
  const [showConfetti, setShowConfetti] = useState(false);

  const topBoxes = [
    // { title: "Today's Sales", value: sellerDashboard?.salesToday || 'AED 0.00' },
    // { title: "Today's Unit Sold", value: sellerDashboard.unitsSoldToday || '0' },
    // { title: 'Total Paid Revenue', value: sellerDashboard.totalPaidRevenue || 'AED 0.00' },
    // { title: 'Total UnPaid Revenue', value: sellerDashboard.totalUnPaidRevenue || 'AED 0.00' },
    { title: 'Next Payment', value: nextPaymentDate },
    { title: 'Total Products', value: sellerDashboard.totalProducts },
  ];

  const [isLoading, setLoading] = useState(false);
  const [orderList, serOrders] = useState([]);
  const getOrderList = async ({ deliveryDate }) => {
    try {
      setLoading(true);
      const orders = await fetchAllOrders({
        params: {
          limit: 100,
          deliveryDate,
          order_status: [STATUS_ALL],
        },
      });
      serOrders(
        orders?.orderList?.listOrders.filter((order) => {
          return !order.cancelled_by_customer;
        })
      );
      setLoading(false);
    } catch (e) {
      displayErrorToast('Something went wrong, while featching the result, please refresh');
      console.error(e);
      setLoading(false);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setShowConfetti(false);
    }, 10000);
  }, []);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const renderCell = (date) => {
    const cellDate = format(new Date(date), 'dd/MM/yyyy');
    if (deliveryCalender?.[cellDate]?.numberOfDelivery) {
      const percentage = Math.floor((deliveryCalender[cellDate]?.completed / deliveryCalender[cellDate].numberOfDelivery) * 100);
      const strokeColors = percentage === 100 ? '#4caf50' : '#ffc107';
      return (
        <div
          className=""
          onClick={() => {
            getOrderList({ deliveryDate: format(new Date(date), 'yyyy-MM-dd') });
            setShowOrderModal(date);
          }}
        >
          <div
            style={{
              width: 30,
              display: 'inline-block',
              marginRight: 0,
            }}
          >
            <Progress.Circle percent={percentage} strokeColor={strokeColors} />
          </div>
          &nbsp; &nbsp;
          <Badge variant="outline-danger">
            {deliveryCalender[cellDate].completed} / {deliveryCalender[cellDate].numberOfDelivery}
          </Badge>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <HtmlHead title={title} description={description} />
      <Col>
        {/* Title Start */}
        <div className="page-title-container mb-0">
          <Row>
            <Col className="mb-2">
              <h1 className="mb-2 pb-0 display-4">{title}</h1>
              <div className="text-muted font-heading text-small">&nbsp;</div>
            </Col>
          </Row>
          {console.log('counts-----------', dashboardStatus?.results)}
          {/* {(dashboardStatus?.results).map((item, index) => {
        
            console.log("counts----",item,index);
            return (
              <Row  key={index}>
              <h1>Hai</h1>
              </Row>
                  );
                  
          })} */}
        </div>
        {/* Title End */}
      </Col>
      <Modal className="modal-left x1-large" show={!!showOrderModal} onHide={() => setShowOrderModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">Scheduled On {format(new Date(showOrderModal), 'dd/MM/yyyy')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <If condition={!isLoading}>
            <OrderList
              orders={orderList}
              tableHeight={600}
              otherCells={[
                { key: 'delivery_completed_at', label: 'Delivered' },
                { key: 'scheduled_on', label: 'Scheduled On', width: 120 },
              ]}
            />
          </If>
          <If condition={isLoading}>
            <div className="align-items-center d-flex justify-content-center sh-80 text-center">
              <Spinner animation="border" size="sm" variant="primary" className="dzu-spinner-border" />
            </div>
          </If>
        </Modal.Body>
      </Modal>
      <Row>
        <Col lg={4}>
          <div className="message-container mb-4">
            <div className="offer-banner">
              <h1>Boost Your Sales to Stellar Heights!</h1>
              <p>
                <Link to="/marketing/promotions" className="btn btn-xs btn-outline-success" size="xs" href="/marketing/promotions">
                  {' '}
                  PARTICIPATE NOW 🚀
                </Link>
              </p>
            </div>
            <div className="offer-details">
              <p>Contact us today to tailor the perfect promotion for your business: </p>
              <p>
                📞 <a href="tel:971-569918969">971-569918969</a> <br /> ✉️ <a href="mailto:afreen.ulday@danubehome.com">afreen.ulday@danubehome.com</a>
              </p>

              <ul>
                <li>
                  <span className="highlight">🛍️ Buy One, Get One (BOGO) deals</span>
                </li>
                <li>
                  <span className="highlight">🎁 Bundle Offers</span> to excite and entice
                </li>
                <li>
                  <span className="highlight">🎉 Freebies</span> that make your customers smile
                </li>

                <li>
                  <span className="highlight">🏆 Customized Coupons </span> that grow your brand
                </li>
              </ul>
            </div>
          </div>
          <h6>Delivery Schedule Calendar</h6>
          <Calendar bordered renderCell={renderCell} className="dashboard-calendar" data={deliveryCalender} />
        </Col>
        <Col lg={8}>
          <Row>
            <Col>
              {/* <Alert variant="danger">
                <div>
                  To maintain the quality and integrity of danubehome, it's essential that each product provides a comprehensive visual representation.
                  <b> Starting from September 30th, any product listings with fewer than 3 images will be suppressed.</b> Please update your listings
                  accordingly to ensure continued visibility on our platform. For more info:
                </div>
                <a rel="noreferrer" href="https://seller-academy.danubehome.com/en/articles/image-guidelines-for-product-listing" className="" target="_blank">
                  <span>Check Image Guidelines</span>
                </a>
              </Alert> */}
              <div className="banner">
                <Alert className="banner-text" variant="info">
                  <h3>
                    Exciting News! SellerHub App is Now Available on iOS and Android
                    <span role="img" aria-label="Success">
                      🎉
                    </span>
                    <br />
                    <span className="text-muted text-small">Login with your email id and password used for sellerhub.danubehome.com</span>
                  </h3>
                  <div className="pt-2 w-90 sh-15">
                    <a href="https://apps.apple.com/us/app/sellerhub/id6464481616" className="download-button" target="_blank" rel="noopener noreferrer">
                      <img src="/img/logo/appStore.svg" alt="Download on the App Store" style={{ height: 50 }} />
                    </a>
                    {'   '}Available via Link Only.
                    <a
                      className="cursor-pointer"
                      target="_blank"
                      onClick={() => {
                        clipboardCopy('https://apps.apple.com/us/app/sellerhub/id6464481616');
                        displaySuccessToast('Successfully Copied. Visit this link in any mobile browser to download the app', 15000);
                      }}
                    >
                      {'   '}
                      Copy Link or Scan QR Code
                    </a>
                    <img className="ps-5 float-end" src="/img/seller/iphone-qrcode.png" alt="Download on the App Store" style={{ height: 100 }} />
                  </div>
                  <hr />
                  <div className="pt-2 w-90 sh-15">
                    <a
                      target="_blank"
                      onClick={() => {
                        clipboardCopy('https://play.google.com/store/apps/details?id=com.sellerhubapp');
                        displaySuccessToast('Successfully Copied. Visit this link in any mobile browser to download the app', 15000);
                      }}
                      className="download-button pt-2 cursor-pointer"
                      rel="noopener noreferrer"
                    >
                      <img src="/img/logo/playStore.svg" alt="Download on the Playstore Store" style={{ height: 50 }} />
                      {'   '}Available via PlayStore Link.{'   '}
                      Copy Link or Scan QR Code
                      <If condition={showConfetti}>
                        <Confetti width={800} height={200} friction={0.99} tweenDuration={2000} style={{ marginLeft: 100 }} />
                      </If>
                    </a>
                    <img className="ps-5 float-end" src="/img/seller/android-qrcode.png" alt="Download on the App Store" style={{ height: 100 }} />
                  </div>
                </Alert>
              </div>
            </Col>
          </Row>
          <Row className="g-2">
            {sellerStatus?.map((box) => {
              return (
                <Col md="3" key={box.title}>
                  <Card>
                    <Card.Body className=" p-2 pt-2 pb-2 align-items-center">
                      <Row className="g-0">
                        <Col xs="auto" className="mt-1">
                          <div className=" sw-4 sh-4 rounded-xl d-flex justify-content-center align-items-center">
                            <CsLineIcons icon="dollar" />
                          </div>
                        </Col>
                        <Col>
                          <div className="sh-5 d-flex align-items-center lh-1-25 ps-3">{box.bottomText || ''}</div>
                          <div className="cta-3 text-primary ps-3">
                            <span className="text-large">{box.bigText || ''}</span> <span className="text-small text-muted">{box.suffix || ''}</span>
                            <If condition={box.rate}>
                              <span className="text-small" style={{ position: 'absolute', bottom: 10, right: 20 }}>
                                <If condition={box.rate > 0}>
                                  <CsLineIcons icon="arrow-top" className="text-tertiary " width="16px" />
                                </If>
                                <If condition={box.rate < -1}>
                                  <CsLineIcons icon="arrow-bottom" className="text-danger " width="16px" />
                                </If>
                                {(box.rate || 0).toFixed(0)}%
                              </span>
                            </If>
                          </div>
                          <div className="ms-n3">
                            {' '}
                            <span className="text-small text-muted">{box.compareText || ''}: &nbsp;</span>
                            <span className="text-small text-bold">
                              {box.compareValue && box.compareValue !== 'undefined' ? box.compareValue : '0'}{' '}
                              <span className="text-small text-muted">{box.suffix || ''}</span>
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
            {topBoxes.map((box) => {
              return (
                <Col md="4" key={box.title}>
                  <Card>
                    <Card.Body className=" p-2 pt-2 pb-2 align-items-center">
                      <Row className="g-0">
                        <Col xs="auto" className="mt-1">
                          <div className=" sw-4 sh-4 rounded-xl d-flex justify-content-center align-items-center">
                            <CsLineIcons icon="dollar" />
                          </div>
                        </Col>
                        <Col>
                          <div className="sh-5 d-flex align-items-center lh-1-25 ps-3">{box.title || ''}</div>
                          <div className="cta-3 text-primary ps-3"> {box.value || ''}</div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col md="6">
          <Row>
            <Col md="12" className="mt-3">
              <Row>
                <Col md="4">
                  <h2 className="small-title">Unshipped Orders</h2>
                  <Card className="mb-2 bg-warning">
                    <Card.Body>
                      <div className="text-center">
                        <span className="text-xlarge fw-bolder">{sellerDashboard?.delayedShipping || 0}</span>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="4">
                  <h2 className="small-title">Low Stock Products</h2>
                  <Card className="mb-2">
                    <Card.Body>
                      <div className="text-center">
                        <span className="text-xlarge fw-bolder">{sellerDashboard?.lowStockProducts || 0}</span>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="4">
                  <h2 className="small-title">Messages</h2>
                  <Card className="mb-2 bg-quaternary">
                    <Card.Body>
                      <div className="text-center">
                        <span className="text-xlarge fw-bolder">{sellerDashboard?.messages || 0}</span>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col md="6">
          <Row>
            <Col md="6">
              <h2 className="small-title ">Seller Rating</h2>
              <RatingCard ratings={mainDashboard?.sellerRatings} />
            </Col>
            <Col md="6">
              <h2 className="small-title ">Product Rating</h2>
              <RatingCard ratings={mainDashboard?.productRatings} />
            </Col>
            <Col md="6" className="mt-4">
              <h2 className="small-title">Delivery Rating</h2>
              <RatingCard />
            </Col>
            <Col md="6" className="mt-4">
              <h2 className="small-title">Product as Described</h2>
              <Card className="">
                <Card.Body className=" p-2 pt-2 pb-2 align-items-center">
                  <div className="text-muted text-small d-flex ms-1 me-1 border-bottom pb-2 ">
                    Based on {mainDashboard?.productAsDescribed?.totalProduct} ordered products
                  </div>

                  <div style={{ width: 135, marginTop: 10 }} className="ml-3">
                    <Progress.Circle
                      percent={calculatePercent(mainDashboard?.productAsDescribed?.describedProduct, mainDashboard?.productAsDescribed?.totalProduct)}
                      strokeWidth={10}
                      strokeColor={mapPercentToColorShade(mainDashboard?.productAsDescribed?.describedProduct, mainDashboard?.productAsDescribed?.totalProduct)}
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(DashboardGettingStarted);
