import React from 'react';

export default ({ activeStep = -1, handleBack = () => {} }) => {
  return (
    <div className="leftbar">
      <span className="sellerHubLogo">
        <img src="./img/seller/Logo.png" alt="" />
      </span>
      {activeStep !== 4 && (
        <ul className="leftbarNav">
          <li className={activeStep === -1 ? 'Active' : 'cursor-pointer'}>
            <span className="steps">
              <span className="leftbarNavCount cta-1 " style={{ marginTop: -2 }}>
                1
              </span>
              <span className="leftbarNavSteps">
                <strong className="cta-1">Register</strong>
              </span>
            </span>
            <span className="activeBdr" />
          </li>
          <li className={activeStep === 0 ? 'Active' : 'cursor-pointer'} onClick={() => handleBack(1, 'business')}>
            <span className="steps">
              <span className="leftbarNavCount cta-1 " style={{ marginTop: -2 }}>
                2
              </span>
              <span className="leftbarNavSteps cta-1">
                <strong className="cta-1">Business Details</strong>
              </span>
            </span>
            <span className="activeBdr" />
          </li>
          <li className={activeStep === 1 ? 'Active' : 'cursor-pointer'} onClick={() => handleBack(2, 'bank')}>
            <span className="steps">
              <span className="leftbarNavCount cta-1 " style={{ marginTop: -2 }}>
                3
              </span>
              <span className="leftbarNavSteps cta-1">
                <strong>Bank Details</strong>
              </span>
            </span>
            <span className="activeBdr" />
          </li>
          <li className={activeStep === 2 ? 'Active' : 'cursor-pointer'} onClick={() => handleBack(3, 'documents')}>
            <span className="steps">
              <span className="leftbarNavCount cta-1 " style={{ marginTop: -2 }}>
                4
              </span>
              <span className="leftbarNavSteps cta-1">
                <strong>Company Documents</strong>
              </span>
            </span>
            <span className="activeBdr" />
          </li>
          <li className={activeStep === 3 ? 'Active' : ''} onClick={() => handleBack(4, 'warehouse')}>
            <span className="steps">
              <span className="leftbarNavCount cta-1 " style={{ marginTop: -2 }}>
                5
              </span>
              <span className="leftbarNavSteps cta-1">
                <strong>Warehouse Details</strong>
              </span>
            </span>
            <span className="activeBdr" />
          </li>
        </ul>
      )}
      {/* <span className="sellerHubIcon">
        <img src="./img/seller/sellerHubIcon.png" alt="" className="w-70" />
      </span> */}
    </div>
  );
};
