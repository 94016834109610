import { gql } from '@apollo/client';
import ApolloClientUploadSellerService from 'ApolloClientUploadSellerService';

export const CREATE_SELLER_ACCOUNT = () => gql`
  mutation createSellerAccount($seller: SellerInputs) {
    createSeller(seller: $seller) {
      message
      success
    }
  }
`;

export const createSellerAccount = async ({ variables }) => {
  try {
    const result = await ApolloClientUploadSellerService.mutate({
      mutation: CREATE_SELLER_ACCOUNT(),
      variables,
      fetchPolicy: 'network-only',
    });
    console.log(result);
    return result;
  } catch (e) {
    console.error(e);
    return { error: e };
  }
  // return { error: true };
};
