import React, { useState, useEffect } from 'react';
import { Row, Col, Dropdown, OverlayTrigger, Form, Tooltip, Card, Tab, Nav } from 'react-bootstrap';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { NavLink } from 'react-router-dom';
import Pagination from '@vlsergey/react-bootstrap-pagination';
import HtmlHead from 'components/html-head/HtmlHead';
import { useIntl } from 'react-intl';
import { getCurrentSellerId } from 'auth/utils';
import { Table } from 'rsuite';

import { SORTING_ORDER } from 'constants/index';
import { useUrlSearchParams } from 'use-url-search-params';
import { useSelector, useDispatch } from 'react-redux';

import classNames from 'classnames';

const { Column, HeaderCell, Cell } = Table;
const data = [
  {
    id: 1,
    email: 'Leora13@yahoo.com',
    firstName: 'Ernest Schuppe Anderson',
    lastName: null,
    city: 'New Gust',
    cityRowSpan: 3,
    street: 'Dickinson Keys',
    streetRowSpan: 2,
    companyName: 'Lebsack - Nicolas',
  },
  {
    id: 2,
    email: 'Mose_Gerhold51@yahoo.com',
    firstName: 'Janis',
    lastName: 'Bode',
    city: 'New Gust',
    street: 'Dickinson Keys',
    companyName: 'Glover - Hermiston',
  },
  {
    id: 3,

    email: 'Frieda.Sauer61@gmail.com',
    firstName: 'Makenzie Vandervort',
    lastName: null,
    city: 'New Gust',
    street: 'Legros Divide',
    companyName: 'Williamson - Kassulke',
  },
  {
    id: 4,
    email: 'Eloisa.OHara@hotmail.com',
    firstName: 'Ciara',
    lastName: 'Towne',
    city: 'Vandervort',
    cityRowSpan: 4,
    street: 'Mosciski Estate',
    streetRowSpan: 2,
    companyName: 'Hilpert, Eichmann and Brown',
  },
  {
    id: 5,
    email: 'Brisa46@hotmail.com',
    firstName: 'Suzanne',
    lastName: 'Wolff',
    city: 'Vandervort',
    street: 'Mosciski Estate',
    companyName: 'Mayer - Considine',
  },
  {
    id: 6,
    email: 'Cody.Schultz56@gmail.com',
    firstName: 'Alessandra',
    lastName: null,
    city: 'Vandervort',
    street: 'Kali Spurs',
    streetRowSpan: 2,
    companyName: 'Nikolaus and Sons',
  },
  {
    id: 7,
    email: 'Enrico_Beer@yahoo.com',
    firstName: 'Margret',
    lastName: 'Heller',
    city: 'Vandervort',
    street: 'Kali Spurs',
    companyName: 'Corwin, Maggio and Wintheiser',
  },
  {
    id: 8,
    email: 'Mitchel.Herman@yahoo.com',
    firstName: 'Emiliano',
    lastName: 'Moore',
    city: 'Gilberthaven',
    cityRowSpan: 2,
    street: null,
    companyName: 'Gulgowski - Botsford',
  },
  {
    id: 9,

    email: 'Gaylord_Reichel16@yahoo.com',
    firstName: 'Alessandra',
    lastName: 'Smith',
    city: 'Gilberthaven',
    street: 'Kali Spurs',
    companyName: 'Maggio LLC',
  },
];

const StatementViewV2 = () => {
  const title = 'Statement';
  const description = 'Statement Detail View';
  const { formatMessage: f } = useIntl();
  const [sortingName, setSortingName] = useState(f({ id: 'sort.date_asc' }));
  const [oReqStatus, setRequestStatus] = useState(0);
  const [sortingValue, setSortingValue] = useUrlSearchParams({ sort: '_date', order: SORTING_ORDER.ASC }, null, true);
  const { statementStatus } = useSelector((state) => state.statementStatus);
  const [paginationValue, setPaginationValue] = useUrlSearchParams({ page: 0 }, null, true);
  const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 10 }, null, true);
  console.log(useState);
  console.log(useEffect);
  console.log(statementStatus, 'hhhh');
  // console.log(setRequestStatus)
  // console.log(paginationValue)

  const limitByValues = [5, 10, 20, 50];
  const handleSorting = (type, order = SORTING_ORDER.ASC, name) => {
    setSortingValue({ sort: type, order });
    setSortingName(name);
  };
  const sortByValues = [
    { key: 'date', order: SORTING_ORDER.ASC, label: f({ id: 'sort.date_asc' }) },
    { key: 'date', order: SORTING_ORDER.DESC, label: f({ id: 'sort.date_desc' }) },
    { key: 'status', order: SORTING_ORDER.ASC, label: f({ id: 'sort.status_asc' }) },
    { key: 'status', order: SORTING_ORDER.DESC, label: f({ id: 'sort.status_desc' }) },
  ];

  const dispatch = useDispatch();

  const requestOrderStatus = (reqSts) => {
    setRequestStatus(reqSts);
  };
  const handlePagination = (e) => {
    setPaginationValue({ page: e.target.value });
  };
  const handleLimit = (e) => {
    setLimitValue({ limit: Number(e) });
  };

  useEffect(() => {
    getStatementStatusCounts({
      params: {
        country: 'AE',
        supplier_id: getCurrentSellerId(),
        paid: oReqStatus,
        start: Number(paginationValue.page * limitValue.limit),
        limit: Number(limitValue.limit),
        sort: { value: sortingValue.sort, order: Number(sortingValue.order) },
      },
      dispatch,
    });
  }, [paginationValue.page, oReqStatus, limitValue.limit, sortingValue.sort]);

  return (
    <>
      <HtmlHead title={title} description={description} />
      <Col>
        {/* Title Start */}
        <div className="page-title-container mb-3">
          <Row>
            <Col className="mb-2">
              <h1 className="mb-2 pb-0 display-4">{title}</h1>
              <div className="text-muted font-heading text-small">List of All Statements.</div>
            </Col>
          </Row>
        </div>
        <Row className="mb-3">
          <Col md="5" lg="3" xxl="2" className="mb-1">
            <div className="d-inline-block float-md-start me-1 mb-1 search-input-container w-100 shadow bg-foreground">
              <Form.Control type="text" placeholder="Search" id="listSearch" />
              <span className="search-magnifier-icon">
                <CsLineIcons icon="search" />
              </span>
              <span className="search-delete-icon d-none">
                <CsLineIcons icon="close" />
              </span>
            </div>
          </Col>
          <Col md="7" lg="9" xxl="10" className="mb-1 text-end">
            <Dropdown align={{ xs: 'end' }} className="d-inline-block ms-1">
              <OverlayTrigger delay={{ show: 1000, hide: 0 }} placement="top" overlay={<Tooltip id="tooltip-top">Export</Tooltip>}>
                <Dropdown.Toggle variant="foreground-alternate" className="dropdown-toggle-no-arrow btn btn-icon btn-icon-only shadow">
                  <CsLineIcons icon="download" />
                </Dropdown.Toggle>
              </OverlayTrigger>
              <Dropdown.Menu className="shadow dropdown-menu-end">
                <Dropdown.Item href="#">Copy</Dropdown.Item>
                <Dropdown.Item href="#">Excel</Dropdown.Item>
                <Dropdown.Item href="#">Cvs</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {/* Length Start */}
            {/* Length Start */}
            <Dropdown align={{ xs: 'end' }} className="d-inline-block ms-1" id="listSort">
              <Dropdown.Toggle variant="foreground-alternate" className="shadow sw-19">
                {f({ id: 'menu.sortby' })} {sortingName}
              </Dropdown.Toggle>

              <Dropdown.Menu className="shadow dropdown-menu-end" data-testid="sortItemDropdown">
                {sortByValues.map((obj, i) => {
                  return (
                    <Dropdown.Item
                      href="#"
                      value={obj.key}
                      key={i}
                      onClick={() => {
                        handleSorting(obj.key, obj.order, obj.label);
                      }}
                    >
                      {obj.label}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            {/* Length End */}
            <Dropdown align={{ xs: 'end' }} className="d-inline-block ms-1" id="listLimit">
              <Dropdown.Toggle variant="foreground-alternate" className="shadow sw-13">
                {limitValue.limit} Items
              </Dropdown.Toggle>

              <Dropdown.Menu className="shadow dropdown-menu-end">
                {limitByValues.map((limit, i) => {
                  return (
                    <Dropdown.Item key={i} href="#" onClick={() => handleLimit(limit)}>
                      {limit} Items
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            {/* Length End */}
          </Col>
        </Row>
        <Col md="1" className="d-flex flex-column pe-1 justify-content-end">
          {/* <div className="text-muted text-small cursor-pointer sort">STATUS</div> */}
          <div
            className={classNames('text-muted text-medium cursor-pointer ', {
              asc: sortingValue.value === 'fields.name.lang.en' && sortingValue.order === SORTING_ORDER.ASC,
              desc: sortingValue.value === 'fields.name.lang.en' && sortingValue.order === SORTING_ORDER.DESC,
            })}
          />
        </Col>
      </Col>

      {/* <div className="mb-5">
        <Row className="g-0 h-100 align-content-center d-none d-md-flex ps-5 pe-5 mb-2 custom-sort">
          <Col md="3" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
            <div className="text-muted text-medium cursor-pointer ">Supplier name</div>
          </Col>
          <Col md="3" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
            <div className="text-muted text-medium cursor-pointer ">Total Paid</div>
          </Col>
          <Col md="3" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
            <div className="text-muted text-medium cursor-pointer ">Total Pending</div>
          </Col>
          <Col md="3" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
            <div className="text-muted text-medium cursor-pointer ">Action</div>
          </Col>
        </Row>
      </div> */}
      <Tab.Container defaultActiveKey="First">
        <Nav variant="tabs" className="nav-tabs-title nav-tabs-line-title" activeKey="First">
          <Nav.Item>
            <Nav.Link eventKey="First" className={`mb-2 clickNav  ${oReqStatus === 0 ? 'tabActive' : ''}`} onClick={() => requestOrderStatus(0)}>
              Current (Jan 1st to 15th Jan)
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Second" className={`mb-2 clickNav  ${oReqStatus === 1 ? 'tabActive' : ''}`} onClick={() => requestOrderStatus(1)}>
              Completed
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>
          <Tab.Pane eventKey="First">
            <Table bordered cellBordered autoHeight data={data}>
              <Column width={80} fixed>
                <HeaderCell>Id</HeaderCell>
                <Cell dataKey="id" />
              </Column>
              <Column
                width={200}
                verticalAlign="middle"
                rowSpan={(rowData) => {
                  return rowData.cityRowSpan;
                }}
              >
                <HeaderCell>City</HeaderCell>
                <Cell dataKey="city" />
              </Column>

              <Column
                width={200}
                verticalAlign="middle"
                rowSpan={(rowData) => {
                  return rowData.streetRowSpan;
                }}
              >
                <HeaderCell>Street</HeaderCell>
                <Cell dataKey="street" />
              </Column>

              <Column width={130}>
                <HeaderCell>First Name</HeaderCell>
                <Cell dataKey="firstName" />
              </Column>

              <Column width={130}>
                <HeaderCell>Last Name</HeaderCell>
                <Cell dataKey="lastName" />
              </Column>

              <Column width={200}>
                <HeaderCell>Company Name</HeaderCell>
                <Cell dataKey="companyName" />
              </Column>

              <Column width={80} fixed="right">
                <HeaderCell>...</HeaderCell>
                <Cell>Edit</Cell>
              </Column>
            </Table>
          </Tab.Pane>

          <Tab.Pane eventKey="Second">
            <div className="mb-5">
              <Row className="g-0 h-100 align-content-center d-none d-md-flex ps-5 pe-5 mb-2 custom-sort">
                <Col md="1" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
                  <div className="text-muted text-medium cursor-pointer ">Sl no</div>
                </Col>
                <Col md="2" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
                  <div className="text-muted text-medium cursor-pointer ">Created date</div>
                </Col>
                <Col md="2" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
                  <div className="text-muted text-medium cursor-pointer ">Order reference no</div>
                </Col>
                <Col md="2" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
                  <div className="text-muted text-medium cursor-pointer ">LPO No</div>
                </Col>
                <Col md="2" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
                  <div className="text-muted text-medium cursor-pointer ">Amount</div>
                </Col>
                <Col md="2" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
                  <div className="text-muted text-medium cursor-pointer ">Status</div>
                </Col>
                <Col md="1" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
                  <div className="text-muted text-medium cursor-pointer ">Action</div>
                </Col>
              </Row>
            </div>
            {statementStatus?.results?.map((item, index) => {
              console.log(item, 'item');
              return (
                <Card className="hover-border-primary mb-2" key={1} style={{ fontSize: '90%' }}>
                  <Card.Body className="pt-0 pb-0 sh-22 sh-md-4 card_height" style={{ marginTop: '25px', marginBottom: '40px' }}>
                    <Row className="g-0 h-100 align-content-center cursor-default">
                      <Col xs="6" md="1" className="text-left d-flex flex-column justify-content-center mb-2 mb-md-0 order-3 order-md-2">
                        <div className=" text-medium ">{index + 1}</div>
                      </Col>
                      <Col xs="6" md="2" className="text-left d-flex flex-column justify-content-center mb-2 mb-md-0 order-3 order-md-2">
                        <div className="text-medium ">{item?.created_at}</div>
                      </Col>
                      <Col xs="6" md="2" className="text-left d-flex flex-column justify-content-center mb-2 mb-md-0 order-3 order-md-2">
                        <div className="text-medium ">{item?.reference_no}</div>
                      </Col>
                      <Col xs="6" md="2" className="text-leftd-flex flex-column justify-content-center align-items-md-left mb-2 mb-md-0 order-2 order-md-5">
                        <div className="text-medium ">{item?.order_status}</div>
                      </Col>
                      <Col xs="6" md="2" className="text-left d-flex flex-column justify-content-center align-items-md-left mb-2 mb-md-0 order-2 order-md-5">
                        <div className="text-medium ">{item?.grand_total}</div>
                      </Col>
                      <Col xs="6" md="2" className="text-left d-flex flex-column justify-content-center align-items-md-left mb-2 mb-md-0 order-2 order-md-5">
                        <div className="text-medium ">{item?.name}</div>
                      </Col>
                      <Col xs="6" md="1" className="text-left d-flex flex-column justify-content-center align-items-md-left mb-2 mb-md-0 order-2 order-md-5">
                        <NavLink to="/orders/views" className="text-dark cta-4">
                          <CsLineIcons icon="eye" size="15" className="text-primary me-1" />
                        </NavLink>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              );
            })}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      <div className="d-flex justify-content-center">
        <Pagination id="listPage" value={paginationValue.page} totalPages={statementStatus.listOrdersCount?.totalPages} onChange={(e) => handlePagination(e)} />
      </div>
    </>
  );
};

export default StatementViewV2;
