/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
/* eslint no-underscore-dangle: 0 */

import React, { useState, useEffect } from 'react';
import HtmlHead from 'components/html-head/HtmlHead';
import SellerOnboardingFullPage from 'layout/SellerOnboardingFullPage';
import { Row, Col, Button, Form, Card } from 'react-bootstrap';
import * as Yup from 'yup';
import { SELLER_ONBOARDING } from 'constants/index';
import { DEFAULT_PATHS } from 'config';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { displayErrorToast, displaySuccessToast } from 'helpers/toaster';
import { GET_USER_DETAIL } from 'views/catalog/actions/userActions';
import { setCurrentUser, setLogin, setLogout } from 'auth/authSlice';
import { auth, dhGetAuth } from 'helpers/Firebase';
import isEmpty from 'lodash.isempty';
import useLayout from 'hooks/useLayout';
import OnboardingListNav from 'views/default/OnboardingListNav';
import ApolloClientSellerService from '../../ApolloClientSellerService';
import { createSellerAccount } from './actions/sellerAccountCreateActions';
import './style.scss';
import WizardOne from './WizardOne';
import WizardTwo from './WizardTwo';
import WizardThree from './WizardThree';
import WizardFour from './WizardFour';

const WizardFive = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    window.localStorage.authToken = null;
    auth.signOut();
    dispatch(setLogout());
  }, []);

  const handleLogout = () => {
    setTimeout(() => {
      history.replace(DEFAULT_PATHS.LOGIN);
    }, 100);
  };

  return (
    <>
      <Card>
        <Card.Body>
          <div className="d-flex justify-content-center">
            <h3>Hi Seller, your details has been sent for an approval. We will inform you by mail. Thanks.</h3>
          </div>
        </Card.Body>
        <Card.Footer>
          <div className="d-flex justify-content-center">
            <Button onClick={handleLogout}>CLOSE</Button>
          </div>
        </Card.Footer>
      </Card>
    </>
  );
};

/**
 * Master function for onboarding
 * @returns
 */

const SellerOnboardingProfile = () => {
  useLayout();
  const title = 'Seller Onboarding';
  const description = 'Seller onboarding profile information.';
  // const [addSellerAccount, { data, loading, error }] = useMutation(CREATE_SELLER_ACCOUNT(), { client: ApolloClientSellerService });
  const history = useHistory();
  const dispatch = useDispatch();

  const { data: userDetailData, refetch: userDetailRefetch } = useQuery(GET_USER_DETAIL(), {
    client: ApolloClientSellerService,
  });

  useEffect(() => {
    // shameem.kt+testingregistration@gmail.com
    window.__onbaordingTimer = setInterval(() => {
      try {
        dhGetAuth()
          .currentUser.getIdToken()
          .then((d) => {
            if (!d) {
              dispatch(setLogout());
              displayErrorToast('Your session has been timed out or logined from different system. Kindly login again', 6000);
              setTimeout(() => {
                clearInterval(window.__onbaordingTimer);
                window.location.href = '/login';
              }, 6000);
            }
          });
      } catch (e) {
        displayErrorToast('Your session has been timed out or logined from different system. Kindly login again', 6000);
        setTimeout(() => {
          clearInterval(window.__onbaordingTimer);
          dispatch(setLogout());
          window.location.href = '/login';
        }, 6000);
      }
    }, 30000);
    return () => {
      clearInterval(window.__onbaordingTimer);
    };
  }, []);

  useEffect(() => {
    if (userDetailData?.getUserDetail && userDetailData?.getUserDetail?.sellers) {
      dispatch(
        setCurrentUser({
          _id: userDetailData.getUserDetail._id,
          id: userDetailData.getUserDetail.fuid,
          name: userDetailData.getUserDetail.name,
          thumb: '/img/profile/profile-9.webp',
          role: userDetailData.getUserDetail.role,
          email: userDetailData.getUserDetail.email,
          sellerName: userDetailData?.getUserDetail?.sellers?.[0]?.displayName,
          sellerId: userDetailData?.getUserDetail?.sellers?.[0]?._id,
          isActive: userDetailData?.getUserDetail?.sellers?.[0]?.isActive,
          emailVerified: true,
          isEligibleForAds: userDetailData.getUserDetail?.sellers?.[0]?.isEligibleForAds,
        })
      );
      setTimeout(() => {
        if (userDetailData?.getUserDetail?.sellers?.[0] && userDetailData?.getUserDetail?.sellers?.[0]?.isActive !== 4) {
          history.push(DEFAULT_PATHS.APP);
          window.location.href = DEFAULT_PATHS.APP;
        }
        if (userDetailData?.getUserDetail?.sellers?.[0] && userDetailData?.getUserDetail?.sellers?.[0]?.isActive === 4) {
          history.push(DEFAULT_PATHS.NOTFOUND);
        }
      }, 100);
    }
  }, [userDetailData]);

  const [activeStep, setActiveStep] = useState(history?.location?.state?.activeStep || 0);
  const [user, setUserData] = useState({});
  console.log('🚀 ~ file: SellerOnboarding.js:500 ~ SellerOnboardingProfile ~ user', user);

  const assignBusinessSellerUser = (val) => {
    console.log('🚀 ~ file: SellerOnboarding.js:502 ~ assignBusinessSellerUser ~ val', val);
    setUserData((user) => ({
      ...user,
      business: val,
    }));
  };

  const assignBankSellerUser = (val) => {
    setUserData((user) => ({
      ...user,
      bank: val,
    }));
  };

  const assignDocumentSellerUser = (val) => {
    setUserData((user) => ({
      ...user,
      documents: val,
    }));
  };
  const [isCompleteLoading, setCompleteLoading] = useState(false);
  const handleComplete = async (details) => {
    try {
      setCompleteLoading(true);
      const finalUserObj = { ...user, warehouse: [details] };
      const { data, error } = await createSellerAccount({ variables: { seller: finalUserObj } });
      setCompleteLoading(false);
      console.log('🚀 ~ file: SellerOnboarding.js:529 ~ handleComplete ~ finalUserObj', finalUserObj);
      if (data) {
        if (data?.createSeller.success !== false) {
          displaySuccessToast(data.createSeller.message);
          userDetailRefetch();
          setActiveStep(3);
        } else {
          displayErrorToast(error?.message);
        }
      }
      if (error) {
        displayErrorToast(error?.message);
        // history.push(DEFAULT_PATHS.APP);
      }
    } catch (e) {
      console.error(e);
      displayErrorToast('Something went wrong');
      setCompleteLoading(false);
    }
  };

  const handleBack = (step, type) => {
    if (step > 0 && user[type] && step <= activeStep) {
      setActiveStep(step - 1);
    }
  };

  const sellerWizard = (
    // <div>
    //   <Stepper activeStep={activeStep}>
    //     <Step label="Seller Details" />
    //     <Step label="Company Document" />
    //     <Step label="Bank Details" />
    //   </Stepper>
    //   {/* NOTE: IMPORTANT !! StepWizard must contains at least 2 children components, else got error */}
    //   {
    //     <StepWizard instance={assignStepWizard} onStepChange={handleStepChange}>
    //       <WizardOne userCallback={assignSellerUser} />
    //       <WizardTwo userCallback={assignSellerUser} />
    //       <WizardThree user={user} completeCallback={handleComplete} />
    //     </StepWizard>
    //   }
    // </div>
    <div className="containSection">
      <OnboardingListNav handleBack={handleBack} activeStep={activeStep} />
      <div className="loginSection">
        <div className="m-lg-7">
          <If condition={userDetailData?.getUserDetail?.sellers?.[0]?.isActive === 4}>Awaiting Document Verification.</If>
          <If condition={userDetailData?.getUserDetail?.sellers?.[0]?.isActive !== 4}>
            {activeStep === 0 && <WizardOne activeStep={activeStep} setActiveStep={setActiveStep} user={user} userCallback={assignBusinessSellerUser} />}
            {activeStep === 1 && <WizardTwo activeStep={activeStep} setActiveStep={setActiveStep} user={user} userCallback={assignBankSellerUser} />}
            {activeStep === 2 && <WizardThree activeStep={activeStep} setActiveStep={setActiveStep} user={user} userCallback={assignDocumentSellerUser} />}
            {activeStep === 3 && (
              <WizardFour activeStep={activeStep} setActiveStep={setActiveStep} completeCallback={handleComplete} isCompleteLoading={isCompleteLoading} />
            )}
            {activeStep === 4 && <WizardFive />}
          </If>
        </div>
      </div>
    </div>
  );

  return (
    <div className="onboard">
      <HtmlHead title={title} description={description} />

      <SellerOnboardingFullPage content={sellerWizard} />
    </div>
  );
};

export default SellerOnboardingProfile;
