import { createSlice } from '@reduxjs/toolkit';

// import { DEFAULT_USER, IS_DEMO } from 'config.js';

const initialState = {
  returnDetail:[],
  isOrderLoading: true,
};

const returnDetailSlice = createSlice({
  name: 'returnDetail',
  initialState,
  reducers: {
    setReturnDetail(state, actions) {
      state.isOrderLoading = false;
      state.returnDetail = actions.payload;
    },
  },
});

export const { setReturnDetail } = returnDetailSlice.actions;

const returnDetailReducer = returnDetailSlice.reducer;
export default returnDetailReducer;
