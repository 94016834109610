import { auth, dhGetAuth } from 'helpers/Firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { GET_USER_DETAIL } from 'views/catalog/actions/userActions';
// import { GET_SELLER_DETAIL } from 'views/account/actions/getSellerDetailAction';
import ApolloClientSellerService from 'ApolloClientSellerService';
import { setLogin, setLoginLoadingHide } from './authSlice';
// import { setLogout } from 'auth/authSlice';
// import { setCurrentUser,  } from 'auth/authSlice';
import { store } from '../store';
import { DEFAULT_PATHS } from '../config';

// import { USER_ROLE } from 'constants/index';
// import { DEFAULT_PATHS } from 'config';

export const getFirebaseToken = async () => {
  return new Promise((resolve) => {
    dhGetAuth()
      .currentUser?.getIdToken()
      .then((token) => {
        console.log('token token token', token);
        resolve(token);
      })
      .catch((e) => {
        console.log('token token error', e);
      });
  });
  // const token = await dhGetAuth().currentUser?.getIdToken();
  // return token;
};

export const fetchUserDetail = async (callback) => {
  const token = await getFirebaseToken();
  console.log('token, token', token);
  const { data: userDetailDataRefetch } = await ApolloClientSellerService.query({
    query: GET_USER_DETAIL(),
    fetchPolicy: 'network-only',
  });

  // const { data: sellerIdData } = await ApolloClientSellerService.mutate({
  //   mutation: GET_SELLER_DETAIL(),
  //   variables: { _id: userDetailDataRefetch?.getUserDetail?.sellers?.[0]?._id },
  //   fetchPolicy: 'network-only',
  // });
  // console.log('sellerIdData', sellerIdData);
  window.localStorage.sellerId = userDetailDataRefetch?.getUserDetail.sellers?.[0]?.supplierWebId;
  if (userDetailDataRefetch?.getUserDetail.sellers?.[0] && callback) {
    callback(userDetailDataRefetch);
    // history.push(DEFAULT_PATHS.APP);
  }
  console.log('data', userDetailDataRefetch);
};

let authStateInitialized = false;

export const initialAuthStateChanged = ({ history, isApp, isLoginLoading, callback } = {}) => {
  if (!authStateInitialized) {
    authStateInitialized = true;
    if (!window.localStorage.sellerId) {
      fetchUserDetail();
    }
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // const { uid, name, email, emailVerified } = user;
        console.log('this util called', user);
        const token = await user?.getIdToken();
        store.dispatch(
          setLogin({
            token: token,
            email: user.email,
            phone: user.phoneNumber,
          })
        );
        if (!isApp && history && !isLoginLoading) {
          if (callback) {
            callback();
          } else history.push(DEFAULT_PATHS.APP);
        }
      } else {
        store.dispatch(setLoginLoadingHide());
        console.log('this util called1');

        // dispatch(setLogout());
      }
    });
  }
};

export const getHeaders = async () => {
  const token = await getFirebaseToken();
  if (token) {
    window.localStorage.authToken = token;
  }
  return {
    headers: { Authorization: `Bearer ${token}` },
  };
};

export const getCurrentSellerId = () => {
  return Number(window.localStorage.sellerId);
};
