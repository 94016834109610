/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Button, Card, Col, Form, FormLabel, Row } from 'react-bootstrap';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import isEmpty from 'lodash.isempty';

const WizardTwo = (props) => {
  const [sellerInfo, setSellerCompanyInfo] = useState(
    props.user?.bank || (window.localStorage.onBoardingBank && JSON.parse(window.localStorage.onBoardingBank)) || {}
  );
  const [errors, setError] = useState({});

  const onInputChanged = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setSellerCompanyInfo((sellerInfo) => ({
      ...sellerInfo,
      [targetName]: targetValue,
    }));
    setError({ ...errors, [targetName]: '' });
  };
  useEffect(() => {
    if (!isEmpty(sellerInfo)) {
      window.localStorage.onBoardingBank = JSON.stringify(sellerInfo);
    }
  }, [sellerInfo]);

  const validate = () => {
    const validationSchema = Yup.object().shape({
      bankName: Yup.string().min(4).required('Bank name is required'),
      accountNumber: Yup.number()
        .typeError('You must specify a number')
        .test('Is positive?', 'The a/c number must be numeric', (value) => value > 0),
      accountName: Yup.string().min(4).required('Account name is required'),
      iban: Yup.string().min(3).required('IBAN is required'),
      // swiftCode: Yup.string().min(4).required('Swift code is required'),
      branchName: Yup.string().min(4).required('Branch name is required'),
    });

    validationSchema
      .validate(sellerInfo, { abortEarly: false })
      .then(function () {
        setError({});
        props.setActiveStep(props.activeStep + 1);
        props.userCallback(sellerInfo);
      })
      .catch(function (err) {
        const newErrors = {};
        err.inner.forEach((obj) => {
          newErrors[obj.path] = obj.message;
        });
        setError(newErrors);
      });
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Row className="row-cols-1 row-cols-xl-1 g-2 mb-5">
            <Col>
              <h2 className="small-title">Bank Details</h2>
              <Card className="mb-5">
                <Card.Body>
                  <Row>
                    <Col>
                      <FormLabel>Bank Name</FormLabel>
                      <div className="mb-3">
                        <Form.Control type="text" name="bankName" defaultValue={sellerInfo?.bankName} onChange={onInputChanged} />
                        <span style={{ color: 'red' }}>{errors.bankName}</span>
                      </div>
                    </Col>
                    <Col>
                      <FormLabel>Branch Name</FormLabel>
                      <div className="mb-3">
                        <Form.Control type="text" name="branchName" defaultValue={sellerInfo?.branchName} onChange={onInputChanged} />
                        <span style={{ color: 'red' }}>{errors.branchName}</span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormLabel>Account Number</FormLabel>
                      <div className="mb-3">
                        <Form.Control type="number" name="accountNumber" defaultValue={sellerInfo?.accountNumber} onChange={onInputChanged} />
                        <span style={{ color: 'red' }}>{errors.accountNumber}</span>
                      </div>
                    </Col>
                    <Col>
                      <FormLabel>Account Holder Name</FormLabel>
                      <div className="mb-3">
                        <Form.Control type="text" name="accountName" defaultValue={sellerInfo?.accountName} onChange={onInputChanged} />
                        <span style={{ color: 'red' }}>{errors.accountName}</span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormLabel>Iban</FormLabel>
                      <div className="mb-3">
                        <Form.Control type="text" name="iban" defaultValue={sellerInfo?.iban} onChange={onInputChanged} />
                        <span style={{ color: 'red' }}>{errors.iban}</span>
                      </div>
                    </Col>
                    <Col>
                      <FormLabel>Swift Code</FormLabel>
                      <div className="mb-3">
                        <Form.Control type="text" name="swiftCode" defaultValue={sellerInfo?.swiftCode} onChange={onInputChanged} />
                        <span style={{ color: 'red' }}>{errors.swiftCode}</span>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Button variant="outline-primary" className="me-2" onClick={() => props.setActiveStep(props.activeStep - 1)}>
            Back
          </Button>
          <Button onClick={validate}>Next</Button>
        </Card.Footer>
      </Card>
    </>
  );
};

export default WizardTwo;
