import React from 'react';
import { Badge } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import CsLineIcons from 'cs-line-icons/CsLineIcons';

import IconMenuNotifications from './notifications/Notifications';
// import SearchModal from './search/SearchModal';

const NavIconMenu = () => {
  const { unread = 0 } = useSelector((state) => state.messages);

  // const [showSearchModal, setShowSearchModal] = useState(false);

  // const onSearchIconClick = (e) => {
  //   e.preventDefault();
  //   // setShowSearchModal(true);
  // };

  return (
    <>
      <ul className="list-unstyled list-inline text-center menu-icons">
        <li className="list-inline-item">
          <a href="/dashboard/customer-messages">
            <CsLineIcons icon="support" size="18" />
            <span className=" text-small">
              <Badge bg="secondary">{unread || 0}</Badge>
            </span>
          </a>
        </li>
        {/* <li className="list-inline-item">
          <a href="#/">
            <CsLineIcons icon="bell" size="25" />
            <Badge bg="secondary">{supportTicketUnreadListCount || 0}</Badge>
          </a>
        </li> */}

        <IconMenuNotifications />
      </ul>
      {/* <SearchModal show={showSearchModal} setShow={setShowSearchModal} /> */}
    </>
  );
};

export default React.memo(NavIconMenu);
